.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: #f3f3f3;
  background: #4e54c8;
  background: linear-gradient(to left, #6a71ff, #4e54c8);
  // background-color: #ffffff;
  // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%230FF'/%3E%3Cstop offset='1' stop-color='%23CF6'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(0,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23F00'/%3E%3Cstop offset='1' stop-color='%23FC0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='12.87'%3E%3Cpath transform='translate(-45.5 -9.2) rotate(-11 1409 581) scale(0.90574)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4.29' transform='translate(-99.5 47) rotate(-3.4 800 450) scale(0.9888979999999999)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(-13.7 16.5) rotate(-51.5 401 736) scale(0.9888979999999999)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='3.9'%3E%3Cpath transform='translate(282 16.2) rotate(-4.05 150 345) scale(1.0225799999999998)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8.58' transform='translate(4.5 -135) rotate(-82.8 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='translate(-86 10) rotate(-13.8 1400 132) scale(0.825)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  // background-attachment: fixed;
  // background-size: cover;
  .intro {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    h2 {
      color: #eeeeee;
      font-family: 'Roboto';
      text-align: center;
      font-weight: 500;
    }
  }
  .gamebox{
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
  }
  .wheel_container {
    border-radius: 50%;
    background-color: #eeeeee;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 0px 0px 28px 20px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 10px 13px -7px #000000, 0px 0px 28px 20px rgba(0, 0, 0, 0.38);
    z-index: 5;
  }

  .lose {
    background-color: #e62e2e;
    padding: 20px;
    border-radius: 10px;
    box-shadow:rgba(0, 0, 0, 0.38) 0px 0px 20px 10px;
    color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #eeeeee;
    img{
      height: 150px;
      margin-bottom: 20px;
    }
    h4 {
      margin: 0;
    }
  }
  
  .wine {
    background-color: #2ee6c7;
    padding: 20px;
    border-radius: 10px;
    box-shadow:rgba(0, 0, 0, 0.38) 0px 0px 20px 10px;
    color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #eeeeee;
    img{
      height: 150px;
      margin-bottom: 20px;
    }
    h4 {
      margin: 0;
    }
  }

  .circles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    & li {
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 0.2);
      animation: animate 25s linear infinite;
      bottom: -150px;

      &:nth-child(1) {
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
      }

      &:nth-child(2) {
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
      }

      &:nth-child(3) {
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
      }

      &:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
      }

      &:nth-child(5) {
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
      }

      &:nth-child(6) {
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
      }

      &:nth-child(7) {
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
      }

      &:nth-child(8) {
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
      }

      &:nth-child(9) {
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
      }

      &:nth-child(10) {
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
      }
    }
  }

  .cube {
    position: absolute;
    top: 80%;
    left: 45%;
    width: 10px;
    height: 10px;
    border: solid 1px darken(#0040C1, 8%);
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 12s ease-in forwards infinite;
    
    &:nth-child(2n) {
      border-color: lighten(#0040C1, 10%);
    }
    
    &:nth-child(2) {
      animation-delay: 2s;
      left: 25%;
      top: 40%;
    }
    
    &:nth-child(3) {
      animation-delay: 4s;
      left: 75%;
      top: 50%;
    }
    
    &:nth-child(4) {
      animation-delay: 6s;
      left: 90%;
      top: 10%;
    }
    
    &:nth-child(5) {
      animation-delay: 8s;
      left: 10%;
      top: 85%;
    }
    
    &:nth-child(6) {
      animation-delay: 10s;
      left: 50%;
      top: 10%;
    }
  }
  
  @keyframes cube {
    from {
      transform: scale(0) rotate(0deg) translate(-50%, -50%);   
      opacity: 1;
    }
    to {
      transform: scale(20) rotate(960deg) translate(-50%, -50%); 
      opacity: 0;
    }
  }

  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }

    100% {
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }
  }
}
.win,
.lose {

  // background-color: #2eace6;
  // padding: 20px;
  // border-radius: 10px;
  // box-shadow:rgba(0, 0, 0, 0.38) 0px 0px 20px 10px;
  // color: #eeeeee;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  // border: 1px solid #2eace6bd;
  .logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    img {
      min-width: 90px;
      width: auto;
      max-width: 250px;
    }
  }

  .title {
    text-align: center;
  }

  .imageHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;

    .imgSystem {
      height: 150px;
      margin-bottom: 20px;
    }

    .imgProduct {
      width: 250px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  h4 {
    text-align: center;
    margin: 0;
    font-size: xx-large;
  }

  p {
    text-align: center;

    &:first-of-type {
      font-size: large;
    }

    &:last-child {
      font-size: small;
    }
  }
}

.card_grid_easy {
  width: 90%;
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr; 
  gap: 10px 10px; 
  grid-template-areas: 
    ". ."
    ". ."
    ". ."; 
}
.card_grid_med {
  width: 90%;
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr 1fr; 
  gap: 10px 10px; 
  grid-template-areas: 
    ". ."
    ". ."
    ". ."
    ". ."; 
}
.card_grid_hard {
  width: 90%;
  display: grid; 
  grid-auto-rows: 1fr; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 10px 10px; 
  grid-template-areas: 
    ". . ."; 
}

