.App {
  font-family: sans-serif;
  text-align: center;
}

.slot {
  position: relative;
  display: inline-block;
  height: 200px;
  width: 100px;
}

.slot_machine_big {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  position: relative;
  align-self: center;
  width: 100%;
  height: 100%;
  justify-self: center;
}

section {
  position: absolute;
  border-radius: 15px !important;
  width: 90px;
  height: 129px;
  overflow: hidden;
  /* background-color: white; */
  border-radius: 2px;
  /* border: 1px solid lightgrey; */
  color: white;
  font-family: sans-serif;
  text-align: center;
  font-size: 25px;
  line-height: 119px;
  cursor: default;
}
.game_big_box {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-self: flex-end;
  align-self: flex-end;
}
.white-space {
  width: 100%;
  height: 50px;
}
.container {
  position: absolute;
  top: 2px;
  width: 100%;
  transition: top ease-in-out 0.5s;
  text-align: center;
}

.container img {
  height: 100%;
}

.container .box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 129px;
  width: 100%;
}
.slot_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-image: url("/src/assets/rollet_slot_machine.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: 2;
}

.game_slot_header {
  width: 100%;
  background-image: url('/src/assets/header_slot_machine.png');
  height: 160px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  top: -130px;
}

.game_slot_plat {
  width: 100%;
  background-image: url('/src/assets/plat_slot_machine.png');
  height: 60px;
  position: absolute;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center center;
  bottom: 27px;
}

.roll {
  width: 77px;
  background-image: url('/src/assets/handle_slot_machine.png');
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-color: white; */
  /* padding: 10px; */
  /* background-size: 50%;
  background-position: 0% -30%;*/
  margin-top: -60px;
  height: 169px;
  text-align: center;
  position: absolute;
  right: -55px;
  font-size: 20px;
  border-radius: 20px; 
}

.here {
  position: absolute;
  top: -30px;
  left: 26%;
  color: white;
  animation: blinkingText 1.2s infinite;
  /* opacity: 0; */
}

.rolling {
  animation: roll 1.2s infinite;
  
}

.logo_1 {
  top:1%;
  left: 20%;
  position:absolute;
  text-align:center;
  width: 10%;
  height: 10%;

}

.logo {
  top:-0.5%;
  left:40%;
  width:20%;
  position:absolute;

}
@media only screen and (max-width: 700px) {
  .logo{
    width:40%;
    top:1%;
    left:30%;
  }
}

@media only screen and (max-width: 400px) {
  .logo{
    width:70%;
    top:0;
    left:15%;
  }
}
 /*
  animation to roll the handle
 */
@keyframes roll {
  0% {
    translate: scaleY(1);
    /* transform-origin: bottom left; */
  }
  50% {
    transform: scaleY(-1);
    /* transform-origin: bottom left; */
    margin-top: 30px;
  }
  100% {
    transform: scaleY(1);
    /* transform-origin: bottom right; */
    margin-top: -60px;
  }
}

@keyframes blinkingText {
  0% {
    color: white;
  }
  49% {
    color: white;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: white;
  }
}
