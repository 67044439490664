.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fafafa00;
    .gamehost {
        flex: 1;
        flex-grow: 1;
        height: 100%;
    }
    .footer{
        flex: 0;
        flex-grow: 0;
        flex-shrink: 1;
        background-color: #b2bec338;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        font-weight: 300;
    }
}