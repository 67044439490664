.card {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 80px;
  max-height: 150px;
  height: auto;
  overflow: hidden;
  border-radius: 8px;
;
  &>div{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  img {
    height: 95%;
    display: block;
  }
  & .front {
    transform: rotateY(90deg);
    position: absolute;
    transition: all ease-in 0.2s;
    background-color: #fff;
  }
  & .flipped {

    img {
      height: 95%;
      max-width: 95%;
    }
    & .front {
      transform: rotateY(0deg);
      transition-delay: 0.2s;
    }
    & .back {
      transform: rotateY(90deg);
      transition-delay: 0s;
    }
  }
  
  & .back {
    background-color: #30b474;
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;
    flex: 1;
    width: 100%;
  }
}




