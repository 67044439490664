.win,
.lose {

  // background-color: #2eace6;
  // padding: 20px;
  // border-radius: 10px;
  // box-shadow:rgba(0, 0, 0, 0.38) 0px 0px 20px 10px;
  // color: #eeeeee;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  // border: 1px solid #2eace6bd;
  .logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    img {
      min-width: 90px;
      width: auto;
      max-width: 250px;
    }
  }

  .title {
    text-align: center;
  }

  .imageHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;

    .imgSystem {
      height: 150px;
      margin-bottom: 20px;
    }

    .imgProduct {
      width: 250px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  h4 {
    text-align: center;
    margin: 0;
    font-size: xx-large;
  }

  p {
    text-align: center;

    &:first-of-type {
      font-size: large;
    }

    &:last-child {
      font-size: small;
    }
  }
}
.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;

  .container {
    // border: 1px solid #DEDEDE;
    padding: 12px;
    // box-shadow: 0 0 4px 4px #DEDEDE;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(auto-fill, 182px);
    justify-items: center;
    align-items: stretch;
    gap: 1rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    perspective: 100%;
    max-width: 720px;
    box-sizing: border-box;
}

.card {
    width: 100%;
    height: 100%;
    max-width: 188px;
    max-height: 325px;
    border-radius: 13px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    transition: 0.3s;
    transform-style: preserve-3d;
    position: relative;
    cursor: pointer;
    background-color: #FAE9E7;
    box-sizing: border-box;    
    img {
      width: 80%;
      // height: 95%;
    }
    
    .card-face {
      backface-visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.card-front-face {
        justify-content: center;
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        img {
          width: 82px;
          // height: 100%;
        }
      }
      &.card-back-face {
        transform: rotateY(180deg);
      }
    }
  
    &.is-flipped {
      transform: rotateY(180deg);
      .card-face {
        &.card-front-face {
          opacity: 0;
        }
      }
    }
  
    &.is-inactive {
      // visibility: hidden;
      opacity: 0;
    }
  }
}
