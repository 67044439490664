$white: #fff;
$black: #000;

@mixin hc(){
  left: 50%;
  transform: translatex(-50%);
}

@mixin vc(){
  top: 50%;
  transform: translatey(-50%);
}

@mixin cc(){
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  user-select: none;

  @keyframes bounce {
    0% {
      transform: translateY(100px);
    }

    25% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(50px);
    }

    75% {
      transform: translateY(0);
    }

    95% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(0);
    }
  }
  .card_container {
    // height: 260px;
    // width: 80%;
    // max-width: 380px;
    position: relative;
    // animation: bounce 1.5s;
    // animation-timing-function: ease;
    // animation-iteration-count: 1;
    // box-shadow: 0px 0px 16px 1px #ECC33D;
    border-radius: 20px;

    .card {
      margin: auto;
      padding: 10px;
      border-radius: 1em;
      border: 1px solid transparentize($white, 0.5);
      text-shadow: 0 0 1em transparentize($black, 0.5);
      background: transparentize($white, 0.8);
      backdrop-filter: blur(20px);
      transform: perspective(500px);
      transform-style: preserve-3d;
      animation: bounce 1.5s;
      animation-timing-function: ease;
      animation-iteration-count: 1;
      box-shadow: 0px 0px 16px 1px #ECC33D;
      height: 200px;
      width: 300px;
      user-select: none;
      .helper_zone {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        user-select: none;
        text-align: center;
      }
  
      .helper_s_zone{
        color: rgb(173, 173, 173);
        font-style: italic;
        font-size: smaller;
        max-width: 100%;
        text-align: center;
        user-select: none;
      }
      .welcome {
        transform: translateZ(20px);
      }
      
      .year {
        font-size: 2.5em;
        span {
          display: block;
          transform: translateZ(40px);
          &:nth-child(2){
            padding-left: 1em;
            transform: translateZ(60px);
          }
          &:nth-child(3){
            padding-left: 2em;
            transform: translateZ(80px);
          }
        }
      }
    }
  }
  .scratch_zone {
    overflow: hidden;
    // border: 1px solid #ccc;
    background-color: rgb(83, 83, 83);
    background-image: url('./over.png');
    width: 80%;
    height: 50px;
    // bottom: 40%;
    // position: absolute;
    position: relative;
    background-size: contain;
    // z-index: 999;
    margin-left: 10%;
    margin-top: 20px;
    user-select: none;
    transform: rotateZ(100px);

    .canvas {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      position: absolute !important;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      cursor: pointer;
      user-select: none;
    }

    .code {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        margin: auto;
        user-select: none;
      }
    }
  }


  
  .cardOld {
    border: 1px solid #ccc;
    background-color: #D4AF37;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 260px;
    // width: 80%;
    max-width: 380px;
    position: relative;

    background-image: url('./pngegg.png');
    background-size: cover;

    .helper_zone {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      transform: translateZ(100px);
      user-select: none;
    }

    .helper_s_zone{
      color: rgb(41, 41, 41);
      font-style: italic;
      font-size: smaller;
      transform: translateZ(50px);
      user-select: none;
    }
  }

  // animation

  .fireflies_box {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .fireflies_item {
    position: absolute;

    -webkit-transform: translateY(-10vh);
    transform: translateY(-10vh);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  .fireflies_item:nth-child(1) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-1;
    animation-name: an-move-frames-1;
    -webkit-animation-duration: 30836ms;
    animation-duration: 30836ms;
    -webkit-animation-delay: 36509ms;
    animation-delay: 36509ms;
  }

  @-webkit-keyframes an-move-frames-1 {
    from {
      -webkit-transform: translate3d(38vw, 103vh, 0);
      transform: translate3d(38vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(6vw, -126vh, 0);
      transform: translate3d(6vw, -126vh, 0);
    }
  }

  @keyframes an-move-frames-1 {
    from {
      -webkit-transform: translate3d(38vw, 103vh, 0);
      transform: translate3d(38vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(6vw, -126vh, 0);
      transform: translate3d(6vw, -126vh, 0);
    }
  }

  .fireflies_item:nth-child(1) .fireflies_inner {
    -webkit-animation-delay: 2643ms;
    animation-delay: 2643ms;
  }

  .fireflies_item:nth-child(2) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-2;
    animation-name: an-move-frames-2;
    -webkit-animation-duration: 36977ms;
    animation-duration: 36977ms;
    -webkit-animation-delay: 18420ms;
    animation-delay: 18420ms;
  }

  @-webkit-keyframes an-move-frames-2 {
    from {
      -webkit-transform: translate3d(45vw, 109vh, 0);
      transform: translate3d(45vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(11vw, -116vh, 0);
      transform: translate3d(11vw, -116vh, 0);
    }
  }

  @keyframes an-move-frames-2 {
    from {
      -webkit-transform: translate3d(45vw, 109vh, 0);
      transform: translate3d(45vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(11vw, -116vh, 0);
      transform: translate3d(11vw, -116vh, 0);
    }
  }

  .fireflies_item:nth-child(2) .fireflies_inner {
    -webkit-animation-delay: 640ms;
    animation-delay: 640ms;
  }

  .fireflies_item:nth-child(3) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-3;
    animation-name: an-move-frames-3;
    -webkit-animation-duration: 34091ms;
    animation-duration: 34091ms;
    -webkit-animation-delay: 27559ms;
    animation-delay: 27559ms;
  }

  @-webkit-keyframes an-move-frames-3 {
    from {
      -webkit-transform: translate3d(2vw, 103vh, 0);
      transform: translate3d(2vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(94vw, -123vh, 0);
      transform: translate3d(94vw, -123vh, 0);
    }
  }

  @keyframes an-move-frames-3 {
    from {
      -webkit-transform: translate3d(2vw, 103vh, 0);
      transform: translate3d(2vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(94vw, -123vh, 0);
      transform: translate3d(94vw, -123vh, 0);
    }
  }

  .fireflies_item:nth-child(3) .fireflies_inner {
    -webkit-animation-delay: 1414ms;
    animation-delay: 1414ms;
  }

  .fireflies_item:nth-child(4) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-4;
    animation-name: an-move-frames-4;
    -webkit-animation-duration: 36598ms;
    animation-duration: 36598ms;
    -webkit-animation-delay: 14589ms;
    animation-delay: 14589ms;
  }

  @-webkit-keyframes an-move-frames-4 {
    from {
      -webkit-transform: translate3d(64vw, 103vh, 0);
      transform: translate3d(64vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(60vw, -133vh, 0);
      transform: translate3d(60vw, -133vh, 0);
    }
  }

  @keyframes an-move-frames-4 {
    from {
      -webkit-transform: translate3d(64vw, 103vh, 0);
      transform: translate3d(64vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(60vw, -133vh, 0);
      transform: translate3d(60vw, -133vh, 0);
    }
  }

  .fireflies_item:nth-child(4) .fireflies_inner {
    -webkit-animation-delay: 3343ms;
    animation-delay: 3343ms;
  }

  .fireflies_item:nth-child(5) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-5;
    animation-name: an-move-frames-5;
    -webkit-animation-duration: 34524ms;
    animation-duration: 34524ms;
    -webkit-animation-delay: 4224ms;
    animation-delay: 4224ms;
  }

  @-webkit-keyframes an-move-frames-5 {
    from {
      -webkit-transform: translate3d(51vw, 104vh, 0);
      transform: translate3d(51vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(20vw, -121vh, 0);
      transform: translate3d(20vw, -121vh, 0);
    }
  }

  @keyframes an-move-frames-5 {
    from {
      -webkit-transform: translate3d(51vw, 104vh, 0);
      transform: translate3d(51vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(20vw, -121vh, 0);
      transform: translate3d(20vw, -121vh, 0);
    }
  }

  .fireflies_item:nth-child(5) .fireflies_inner {
    -webkit-animation-delay: 1651ms;
    animation-delay: 1651ms;
  }

  .fireflies_item:nth-child(6) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-6;
    animation-name: an-move-frames-6;
    -webkit-animation-duration: 30761ms;
    animation-duration: 30761ms;
    -webkit-animation-delay: 5863ms;
    animation-delay: 5863ms;
  }

  @-webkit-keyframes an-move-frames-6 {
    from {
      -webkit-transform: translate3d(44vw, 101vh, 0);
      transform: translate3d(44vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(31vw, -109vh, 0);
      transform: translate3d(31vw, -109vh, 0);
    }
  }

  @keyframes an-move-frames-6 {
    from {
      -webkit-transform: translate3d(44vw, 101vh, 0);
      transform: translate3d(44vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(31vw, -109vh, 0);
      transform: translate3d(31vw, -109vh, 0);
    }
  }

  .fireflies_item:nth-child(6) .fireflies_inner {
    -webkit-animation-delay: 942ms;
    animation-delay: 942ms;
  }

  .fireflies_item:nth-child(7) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-7;
    animation-name: an-move-frames-7;
    -webkit-animation-duration: 35683ms;
    animation-duration: 35683ms;
    -webkit-animation-delay: 335ms;
    animation-delay: 335ms;
  }

  @-webkit-keyframes an-move-frames-7 {
    from {
      -webkit-transform: translate3d(18vw, 101vh, 0);
      transform: translate3d(18vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(11vw, -113vh, 0);
      transform: translate3d(11vw, -113vh, 0);
    }
  }

  @keyframes an-move-frames-7 {
    from {
      -webkit-transform: translate3d(18vw, 101vh, 0);
      transform: translate3d(18vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(11vw, -113vh, 0);
      transform: translate3d(11vw, -113vh, 0);
    }
  }

  .fireflies_item:nth-child(7) .fireflies_inner {
    -webkit-animation-delay: 2080ms;
    animation-delay: 2080ms;
  }

  .fireflies_item:nth-child(8) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-8;
    animation-name: an-move-frames-8;
    -webkit-animation-duration: 32920ms;
    animation-duration: 32920ms;
    -webkit-animation-delay: 20271ms;
    animation-delay: 20271ms;
  }

  @-webkit-keyframes an-move-frames-8 {
    from {
      -webkit-transform: translate3d(64vw, 109vh, 0);
      transform: translate3d(64vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(14vw, -111vh, 0);
      transform: translate3d(14vw, -111vh, 0);
    }
  }

  @keyframes an-move-frames-8 {
    from {
      -webkit-transform: translate3d(64vw, 109vh, 0);
      transform: translate3d(64vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(14vw, -111vh, 0);
      transform: translate3d(14vw, -111vh, 0);
    }
  }

  .fireflies_item:nth-child(8) .fireflies_inner {
    -webkit-animation-delay: 1390ms;
    animation-delay: 1390ms;
  }

  .fireflies_item:nth-child(9) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-9;
    animation-name: an-move-frames-9;
    -webkit-animation-duration: 35658ms;
    animation-duration: 35658ms;
    -webkit-animation-delay: 21515ms;
    animation-delay: 21515ms;
  }

  @-webkit-keyframes an-move-frames-9 {
    from {
      -webkit-transform: translate3d(57vw, 110vh, 0);
      transform: translate3d(57vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(43vw, -126vh, 0);
      transform: translate3d(43vw, -126vh, 0);
    }
  }

  @keyframes an-move-frames-9 {
    from {
      -webkit-transform: translate3d(57vw, 110vh, 0);
      transform: translate3d(57vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(43vw, -126vh, 0);
      transform: translate3d(43vw, -126vh, 0);
    }
  }

  .fireflies_item:nth-child(9) .fireflies_inner {
    -webkit-animation-delay: 2381ms;
    animation-delay: 2381ms;
  }

  .fireflies_item:nth-child(10) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-10;
    animation-name: an-move-frames-10;
    -webkit-animation-duration: 28922ms;
    animation-duration: 28922ms;
    -webkit-animation-delay: 29337ms;
    animation-delay: 29337ms;
  }

  @-webkit-keyframes an-move-frames-10 {
    from {
      -webkit-transform: translate3d(31vw, 110vh, 0);
      transform: translate3d(31vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(42vw, -132vh, 0);
      transform: translate3d(42vw, -132vh, 0);
    }
  }

  @keyframes an-move-frames-10 {
    from {
      -webkit-transform: translate3d(31vw, 110vh, 0);
      transform: translate3d(31vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(42vw, -132vh, 0);
      transform: translate3d(42vw, -132vh, 0);
    }
  }

  .fireflies_item:nth-child(10) .fireflies_inner {
    -webkit-animation-delay: 665ms;
    animation-delay: 665ms;
  }

  .fireflies_item:nth-child(11) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-11;
    animation-name: an-move-frames-11;
    -webkit-animation-duration: 34311ms;
    animation-duration: 34311ms;
    -webkit-animation-delay: 36829ms;
    animation-delay: 36829ms;
  }

  @-webkit-keyframes an-move-frames-11 {
    from {
      -webkit-transform: translate3d(8vw, 102vh, 0);
      transform: translate3d(8vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(36vw, -122vh, 0);
      transform: translate3d(36vw, -122vh, 0);
    }
  }

  @keyframes an-move-frames-11 {
    from {
      -webkit-transform: translate3d(8vw, 102vh, 0);
      transform: translate3d(8vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(36vw, -122vh, 0);
      transform: translate3d(36vw, -122vh, 0);
    }
  }

  .fireflies_item:nth-child(11) .fireflies_inner {
    -webkit-animation-delay: 391ms;
    animation-delay: 391ms;
  }

  .fireflies_item:nth-child(12) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-12;
    animation-name: an-move-frames-12;
    -webkit-animation-duration: 29298ms;
    animation-duration: 29298ms;
    -webkit-animation-delay: 7594ms;
    animation-delay: 7594ms;
  }

  @-webkit-keyframes an-move-frames-12 {
    from {
      -webkit-transform: translate3d(49vw, 109vh, 0);
      transform: translate3d(49vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(53vw, -114vh, 0);
      transform: translate3d(53vw, -114vh, 0);
    }
  }

  @keyframes an-move-frames-12 {
    from {
      -webkit-transform: translate3d(49vw, 109vh, 0);
      transform: translate3d(49vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(53vw, -114vh, 0);
      transform: translate3d(53vw, -114vh, 0);
    }
  }

  .fireflies_item:nth-child(12) .fireflies_inner {
    -webkit-animation-delay: 1354ms;
    animation-delay: 1354ms;
  }

  .fireflies_item:nth-child(13) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-13;
    animation-name: an-move-frames-13;
    -webkit-animation-duration: 35981ms;
    animation-duration: 35981ms;
    -webkit-animation-delay: 24206ms;
    animation-delay: 24206ms;
  }

  @-webkit-keyframes an-move-frames-13 {
    from {
      -webkit-transform: translate3d(16vw, 109vh, 0);
      transform: translate3d(16vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(20vw, -128vh, 0);
      transform: translate3d(20vw, -128vh, 0);
    }
  }

  @keyframes an-move-frames-13 {
    from {
      -webkit-transform: translate3d(16vw, 109vh, 0);
      transform: translate3d(16vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(20vw, -128vh, 0);
      transform: translate3d(20vw, -128vh, 0);
    }
  }

  .fireflies_item:nth-child(13) .fireflies_inner {
    -webkit-animation-delay: 2206ms;
    animation-delay: 2206ms;
  }

  .fireflies_item:nth-child(14) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-14;
    animation-name: an-move-frames-14;
    -webkit-animation-duration: 29364ms;
    animation-duration: 29364ms;
    -webkit-animation-delay: 11053ms;
    animation-delay: 11053ms;
  }

  @-webkit-keyframes an-move-frames-14 {
    from {
      -webkit-transform: translate3d(70vw, 109vh, 0);
      transform: translate3d(70vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(26vw, -128vh, 0);
      transform: translate3d(26vw, -128vh, 0);
    }
  }

  @keyframes an-move-frames-14 {
    from {
      -webkit-transform: translate3d(70vw, 109vh, 0);
      transform: translate3d(70vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(26vw, -128vh, 0);
      transform: translate3d(26vw, -128vh, 0);
    }
  }

  .fireflies_item:nth-child(14) .fireflies_inner {
    -webkit-animation-delay: 2037ms;
    animation-delay: 2037ms;
  }

  .fireflies_item:nth-child(15) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-15;
    animation-name: an-move-frames-15;
    -webkit-animation-duration: 32518ms;
    animation-duration: 32518ms;
    -webkit-animation-delay: 29599ms;
    animation-delay: 29599ms;
  }

  @-webkit-keyframes an-move-frames-15 {
    from {
      -webkit-transform: translate3d(22vw, 104vh, 0);
      transform: translate3d(22vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(49vw, -108vh, 0);
      transform: translate3d(49vw, -108vh, 0);
    }
  }

  @keyframes an-move-frames-15 {
    from {
      -webkit-transform: translate3d(22vw, 104vh, 0);
      transform: translate3d(22vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(49vw, -108vh, 0);
      transform: translate3d(49vw, -108vh, 0);
    }
  }

  .fireflies_item:nth-child(15) .fireflies_inner {
    -webkit-animation-delay: 2268ms;
    animation-delay: 2268ms;
  }

  .fireflies_item:nth-child(16) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-16;
    animation-name: an-move-frames-16;
    -webkit-animation-duration: 30881ms;
    animation-duration: 30881ms;
    -webkit-animation-delay: 19913ms;
    animation-delay: 19913ms;
  }

  @-webkit-keyframes an-move-frames-16 {
    from {
      -webkit-transform: translate3d(16vw, 104vh, 0);
      transform: translate3d(16vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(54vw, -113vh, 0);
      transform: translate3d(54vw, -113vh, 0);
    }
  }

  @keyframes an-move-frames-16 {
    from {
      -webkit-transform: translate3d(16vw, 104vh, 0);
      transform: translate3d(16vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(54vw, -113vh, 0);
      transform: translate3d(54vw, -113vh, 0);
    }
  }

  .fireflies_item:nth-child(16) .fireflies_inner {
    -webkit-animation-delay: 2386ms;
    animation-delay: 2386ms;
  }

  .fireflies_item:nth-child(17) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-17;
    animation-name: an-move-frames-17;
    -webkit-animation-duration: 35171ms;
    animation-duration: 35171ms;
    -webkit-animation-delay: 32356ms;
    animation-delay: 32356ms;
  }

  @-webkit-keyframes an-move-frames-17 {
    from {
      -webkit-transform: translate3d(36vw, 104vh, 0);
      transform: translate3d(36vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(60vw, -124vh, 0);
      transform: translate3d(60vw, -124vh, 0);
    }
  }

  @keyframes an-move-frames-17 {
    from {
      -webkit-transform: translate3d(36vw, 104vh, 0);
      transform: translate3d(36vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(60vw, -124vh, 0);
      transform: translate3d(60vw, -124vh, 0);
    }
  }

  .fireflies_item:nth-child(17) .fireflies_inner {
    -webkit-animation-delay: 1050ms;
    animation-delay: 1050ms;
  }

  .fireflies_item:nth-child(18) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-18;
    animation-name: an-move-frames-18;
    -webkit-animation-duration: 31120ms;
    animation-duration: 31120ms;
    -webkit-animation-delay: 30228ms;
    animation-delay: 30228ms;
  }

  @-webkit-keyframes an-move-frames-18 {
    from {
      -webkit-transform: translate3d(18vw, 102vh, 0);
      transform: translate3d(18vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(41vw, -104vh, 0);
      transform: translate3d(41vw, -104vh, 0);
    }
  }

  @keyframes an-move-frames-18 {
    from {
      -webkit-transform: translate3d(18vw, 102vh, 0);
      transform: translate3d(18vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(41vw, -104vh, 0);
      transform: translate3d(41vw, -104vh, 0);
    }
  }

  .fireflies_item:nth-child(18) .fireflies_inner {
    -webkit-animation-delay: 532ms;
    animation-delay: 532ms;
  }

  .fireflies_item:nth-child(19) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-19;
    animation-name: an-move-frames-19;
    -webkit-animation-duration: 36883ms;
    animation-duration: 36883ms;
    -webkit-animation-delay: 15381ms;
    animation-delay: 15381ms;
  }

  @-webkit-keyframes an-move-frames-19 {
    from {
      -webkit-transform: translate3d(96vw, 109vh, 0);
      transform: translate3d(96vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(93vw, -119vh, 0);
      transform: translate3d(93vw, -119vh, 0);
    }
  }

  @keyframes an-move-frames-19 {
    from {
      -webkit-transform: translate3d(96vw, 109vh, 0);
      transform: translate3d(96vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(93vw, -119vh, 0);
      transform: translate3d(93vw, -119vh, 0);
    }
  }

  .fireflies_item:nth-child(19) .fireflies_inner {
    -webkit-animation-delay: 712ms;
    animation-delay: 712ms;
  }

  .fireflies_item:nth-child(20) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-20;
    animation-name: an-move-frames-20;
    -webkit-animation-duration: 28164ms;
    animation-duration: 28164ms;
    -webkit-animation-delay: 20151ms;
    animation-delay: 20151ms;
  }

  @-webkit-keyframes an-move-frames-20 {
    from {
      -webkit-transform: translate3d(69vw, 105vh, 0);
      transform: translate3d(69vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(8vw, -134vh, 0);
      transform: translate3d(8vw, -134vh, 0);
    }
  }

  @keyframes an-move-frames-20 {
    from {
      -webkit-transform: translate3d(69vw, 105vh, 0);
      transform: translate3d(69vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(8vw, -134vh, 0);
      transform: translate3d(8vw, -134vh, 0);
    }
  }

  .fireflies_item:nth-child(20) .fireflies_inner {
    -webkit-animation-delay: 765ms;
    animation-delay: 765ms;
  }

  .fireflies_item:nth-child(21) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-21;
    animation-name: an-move-frames-21;
    -webkit-animation-duration: 33848ms;
    animation-duration: 33848ms;
    -webkit-animation-delay: 33743ms;
    animation-delay: 33743ms;
  }

  @-webkit-keyframes an-move-frames-21 {
    from {
      -webkit-transform: translate3d(24vw, 107vh, 0);
      transform: translate3d(24vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(4vw, -128vh, 0);
      transform: translate3d(4vw, -128vh, 0);
    }
  }

  @keyframes an-move-frames-21 {
    from {
      -webkit-transform: translate3d(24vw, 107vh, 0);
      transform: translate3d(24vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(4vw, -128vh, 0);
      transform: translate3d(4vw, -128vh, 0);
    }
  }

  .fireflies_item:nth-child(21) .fireflies_inner {
    -webkit-animation-delay: 210ms;
    animation-delay: 210ms;
  }

  .fireflies_item:nth-child(22) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-22;
    animation-name: an-move-frames-22;
    -webkit-animation-duration: 31170ms;
    animation-duration: 31170ms;
    -webkit-animation-delay: 15178ms;
    animation-delay: 15178ms;
  }

  @-webkit-keyframes an-move-frames-22 {
    from {
      -webkit-transform: translate3d(100vw, 110vh, 0);
      transform: translate3d(100vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(33vw, -140vh, 0);
      transform: translate3d(33vw, -140vh, 0);
    }
  }

  @keyframes an-move-frames-22 {
    from {
      -webkit-transform: translate3d(100vw, 110vh, 0);
      transform: translate3d(100vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(33vw, -140vh, 0);
      transform: translate3d(33vw, -140vh, 0);
    }
  }

  .fireflies_item:nth-child(22) .fireflies_inner {
    -webkit-animation-delay: 1880ms;
    animation-delay: 1880ms;
  }

  .fireflies_item:nth-child(23) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-23;
    animation-name: an-move-frames-23;
    -webkit-animation-duration: 36460ms;
    animation-duration: 36460ms;
    -webkit-animation-delay: 18779ms;
    animation-delay: 18779ms;
  }

  @-webkit-keyframes an-move-frames-23 {
    from {
      -webkit-transform: translate3d(75vw, 110vh, 0);
      transform: translate3d(75vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(100vw, -120vh, 0);
      transform: translate3d(100vw, -120vh, 0);
    }
  }

  @keyframes an-move-frames-23 {
    from {
      -webkit-transform: translate3d(75vw, 110vh, 0);
      transform: translate3d(75vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(100vw, -120vh, 0);
      transform: translate3d(100vw, -120vh, 0);
    }
  }

  .fireflies_item:nth-child(23) .fireflies_inner {
    -webkit-animation-delay: 3461ms;
    animation-delay: 3461ms;
  }

  .fireflies_item:nth-child(24) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-24;
    animation-name: an-move-frames-24;
    -webkit-animation-duration: 36465ms;
    animation-duration: 36465ms;
    -webkit-animation-delay: 24124ms;
    animation-delay: 24124ms;
  }

  @-webkit-keyframes an-move-frames-24 {
    from {
      -webkit-transform: translate3d(27vw, 106vh, 0);
      transform: translate3d(27vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(94vw, -134vh, 0);
      transform: translate3d(94vw, -134vh, 0);
    }
  }

  @keyframes an-move-frames-24 {
    from {
      -webkit-transform: translate3d(27vw, 106vh, 0);
      transform: translate3d(27vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(94vw, -134vh, 0);
      transform: translate3d(94vw, -134vh, 0);
    }
  }

  .fireflies_item:nth-child(24) .fireflies_inner {
    -webkit-animation-delay: 724ms;
    animation-delay: 724ms;
  }

  .fireflies_item:nth-child(25) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-25;
    animation-name: an-move-frames-25;
    -webkit-animation-duration: 32518ms;
    animation-duration: 32518ms;
    -webkit-animation-delay: 5397ms;
    animation-delay: 5397ms;
  }

  @-webkit-keyframes an-move-frames-25 {
    from {
      -webkit-transform: translate3d(84vw, 110vh, 0);
      transform: translate3d(84vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(25vw, -118vh, 0);
      transform: translate3d(25vw, -118vh, 0);
    }
  }

  @keyframes an-move-frames-25 {
    from {
      -webkit-transform: translate3d(84vw, 110vh, 0);
      transform: translate3d(84vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(25vw, -118vh, 0);
      transform: translate3d(25vw, -118vh, 0);
    }
  }

  .fireflies_item:nth-child(25) .fireflies_inner {
    -webkit-animation-delay: 3298ms;
    animation-delay: 3298ms;
  }

  .fireflies_item:nth-child(26) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-26;
    animation-name: an-move-frames-26;
    -webkit-animation-duration: 31825ms;
    animation-duration: 31825ms;
    -webkit-animation-delay: 36590ms;
    animation-delay: 36590ms;
  }

  @-webkit-keyframes an-move-frames-26 {
    from {
      -webkit-transform: translate3d(36vw, 101vh, 0);
      transform: translate3d(36vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(2vw, -108vh, 0);
      transform: translate3d(2vw, -108vh, 0);
    }
  }

  @keyframes an-move-frames-26 {
    from {
      -webkit-transform: translate3d(36vw, 101vh, 0);
      transform: translate3d(36vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(2vw, -108vh, 0);
      transform: translate3d(2vw, -108vh, 0);
    }
  }

  .fireflies_item:nth-child(26) .fireflies_inner {
    -webkit-animation-delay: 817ms;
    animation-delay: 817ms;
  }

  .fireflies_item:nth-child(27) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-27;
    animation-name: an-move-frames-27;
    -webkit-animation-duration: 29850ms;
    animation-duration: 29850ms;
    -webkit-animation-delay: 32641ms;
    animation-delay: 32641ms;
  }

  @-webkit-keyframes an-move-frames-27 {
    from {
      -webkit-transform: translate3d(56vw, 102vh, 0);
      transform: translate3d(56vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(67vw, -105vh, 0);
      transform: translate3d(67vw, -105vh, 0);
    }
  }

  @keyframes an-move-frames-27 {
    from {
      -webkit-transform: translate3d(56vw, 102vh, 0);
      transform: translate3d(56vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(67vw, -105vh, 0);
      transform: translate3d(67vw, -105vh, 0);
    }
  }

  .fireflies_item:nth-child(27) .fireflies_inner {
    -webkit-animation-delay: 1862ms;
    animation-delay: 1862ms;
  }

  .fireflies_item:nth-child(28) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-28;
    animation-name: an-move-frames-28;
    -webkit-animation-duration: 31107ms;
    animation-duration: 31107ms;
    -webkit-animation-delay: 28831ms;
    animation-delay: 28831ms;
  }

  @-webkit-keyframes an-move-frames-28 {
    from {
      -webkit-transform: translate3d(61vw, 109vh, 0);
      transform: translate3d(61vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(87vw, -110vh, 0);
      transform: translate3d(87vw, -110vh, 0);
    }
  }

  @keyframes an-move-frames-28 {
    from {
      -webkit-transform: translate3d(61vw, 109vh, 0);
      transform: translate3d(61vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(87vw, -110vh, 0);
      transform: translate3d(87vw, -110vh, 0);
    }
  }

  .fireflies_item:nth-child(28) .fireflies_inner {
    -webkit-animation-delay: 154ms;
    animation-delay: 154ms;
  }

  .fireflies_item:nth-child(29) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-29;
    animation-name: an-move-frames-29;
    -webkit-animation-duration: 35404ms;
    animation-duration: 35404ms;
    -webkit-animation-delay: 3439ms;
    animation-delay: 3439ms;
  }

  @-webkit-keyframes an-move-frames-29 {
    from {
      -webkit-transform: translate3d(98vw, 108vh, 0);
      transform: translate3d(98vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(80vw, -111vh, 0);
      transform: translate3d(80vw, -111vh, 0);
    }
  }

  @keyframes an-move-frames-29 {
    from {
      -webkit-transform: translate3d(98vw, 108vh, 0);
      transform: translate3d(98vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(80vw, -111vh, 0);
      transform: translate3d(80vw, -111vh, 0);
    }
  }

  .fireflies_item:nth-child(29) .fireflies_inner {
    -webkit-animation-delay: 3868ms;
    animation-delay: 3868ms;
  }

  .fireflies_item:nth-child(30) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-30;
    animation-name: an-move-frames-30;
    -webkit-animation-duration: 36937ms;
    animation-duration: 36937ms;
    -webkit-animation-delay: 5982ms;
    animation-delay: 5982ms;
  }

  @-webkit-keyframes an-move-frames-30 {
    from {
      -webkit-transform: translate3d(9vw, 106vh, 0);
      transform: translate3d(9vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(81vw, -125vh, 0);
      transform: translate3d(81vw, -125vh, 0);
    }
  }

  @keyframes an-move-frames-30 {
    from {
      -webkit-transform: translate3d(9vw, 106vh, 0);
      transform: translate3d(9vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(81vw, -125vh, 0);
      transform: translate3d(81vw, -125vh, 0);
    }
  }

  .fireflies_item:nth-child(30) .fireflies_inner {
    -webkit-animation-delay: 2174ms;
    animation-delay: 2174ms;
  }

  .fireflies_item:nth-child(31) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-31;
    animation-name: an-move-frames-31;
    -webkit-animation-duration: 34865ms;
    animation-duration: 34865ms;
    -webkit-animation-delay: 5067ms;
    animation-delay: 5067ms;
  }

  @-webkit-keyframes an-move-frames-31 {
    from {
      -webkit-transform: translate3d(81vw, 105vh, 0);
      transform: translate3d(81vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(90vw, -120vh, 0);
      transform: translate3d(90vw, -120vh, 0);
    }
  }

  @keyframes an-move-frames-31 {
    from {
      -webkit-transform: translate3d(81vw, 105vh, 0);
      transform: translate3d(81vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(90vw, -120vh, 0);
      transform: translate3d(90vw, -120vh, 0);
    }
  }

  .fireflies_item:nth-child(31) .fireflies_inner {
    -webkit-animation-delay: 2344ms;
    animation-delay: 2344ms;
  }

  .fireflies_item:nth-child(32) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-32;
    animation-name: an-move-frames-32;
    -webkit-animation-duration: 32361ms;
    animation-duration: 32361ms;
    -webkit-animation-delay: 498ms;
    animation-delay: 498ms;
  }

  @-webkit-keyframes an-move-frames-32 {
    from {
      -webkit-transform: translate3d(70vw, 106vh, 0);
      transform: translate3d(70vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(24vw, -132vh, 0);
      transform: translate3d(24vw, -132vh, 0);
    }
  }

  @keyframes an-move-frames-32 {
    from {
      -webkit-transform: translate3d(70vw, 106vh, 0);
      transform: translate3d(70vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(24vw, -132vh, 0);
      transform: translate3d(24vw, -132vh, 0);
    }
  }

  .fireflies_item:nth-child(32) .fireflies_inner {
    -webkit-animation-delay: 3741ms;
    animation-delay: 3741ms;
  }

  .fireflies_item:nth-child(33) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-33;
    animation-name: an-move-frames-33;
    -webkit-animation-duration: 30724ms;
    animation-duration: 30724ms;
    -webkit-animation-delay: 23120ms;
    animation-delay: 23120ms;
  }

  @-webkit-keyframes an-move-frames-33 {
    from {
      -webkit-transform: translate3d(5vw, 110vh, 0);
      transform: translate3d(5vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(12vw, -132vh, 0);
      transform: translate3d(12vw, -132vh, 0);
    }
  }

  @keyframes an-move-frames-33 {
    from {
      -webkit-transform: translate3d(5vw, 110vh, 0);
      transform: translate3d(5vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(12vw, -132vh, 0);
      transform: translate3d(12vw, -132vh, 0);
    }
  }

  .fireflies_item:nth-child(33) .fireflies_inner {
    -webkit-animation-delay: 3889ms;
    animation-delay: 3889ms;
  }

  .fireflies_item:nth-child(34) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-34;
    animation-name: an-move-frames-34;
    -webkit-animation-duration: 29447ms;
    animation-duration: 29447ms;
    -webkit-animation-delay: 19281ms;
    animation-delay: 19281ms;
  }

  @-webkit-keyframes an-move-frames-34 {
    from {
      -webkit-transform: translate3d(34vw, 103vh, 0);
      transform: translate3d(34vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(74vw, -121vh, 0);
      transform: translate3d(74vw, -121vh, 0);
    }
  }

  @keyframes an-move-frames-34 {
    from {
      -webkit-transform: translate3d(34vw, 103vh, 0);
      transform: translate3d(34vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(74vw, -121vh, 0);
      transform: translate3d(74vw, -121vh, 0);
    }
  }

  .fireflies_item:nth-child(34) .fireflies_inner {
    -webkit-animation-delay: 2647ms;
    animation-delay: 2647ms;
  }

  .fireflies_item:nth-child(35) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-35;
    animation-name: an-move-frames-35;
    -webkit-animation-duration: 28871ms;
    animation-duration: 28871ms;
    -webkit-animation-delay: 44ms;
    animation-delay: 44ms;
  }

  @-webkit-keyframes an-move-frames-35 {
    from {
      -webkit-transform: translate3d(35vw, 107vh, 0);
      transform: translate3d(35vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(53vw, -133vh, 0);
      transform: translate3d(53vw, -133vh, 0);
    }
  }

  @keyframes an-move-frames-35 {
    from {
      -webkit-transform: translate3d(35vw, 107vh, 0);
      transform: translate3d(35vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(53vw, -133vh, 0);
      transform: translate3d(53vw, -133vh, 0);
    }
  }

  .fireflies_item:nth-child(35) .fireflies_inner {
    -webkit-animation-delay: 1069ms;
    animation-delay: 1069ms;
  }

  .fireflies_item:nth-child(36) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-36;
    animation-name: an-move-frames-36;
    -webkit-animation-duration: 36591ms;
    animation-duration: 36591ms;
    -webkit-animation-delay: 19232ms;
    animation-delay: 19232ms;
  }

  @-webkit-keyframes an-move-frames-36 {
    from {
      -webkit-transform: translate3d(24vw, 105vh, 0);
      transform: translate3d(24vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(39vw, -123vh, 0);
      transform: translate3d(39vw, -123vh, 0);
    }
  }

  @keyframes an-move-frames-36 {
    from {
      -webkit-transform: translate3d(24vw, 105vh, 0);
      transform: translate3d(24vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(39vw, -123vh, 0);
      transform: translate3d(39vw, -123vh, 0);
    }
  }

  .fireflies_item:nth-child(36) .fireflies_inner {
    -webkit-animation-delay: 2251ms;
    animation-delay: 2251ms;
  }

  .fireflies_item:nth-child(37) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-37;
    animation-name: an-move-frames-37;
    -webkit-animation-duration: 35870ms;
    animation-duration: 35870ms;
    -webkit-animation-delay: 36018ms;
    animation-delay: 36018ms;
  }

  @-webkit-keyframes an-move-frames-37 {
    from {
      -webkit-transform: translate3d(9vw, 106vh, 0);
      transform: translate3d(9vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(8vw, -135vh, 0);
      transform: translate3d(8vw, -135vh, 0);
    }
  }

  @keyframes an-move-frames-37 {
    from {
      -webkit-transform: translate3d(9vw, 106vh, 0);
      transform: translate3d(9vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(8vw, -135vh, 0);
      transform: translate3d(8vw, -135vh, 0);
    }
  }

  .fireflies_item:nth-child(37) .fireflies_inner {
    -webkit-animation-delay: 3925ms;
    animation-delay: 3925ms;
  }

  .fireflies_item:nth-child(38) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-38;
    animation-name: an-move-frames-38;
    -webkit-animation-duration: 34675ms;
    animation-duration: 34675ms;
    -webkit-animation-delay: 7970ms;
    animation-delay: 7970ms;
  }

  @-webkit-keyframes an-move-frames-38 {
    from {
      -webkit-transform: translate3d(13vw, 108vh, 0);
      transform: translate3d(13vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(78vw, -116vh, 0);
      transform: translate3d(78vw, -116vh, 0);
    }
  }

  @keyframes an-move-frames-38 {
    from {
      -webkit-transform: translate3d(13vw, 108vh, 0);
      transform: translate3d(13vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(78vw, -116vh, 0);
      transform: translate3d(78vw, -116vh, 0);
    }
  }

  .fireflies_item:nth-child(38) .fireflies_inner {
    -webkit-animation-delay: 2900ms;
    animation-delay: 2900ms;
  }

  .fireflies_item:nth-child(39) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-39;
    animation-name: an-move-frames-39;
    -webkit-animation-duration: 36284ms;
    animation-duration: 36284ms;
    -webkit-animation-delay: 5032ms;
    animation-delay: 5032ms;
  }

  @-webkit-keyframes an-move-frames-39 {
    from {
      -webkit-transform: translate3d(37vw, 104vh, 0);
      transform: translate3d(37vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(9vw, -130vh, 0);
      transform: translate3d(9vw, -130vh, 0);
    }
  }

  @keyframes an-move-frames-39 {
    from {
      -webkit-transform: translate3d(37vw, 104vh, 0);
      transform: translate3d(37vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(9vw, -130vh, 0);
      transform: translate3d(9vw, -130vh, 0);
    }
  }

  .fireflies_item:nth-child(39) .fireflies_inner {
    -webkit-animation-delay: 139ms;
    animation-delay: 139ms;
  }

  .fireflies_item:nth-child(40) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-40;
    animation-name: an-move-frames-40;
    -webkit-animation-duration: 35245ms;
    animation-duration: 35245ms;
    -webkit-animation-delay: 561ms;
    animation-delay: 561ms;
  }

  @-webkit-keyframes an-move-frames-40 {
    from {
      -webkit-transform: translate3d(39vw, 107vh, 0);
      transform: translate3d(39vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(29vw, -135vh, 0);
      transform: translate3d(29vw, -135vh, 0);
    }
  }

  @keyframes an-move-frames-40 {
    from {
      -webkit-transform: translate3d(39vw, 107vh, 0);
      transform: translate3d(39vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(29vw, -135vh, 0);
      transform: translate3d(29vw, -135vh, 0);
    }
  }

  .fireflies_item:nth-child(40) .fireflies_inner {
    -webkit-animation-delay: 2995ms;
    animation-delay: 2995ms;
  }

  .fireflies_item:nth-child(41) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-41;
    animation-name: an-move-frames-41;
    -webkit-animation-duration: 33476ms;
    animation-duration: 33476ms;
    -webkit-animation-delay: 23167ms;
    animation-delay: 23167ms;
  }

  @-webkit-keyframes an-move-frames-41 {
    from {
      -webkit-transform: translate3d(56vw, 106vh, 0);
      transform: translate3d(56vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(69vw, -123vh, 0);
      transform: translate3d(69vw, -123vh, 0);
    }
  }

  @keyframes an-move-frames-41 {
    from {
      -webkit-transform: translate3d(56vw, 106vh, 0);
      transform: translate3d(56vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(69vw, -123vh, 0);
      transform: translate3d(69vw, -123vh, 0);
    }
  }

  .fireflies_item:nth-child(41) .fireflies_inner {
    -webkit-animation-delay: 2553ms;
    animation-delay: 2553ms;
  }

  .fireflies_item:nth-child(42) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-42;
    animation-name: an-move-frames-42;
    -webkit-animation-duration: 36551ms;
    animation-duration: 36551ms;
    -webkit-animation-delay: 8464ms;
    animation-delay: 8464ms;
  }

  @-webkit-keyframes an-move-frames-42 {
    from {
      -webkit-transform: translate3d(50vw, 106vh, 0);
      transform: translate3d(50vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(48vw, -125vh, 0);
      transform: translate3d(48vw, -125vh, 0);
    }
  }

  @keyframes an-move-frames-42 {
    from {
      -webkit-transform: translate3d(50vw, 106vh, 0);
      transform: translate3d(50vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(48vw, -125vh, 0);
      transform: translate3d(48vw, -125vh, 0);
    }
  }

  .fireflies_item:nth-child(42) .fireflies_inner {
    -webkit-animation-delay: 1514ms;
    animation-delay: 1514ms;
  }

  .fireflies_item:nth-child(43) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-43;
    animation-name: an-move-frames-43;
    -webkit-animation-duration: 30314ms;
    animation-duration: 30314ms;
    -webkit-animation-delay: 16490ms;
    animation-delay: 16490ms;
  }

  @-webkit-keyframes an-move-frames-43 {
    from {
      -webkit-transform: translate3d(3vw, 105vh, 0);
      transform: translate3d(3vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(42vw, -111vh, 0);
      transform: translate3d(42vw, -111vh, 0);
    }
  }

  @keyframes an-move-frames-43 {
    from {
      -webkit-transform: translate3d(3vw, 105vh, 0);
      transform: translate3d(3vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(42vw, -111vh, 0);
      transform: translate3d(42vw, -111vh, 0);
    }
  }

  .fireflies_item:nth-child(43) .fireflies_inner {
    -webkit-animation-delay: 2882ms;
    animation-delay: 2882ms;
  }

  .fireflies_item:nth-child(44) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-44;
    animation-name: an-move-frames-44;
    -webkit-animation-duration: 32648ms;
    animation-duration: 32648ms;
    -webkit-animation-delay: 21420ms;
    animation-delay: 21420ms;
  }

  @-webkit-keyframes an-move-frames-44 {
    from {
      -webkit-transform: translate3d(15vw, 107vh, 0);
      transform: translate3d(15vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(28vw, -117vh, 0);
      transform: translate3d(28vw, -117vh, 0);
    }
  }

  @keyframes an-move-frames-44 {
    from {
      -webkit-transform: translate3d(15vw, 107vh, 0);
      transform: translate3d(15vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(28vw, -117vh, 0);
      transform: translate3d(28vw, -117vh, 0);
    }
  }

  .fireflies_item:nth-child(44) .fireflies_inner {
    -webkit-animation-delay: 1934ms;
    animation-delay: 1934ms;
  }

  .fireflies_item:nth-child(45) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-45;
    animation-name: an-move-frames-45;
    -webkit-animation-duration: 36314ms;
    animation-duration: 36314ms;
    -webkit-animation-delay: 4743ms;
    animation-delay: 4743ms;
  }

  @-webkit-keyframes an-move-frames-45 {
    from {
      -webkit-transform: translate3d(56vw, 106vh, 0);
      transform: translate3d(56vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(16vw, -127vh, 0);
      transform: translate3d(16vw, -127vh, 0);
    }
  }

  @keyframes an-move-frames-45 {
    from {
      -webkit-transform: translate3d(56vw, 106vh, 0);
      transform: translate3d(56vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(16vw, -127vh, 0);
      transform: translate3d(16vw, -127vh, 0);
    }
  }

  .fireflies_item:nth-child(45) .fireflies_inner {
    -webkit-animation-delay: 3071ms;
    animation-delay: 3071ms;
  }

  .fireflies_item:nth-child(46) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-46;
    animation-name: an-move-frames-46;
    -webkit-animation-duration: 33142ms;
    animation-duration: 33142ms;
    -webkit-animation-delay: 33251ms;
    animation-delay: 33251ms;
  }

  @-webkit-keyframes an-move-frames-46 {
    from {
      -webkit-transform: translate3d(17vw, 106vh, 0);
      transform: translate3d(17vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(61vw, -134vh, 0);
      transform: translate3d(61vw, -134vh, 0);
    }
  }

  @keyframes an-move-frames-46 {
    from {
      -webkit-transform: translate3d(17vw, 106vh, 0);
      transform: translate3d(17vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(61vw, -134vh, 0);
      transform: translate3d(61vw, -134vh, 0);
    }
  }

  .fireflies_item:nth-child(46) .fireflies_inner {
    -webkit-animation-delay: 2586ms;
    animation-delay: 2586ms;
  }

  .fireflies_item:nth-child(47) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-47;
    animation-name: an-move-frames-47;
    -webkit-animation-duration: 33829ms;
    animation-duration: 33829ms;
    -webkit-animation-delay: 19254ms;
    animation-delay: 19254ms;
  }

  @-webkit-keyframes an-move-frames-47 {
    from {
      -webkit-transform: translate3d(98vw, 109vh, 0);
      transform: translate3d(98vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(27vw, -137vh, 0);
      transform: translate3d(27vw, -137vh, 0);
    }
  }

  @keyframes an-move-frames-47 {
    from {
      -webkit-transform: translate3d(98vw, 109vh, 0);
      transform: translate3d(98vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(27vw, -137vh, 0);
      transform: translate3d(27vw, -137vh, 0);
    }
  }

  .fireflies_item:nth-child(47) .fireflies_inner {
    -webkit-animation-delay: 118ms;
    animation-delay: 118ms;
  }

  .fireflies_item:nth-child(48) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-48;
    animation-name: an-move-frames-48;
    -webkit-animation-duration: 32437ms;
    animation-duration: 32437ms;
    -webkit-animation-delay: 11242ms;
    animation-delay: 11242ms;
  }

  @-webkit-keyframes an-move-frames-48 {
    from {
      -webkit-transform: translate3d(29vw, 101vh, 0);
      transform: translate3d(29vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(79vw, -131vh, 0);
      transform: translate3d(79vw, -131vh, 0);
    }
  }

  @keyframes an-move-frames-48 {
    from {
      -webkit-transform: translate3d(29vw, 101vh, 0);
      transform: translate3d(29vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(79vw, -131vh, 0);
      transform: translate3d(79vw, -131vh, 0);
    }
  }

  .fireflies_item:nth-child(48) .fireflies_inner {
    -webkit-animation-delay: 597ms;
    animation-delay: 597ms;
  }

  .fireflies_item:nth-child(49) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-49;
    animation-name: an-move-frames-49;
    -webkit-animation-duration: 28741ms;
    animation-duration: 28741ms;
    -webkit-animation-delay: 36249ms;
    animation-delay: 36249ms;
  }

  @-webkit-keyframes an-move-frames-49 {
    from {
      -webkit-transform: translate3d(90vw, 101vh, 0);
      transform: translate3d(90vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(47vw, -108vh, 0);
      transform: translate3d(47vw, -108vh, 0);
    }
  }

  @keyframes an-move-frames-49 {
    from {
      -webkit-transform: translate3d(90vw, 101vh, 0);
      transform: translate3d(90vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(47vw, -108vh, 0);
      transform: translate3d(47vw, -108vh, 0);
    }
  }

  .fireflies_item:nth-child(49) .fireflies_inner {
    -webkit-animation-delay: 1401ms;
    animation-delay: 1401ms;
  }

  .fireflies_item:nth-child(50) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-50;
    animation-name: an-move-frames-50;
    -webkit-animation-duration: 28592ms;
    animation-duration: 28592ms;
    -webkit-animation-delay: 24528ms;
    animation-delay: 24528ms;
  }

  @-webkit-keyframes an-move-frames-50 {
    from {
      -webkit-transform: translate3d(88vw, 104vh, 0);
      transform: translate3d(88vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(7vw, -131vh, 0);
      transform: translate3d(7vw, -131vh, 0);
    }
  }

  @keyframes an-move-frames-50 {
    from {
      -webkit-transform: translate3d(88vw, 104vh, 0);
      transform: translate3d(88vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(7vw, -131vh, 0);
      transform: translate3d(7vw, -131vh, 0);
    }
  }

  .fireflies_item:nth-child(50) .fireflies_inner {
    -webkit-animation-delay: 3918ms;
    animation-delay: 3918ms;
  }

  .fireflies_item:nth-child(51) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-51;
    animation-name: an-move-frames-51;
    -webkit-animation-duration: 33054ms;
    animation-duration: 33054ms;
    -webkit-animation-delay: 1130ms;
    animation-delay: 1130ms;
  }

  @-webkit-keyframes an-move-frames-51 {
    from {
      -webkit-transform: translate3d(84vw, 107vh, 0);
      transform: translate3d(84vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(56vw, -115vh, 0);
      transform: translate3d(56vw, -115vh, 0);
    }
  }

  @keyframes an-move-frames-51 {
    from {
      -webkit-transform: translate3d(84vw, 107vh, 0);
      transform: translate3d(84vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(56vw, -115vh, 0);
      transform: translate3d(56vw, -115vh, 0);
    }
  }

  .fireflies_item:nth-child(51) .fireflies_inner {
    -webkit-animation-delay: 2898ms;
    animation-delay: 2898ms;
  }

  .fireflies_item:nth-child(52) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-52;
    animation-name: an-move-frames-52;
    -webkit-animation-duration: 36202ms;
    animation-duration: 36202ms;
    -webkit-animation-delay: 7465ms;
    animation-delay: 7465ms;
  }

  @-webkit-keyframes an-move-frames-52 {
    from {
      -webkit-transform: translate3d(38vw, 101vh, 0);
      transform: translate3d(38vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(14vw, -103vh, 0);
      transform: translate3d(14vw, -103vh, 0);
    }
  }

  @keyframes an-move-frames-52 {
    from {
      -webkit-transform: translate3d(38vw, 101vh, 0);
      transform: translate3d(38vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(14vw, -103vh, 0);
      transform: translate3d(14vw, -103vh, 0);
    }
  }

  .fireflies_item:nth-child(52) .fireflies_inner {
    -webkit-animation-delay: 1198ms;
    animation-delay: 1198ms;
  }

  .fireflies_item:nth-child(53) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-53;
    animation-name: an-move-frames-53;
    -webkit-animation-duration: 29425ms;
    animation-duration: 29425ms;
    -webkit-animation-delay: 26350ms;
    animation-delay: 26350ms;
  }

  @-webkit-keyframes an-move-frames-53 {
    from {
      -webkit-transform: translate3d(86vw, 104vh, 0);
      transform: translate3d(86vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(46vw, -133vh, 0);
      transform: translate3d(46vw, -133vh, 0);
    }
  }

  @keyframes an-move-frames-53 {
    from {
      -webkit-transform: translate3d(86vw, 104vh, 0);
      transform: translate3d(86vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(46vw, -133vh, 0);
      transform: translate3d(46vw, -133vh, 0);
    }
  }

  .fireflies_item:nth-child(53) .fireflies_inner {
    -webkit-animation-delay: 327ms;
    animation-delay: 327ms;
  }

  .fireflies_item:nth-child(54) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-54;
    animation-name: an-move-frames-54;
    -webkit-animation-duration: 30917ms;
    animation-duration: 30917ms;
    -webkit-animation-delay: 19942ms;
    animation-delay: 19942ms;
  }

  @-webkit-keyframes an-move-frames-54 {
    from {
      -webkit-transform: translate3d(90vw, 104vh, 0);
      transform: translate3d(90vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(26vw, -107vh, 0);
      transform: translate3d(26vw, -107vh, 0);
    }
  }

  @keyframes an-move-frames-54 {
    from {
      -webkit-transform: translate3d(90vw, 104vh, 0);
      transform: translate3d(90vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(26vw, -107vh, 0);
      transform: translate3d(26vw, -107vh, 0);
    }
  }

  .fireflies_item:nth-child(54) .fireflies_inner {
    -webkit-animation-delay: 3924ms;
    animation-delay: 3924ms;
  }

  .fireflies_item:nth-child(55) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-55;
    animation-name: an-move-frames-55;
    -webkit-animation-duration: 29613ms;
    animation-duration: 29613ms;
    -webkit-animation-delay: 18518ms;
    animation-delay: 18518ms;
  }

  @-webkit-keyframes an-move-frames-55 {
    from {
      -webkit-transform: translate3d(54vw, 104vh, 0);
      transform: translate3d(54vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(58vw, -125vh, 0);
      transform: translate3d(58vw, -125vh, 0);
    }
  }

  @keyframes an-move-frames-55 {
    from {
      -webkit-transform: translate3d(54vw, 104vh, 0);
      transform: translate3d(54vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(58vw, -125vh, 0);
      transform: translate3d(58vw, -125vh, 0);
    }
  }

  .fireflies_item:nth-child(55) .fireflies_inner {
    -webkit-animation-delay: 3373ms;
    animation-delay: 3373ms;
  }

  .fireflies_item:nth-child(56) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-56;
    animation-name: an-move-frames-56;
    -webkit-animation-duration: 33373ms;
    animation-duration: 33373ms;
    -webkit-animation-delay: 3238ms;
    animation-delay: 3238ms;
  }

  @-webkit-keyframes an-move-frames-56 {
    from {
      -webkit-transform: translate3d(92vw, 106vh, 0);
      transform: translate3d(92vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(66vw, -126vh, 0);
      transform: translate3d(66vw, -126vh, 0);
    }
  }

  @keyframes an-move-frames-56 {
    from {
      -webkit-transform: translate3d(92vw, 106vh, 0);
      transform: translate3d(92vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(66vw, -126vh, 0);
      transform: translate3d(66vw, -126vh, 0);
    }
  }

  .fireflies_item:nth-child(56) .fireflies_inner {
    -webkit-animation-delay: 2166ms;
    animation-delay: 2166ms;
  }

  .fireflies_item:nth-child(57) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-57;
    animation-name: an-move-frames-57;
    -webkit-animation-duration: 31530ms;
    animation-duration: 31530ms;
    -webkit-animation-delay: 1260ms;
    animation-delay: 1260ms;
  }

  @-webkit-keyframes an-move-frames-57 {
    from {
      -webkit-transform: translate3d(91vw, 105vh, 0);
      transform: translate3d(91vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(53vw, -111vh, 0);
      transform: translate3d(53vw, -111vh, 0);
    }
  }

  @keyframes an-move-frames-57 {
    from {
      -webkit-transform: translate3d(91vw, 105vh, 0);
      transform: translate3d(91vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(53vw, -111vh, 0);
      transform: translate3d(53vw, -111vh, 0);
    }
  }

  .fireflies_item:nth-child(57) .fireflies_inner {
    -webkit-animation-delay: 2464ms;
    animation-delay: 2464ms;
  }

  .fireflies_item:nth-child(58) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-58;
    animation-name: an-move-frames-58;
    -webkit-animation-duration: 31936ms;
    animation-duration: 31936ms;
    -webkit-animation-delay: 34252ms;
    animation-delay: 34252ms;
  }

  @-webkit-keyframes an-move-frames-58 {
    from {
      -webkit-transform: translate3d(81vw, 109vh, 0);
      transform: translate3d(81vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(93vw, -111vh, 0);
      transform: translate3d(93vw, -111vh, 0);
    }
  }

  @keyframes an-move-frames-58 {
    from {
      -webkit-transform: translate3d(81vw, 109vh, 0);
      transform: translate3d(81vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(93vw, -111vh, 0);
      transform: translate3d(93vw, -111vh, 0);
    }
  }

  .fireflies_item:nth-child(58) .fireflies_inner {
    -webkit-animation-delay: 2375ms;
    animation-delay: 2375ms;
  }

  .fireflies_item:nth-child(59) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-59;
    animation-name: an-move-frames-59;
    -webkit-animation-duration: 33079ms;
    animation-duration: 33079ms;
    -webkit-animation-delay: 5641ms;
    animation-delay: 5641ms;
  }

  @-webkit-keyframes an-move-frames-59 {
    from {
      -webkit-transform: translate3d(46vw, 105vh, 0);
      transform: translate3d(46vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(28vw, -108vh, 0);
      transform: translate3d(28vw, -108vh, 0);
    }
  }

  @keyframes an-move-frames-59 {
    from {
      -webkit-transform: translate3d(46vw, 105vh, 0);
      transform: translate3d(46vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(28vw, -108vh, 0);
      transform: translate3d(28vw, -108vh, 0);
    }
  }

  .fireflies_item:nth-child(59) .fireflies_inner {
    -webkit-animation-delay: 3407ms;
    animation-delay: 3407ms;
  }

  .fireflies_item:nth-child(60) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-60;
    animation-name: an-move-frames-60;
    -webkit-animation-duration: 32875ms;
    animation-duration: 32875ms;
    -webkit-animation-delay: 4427ms;
    animation-delay: 4427ms;
  }

  @-webkit-keyframes an-move-frames-60 {
    from {
      -webkit-transform: translate3d(84vw, 103vh, 0);
      transform: translate3d(84vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(66vw, -120vh, 0);
      transform: translate3d(66vw, -120vh, 0);
    }
  }

  @keyframes an-move-frames-60 {
    from {
      -webkit-transform: translate3d(84vw, 103vh, 0);
      transform: translate3d(84vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(66vw, -120vh, 0);
      transform: translate3d(66vw, -120vh, 0);
    }
  }

  .fireflies_item:nth-child(60) .fireflies_inner {
    -webkit-animation-delay: 861ms;
    animation-delay: 861ms;
  }

  .fireflies_item:nth-child(61) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-61;
    animation-name: an-move-frames-61;
    -webkit-animation-duration: 28130ms;
    animation-duration: 28130ms;
    -webkit-animation-delay: 20625ms;
    animation-delay: 20625ms;
  }

  @-webkit-keyframes an-move-frames-61 {
    from {
      -webkit-transform: translate3d(57vw, 101vh, 0);
      transform: translate3d(57vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(8vw, -122vh, 0);
      transform: translate3d(8vw, -122vh, 0);
    }
  }

  @keyframes an-move-frames-61 {
    from {
      -webkit-transform: translate3d(57vw, 101vh, 0);
      transform: translate3d(57vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(8vw, -122vh, 0);
      transform: translate3d(8vw, -122vh, 0);
    }
  }

  .fireflies_item:nth-child(61) .fireflies_inner {
    -webkit-animation-delay: 2447ms;
    animation-delay: 2447ms;
  }

  .fireflies_item:nth-child(62) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-62;
    animation-name: an-move-frames-62;
    -webkit-animation-duration: 28369ms;
    animation-duration: 28369ms;
    -webkit-animation-delay: 29700ms;
    animation-delay: 29700ms;
  }

  @-webkit-keyframes an-move-frames-62 {
    from {
      -webkit-transform: translate3d(33vw, 110vh, 0);
      transform: translate3d(33vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(33vw, -131vh, 0);
      transform: translate3d(33vw, -131vh, 0);
    }
  }

  @keyframes an-move-frames-62 {
    from {
      -webkit-transform: translate3d(33vw, 110vh, 0);
      transform: translate3d(33vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(33vw, -131vh, 0);
      transform: translate3d(33vw, -131vh, 0);
    }
  }

  .fireflies_item:nth-child(62) .fireflies_inner {
    -webkit-animation-delay: 3060ms;
    animation-delay: 3060ms;
  }

  .fireflies_item:nth-child(63) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-63;
    animation-name: an-move-frames-63;
    -webkit-animation-duration: 34776ms;
    animation-duration: 34776ms;
    -webkit-animation-delay: 13965ms;
    animation-delay: 13965ms;
  }

  @-webkit-keyframes an-move-frames-63 {
    from {
      -webkit-transform: translate3d(81vw, 110vh, 0);
      transform: translate3d(81vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(87vw, -134vh, 0);
      transform: translate3d(87vw, -134vh, 0);
    }
  }

  @keyframes an-move-frames-63 {
    from {
      -webkit-transform: translate3d(81vw, 110vh, 0);
      transform: translate3d(81vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(87vw, -134vh, 0);
      transform: translate3d(87vw, -134vh, 0);
    }
  }

  .fireflies_item:nth-child(63) .fireflies_inner {
    -webkit-animation-delay: 1441ms;
    animation-delay: 1441ms;
  }

  .fireflies_item:nth-child(64) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-64;
    animation-name: an-move-frames-64;
    -webkit-animation-duration: 31871ms;
    animation-duration: 31871ms;
    -webkit-animation-delay: 18767ms;
    animation-delay: 18767ms;
  }

  @-webkit-keyframes an-move-frames-64 {
    from {
      -webkit-transform: translate3d(49vw, 102vh, 0);
      transform: translate3d(49vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(41vw, -115vh, 0);
      transform: translate3d(41vw, -115vh, 0);
    }
  }

  @keyframes an-move-frames-64 {
    from {
      -webkit-transform: translate3d(49vw, 102vh, 0);
      transform: translate3d(49vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(41vw, -115vh, 0);
      transform: translate3d(41vw, -115vh, 0);
    }
  }

  .fireflies_item:nth-child(64) .fireflies_inner {
    -webkit-animation-delay: 3129ms;
    animation-delay: 3129ms;
  }

  .fireflies_item:nth-child(65) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-65;
    animation-name: an-move-frames-65;
    -webkit-animation-duration: 28195ms;
    animation-duration: 28195ms;
    -webkit-animation-delay: 36813ms;
    animation-delay: 36813ms;
  }

  @-webkit-keyframes an-move-frames-65 {
    from {
      -webkit-transform: translate3d(76vw, 108vh, 0);
      transform: translate3d(76vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(44vw, -137vh, 0);
      transform: translate3d(44vw, -137vh, 0);
    }
  }

  @keyframes an-move-frames-65 {
    from {
      -webkit-transform: translate3d(76vw, 108vh, 0);
      transform: translate3d(76vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(44vw, -137vh, 0);
      transform: translate3d(44vw, -137vh, 0);
    }
  }

  .fireflies_item:nth-child(65) .fireflies_inner {
    -webkit-animation-delay: 2721ms;
    animation-delay: 2721ms;
  }

  .fireflies_item:nth-child(66) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-66;
    animation-name: an-move-frames-66;
    -webkit-animation-duration: 35970ms;
    animation-duration: 35970ms;
    -webkit-animation-delay: 21381ms;
    animation-delay: 21381ms;
  }

  @-webkit-keyframes an-move-frames-66 {
    from {
      -webkit-transform: translate3d(60vw, 107vh, 0);
      transform: translate3d(60vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(76vw, -136vh, 0);
      transform: translate3d(76vw, -136vh, 0);
    }
  }

  @keyframes an-move-frames-66 {
    from {
      -webkit-transform: translate3d(60vw, 107vh, 0);
      transform: translate3d(60vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(76vw, -136vh, 0);
      transform: translate3d(76vw, -136vh, 0);
    }
  }

  .fireflies_item:nth-child(66) .fireflies_inner {
    -webkit-animation-delay: 762ms;
    animation-delay: 762ms;
  }

  .fireflies_item:nth-child(67) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-67;
    animation-name: an-move-frames-67;
    -webkit-animation-duration: 28165ms;
    animation-duration: 28165ms;
    -webkit-animation-delay: 23482ms;
    animation-delay: 23482ms;
  }

  @-webkit-keyframes an-move-frames-67 {
    from {
      -webkit-transform: translate3d(73vw, 103vh, 0);
      transform: translate3d(73vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(46vw, -127vh, 0);
      transform: translate3d(46vw, -127vh, 0);
    }
  }

  @keyframes an-move-frames-67 {
    from {
      -webkit-transform: translate3d(73vw, 103vh, 0);
      transform: translate3d(73vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(46vw, -127vh, 0);
      transform: translate3d(46vw, -127vh, 0);
    }
  }

  .fireflies_item:nth-child(67) .fireflies_inner {
    -webkit-animation-delay: 2804ms;
    animation-delay: 2804ms;
  }

  .fireflies_item:nth-child(68) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-68;
    animation-name: an-move-frames-68;
    -webkit-animation-duration: 31121ms;
    animation-duration: 31121ms;
    -webkit-animation-delay: 25905ms;
    animation-delay: 25905ms;
  }

  @-webkit-keyframes an-move-frames-68 {
    from {
      -webkit-transform: translate3d(36vw, 107vh, 0);
      transform: translate3d(36vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(45vw, -109vh, 0);
      transform: translate3d(45vw, -109vh, 0);
    }
  }

  @keyframes an-move-frames-68 {
    from {
      -webkit-transform: translate3d(36vw, 107vh, 0);
      transform: translate3d(36vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(45vw, -109vh, 0);
      transform: translate3d(45vw, -109vh, 0);
    }
  }

  .fireflies_item:nth-child(68) .fireflies_inner {
    -webkit-animation-delay: 593ms;
    animation-delay: 593ms;
  }

  .fireflies_item:nth-child(69) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-69;
    animation-name: an-move-frames-69;
    -webkit-animation-duration: 30143ms;
    animation-duration: 30143ms;
    -webkit-animation-delay: 17821ms;
    animation-delay: 17821ms;
  }

  @-webkit-keyframes an-move-frames-69 {
    from {
      -webkit-transform: translate3d(8vw, 104vh, 0);
      transform: translate3d(8vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(1vw, -125vh, 0);
      transform: translate3d(1vw, -125vh, 0);
    }
  }

  @keyframes an-move-frames-69 {
    from {
      -webkit-transform: translate3d(8vw, 104vh, 0);
      transform: translate3d(8vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(1vw, -125vh, 0);
      transform: translate3d(1vw, -125vh, 0);
    }
  }

  .fireflies_item:nth-child(69) .fireflies_inner {
    -webkit-animation-delay: 2191ms;
    animation-delay: 2191ms;
  }

  .fireflies_item:nth-child(70) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-70;
    animation-name: an-move-frames-70;
    -webkit-animation-duration: 31289ms;
    animation-duration: 31289ms;
    -webkit-animation-delay: 8806ms;
    animation-delay: 8806ms;
  }

  @-webkit-keyframes an-move-frames-70 {
    from {
      -webkit-transform: translate3d(23vw, 108vh, 0);
      transform: translate3d(23vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(68vw, -115vh, 0);
      transform: translate3d(68vw, -115vh, 0);
    }
  }

  @keyframes an-move-frames-70 {
    from {
      -webkit-transform: translate3d(23vw, 108vh, 0);
      transform: translate3d(23vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(68vw, -115vh, 0);
      transform: translate3d(68vw, -115vh, 0);
    }
  }

  .fireflies_item:nth-child(70) .fireflies_inner {
    -webkit-animation-delay: 3666ms;
    animation-delay: 3666ms;
  }

  .fireflies_item:nth-child(71) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-71;
    animation-name: an-move-frames-71;
    -webkit-animation-duration: 31028ms;
    animation-duration: 31028ms;
    -webkit-animation-delay: 1885ms;
    animation-delay: 1885ms;
  }

  @-webkit-keyframes an-move-frames-71 {
    from {
      -webkit-transform: translate3d(70vw, 107vh, 0);
      transform: translate3d(70vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(97vw, -122vh, 0);
      transform: translate3d(97vw, -122vh, 0);
    }
  }

  @keyframes an-move-frames-71 {
    from {
      -webkit-transform: translate3d(70vw, 107vh, 0);
      transform: translate3d(70vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(97vw, -122vh, 0);
      transform: translate3d(97vw, -122vh, 0);
    }
  }

  .fireflies_item:nth-child(71) .fireflies_inner {
    -webkit-animation-delay: 493ms;
    animation-delay: 493ms;
  }

  .fireflies_item:nth-child(72) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-72;
    animation-name: an-move-frames-72;
    -webkit-animation-duration: 35313ms;
    animation-duration: 35313ms;
    -webkit-animation-delay: 28041ms;
    animation-delay: 28041ms;
  }

  @-webkit-keyframes an-move-frames-72 {
    from {
      -webkit-transform: translate3d(3vw, 101vh, 0);
      transform: translate3d(3vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(25vw, -112vh, 0);
      transform: translate3d(25vw, -112vh, 0);
    }
  }

  @keyframes an-move-frames-72 {
    from {
      -webkit-transform: translate3d(3vw, 101vh, 0);
      transform: translate3d(3vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(25vw, -112vh, 0);
      transform: translate3d(25vw, -112vh, 0);
    }
  }

  .fireflies_item:nth-child(72) .fireflies_inner {
    -webkit-animation-delay: 1829ms;
    animation-delay: 1829ms;
  }

  .fireflies_item:nth-child(73) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-73;
    animation-name: an-move-frames-73;
    -webkit-animation-duration: 35959ms;
    animation-duration: 35959ms;
    -webkit-animation-delay: 8041ms;
    animation-delay: 8041ms;
  }

  @-webkit-keyframes an-move-frames-73 {
    from {
      -webkit-transform: translate3d(9vw, 102vh, 0);
      transform: translate3d(9vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(24vw, -113vh, 0);
      transform: translate3d(24vw, -113vh, 0);
    }
  }

  @keyframes an-move-frames-73 {
    from {
      -webkit-transform: translate3d(9vw, 102vh, 0);
      transform: translate3d(9vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(24vw, -113vh, 0);
      transform: translate3d(24vw, -113vh, 0);
    }
  }

  .fireflies_item:nth-child(73) .fireflies_inner {
    -webkit-animation-delay: 2013ms;
    animation-delay: 2013ms;
  }

  .fireflies_item:nth-child(74) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-74;
    animation-name: an-move-frames-74;
    -webkit-animation-duration: 33687ms;
    animation-duration: 33687ms;
    -webkit-animation-delay: 32322ms;
    animation-delay: 32322ms;
  }

  @-webkit-keyframes an-move-frames-74 {
    from {
      -webkit-transform: translate3d(25vw, 108vh, 0);
      transform: translate3d(25vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(88vw, -136vh, 0);
      transform: translate3d(88vw, -136vh, 0);
    }
  }

  @keyframes an-move-frames-74 {
    from {
      -webkit-transform: translate3d(25vw, 108vh, 0);
      transform: translate3d(25vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(88vw, -136vh, 0);
      transform: translate3d(88vw, -136vh, 0);
    }
  }

  .fireflies_item:nth-child(74) .fireflies_inner {
    -webkit-animation-delay: 2122ms;
    animation-delay: 2122ms;
  }

  .fireflies_item:nth-child(75) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-75;
    animation-name: an-move-frames-75;
    -webkit-animation-duration: 35746ms;
    animation-duration: 35746ms;
    -webkit-animation-delay: 20489ms;
    animation-delay: 20489ms;
  }

  @-webkit-keyframes an-move-frames-75 {
    from {
      -webkit-transform: translate3d(26vw, 105vh, 0);
      transform: translate3d(26vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(92vw, -113vh, 0);
      transform: translate3d(92vw, -113vh, 0);
    }
  }

  @keyframes an-move-frames-75 {
    from {
      -webkit-transform: translate3d(26vw, 105vh, 0);
      transform: translate3d(26vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(92vw, -113vh, 0);
      transform: translate3d(92vw, -113vh, 0);
    }
  }

  .fireflies_item:nth-child(75) .fireflies_inner {
    -webkit-animation-delay: 3515ms;
    animation-delay: 3515ms;
  }

  .fireflies_item:nth-child(76) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-76;
    animation-name: an-move-frames-76;
    -webkit-animation-duration: 33832ms;
    animation-duration: 33832ms;
    -webkit-animation-delay: 26304ms;
    animation-delay: 26304ms;
  }

  @-webkit-keyframes an-move-frames-76 {
    from {
      -webkit-transform: translate3d(25vw, 110vh, 0);
      transform: translate3d(25vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -132vh, 0);
      transform: translate3d(63vw, -132vh, 0);
    }
  }

  @keyframes an-move-frames-76 {
    from {
      -webkit-transform: translate3d(25vw, 110vh, 0);
      transform: translate3d(25vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -132vh, 0);
      transform: translate3d(63vw, -132vh, 0);
    }
  }

  .fireflies_item:nth-child(76) .fireflies_inner {
    -webkit-animation-delay: 255ms;
    animation-delay: 255ms;
  }

  .fireflies_item:nth-child(77) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-77;
    animation-name: an-move-frames-77;
    -webkit-animation-duration: 32250ms;
    animation-duration: 32250ms;
    -webkit-animation-delay: 18969ms;
    animation-delay: 18969ms;
  }

  @-webkit-keyframes an-move-frames-77 {
    from {
      -webkit-transform: translate3d(11vw, 107vh, 0);
      transform: translate3d(11vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(33vw, -111vh, 0);
      transform: translate3d(33vw, -111vh, 0);
    }
  }

  @keyframes an-move-frames-77 {
    from {
      -webkit-transform: translate3d(11vw, 107vh, 0);
      transform: translate3d(11vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(33vw, -111vh, 0);
      transform: translate3d(33vw, -111vh, 0);
    }
  }

  .fireflies_item:nth-child(77) .fireflies_inner {
    -webkit-animation-delay: 1600ms;
    animation-delay: 1600ms;
  }

  .fireflies_item:nth-child(78) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-78;
    animation-name: an-move-frames-78;
    -webkit-animation-duration: 32038ms;
    animation-duration: 32038ms;
    -webkit-animation-delay: 9109ms;
    animation-delay: 9109ms;
  }

  @-webkit-keyframes an-move-frames-78 {
    from {
      -webkit-transform: translate3d(60vw, 105vh, 0);
      transform: translate3d(60vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(83vw, -107vh, 0);
      transform: translate3d(83vw, -107vh, 0);
    }
  }

  @keyframes an-move-frames-78 {
    from {
      -webkit-transform: translate3d(60vw, 105vh, 0);
      transform: translate3d(60vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(83vw, -107vh, 0);
      transform: translate3d(83vw, -107vh, 0);
    }
  }

  .fireflies_item:nth-child(78) .fireflies_inner {
    -webkit-animation-delay: 3557ms;
    animation-delay: 3557ms;
  }

  .fireflies_item:nth-child(79) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-79;
    animation-name: an-move-frames-79;
    -webkit-animation-duration: 35962ms;
    animation-duration: 35962ms;
    -webkit-animation-delay: 14119ms;
    animation-delay: 14119ms;
  }

  @-webkit-keyframes an-move-frames-79 {
    from {
      -webkit-transform: translate3d(81vw, 103vh, 0);
      transform: translate3d(81vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(18vw, -107vh, 0);
      transform: translate3d(18vw, -107vh, 0);
    }
  }

  @keyframes an-move-frames-79 {
    from {
      -webkit-transform: translate3d(81vw, 103vh, 0);
      transform: translate3d(81vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(18vw, -107vh, 0);
      transform: translate3d(18vw, -107vh, 0);
    }
  }

  .fireflies_item:nth-child(79) .fireflies_inner {
    -webkit-animation-delay: 1469ms;
    animation-delay: 1469ms;
  }

  .fireflies_item:nth-child(80) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-80;
    animation-name: an-move-frames-80;
    -webkit-animation-duration: 34908ms;
    animation-duration: 34908ms;
    -webkit-animation-delay: 29337ms;
    animation-delay: 29337ms;
  }

  @-webkit-keyframes an-move-frames-80 {
    from {
      -webkit-transform: translate3d(54vw, 101vh, 0);
      transform: translate3d(54vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(87vw, -129vh, 0);
      transform: translate3d(87vw, -129vh, 0);
    }
  }

  @keyframes an-move-frames-80 {
    from {
      -webkit-transform: translate3d(54vw, 101vh, 0);
      transform: translate3d(54vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(87vw, -129vh, 0);
      transform: translate3d(87vw, -129vh, 0);
    }
  }

  .fireflies_item:nth-child(80) .fireflies_inner {
    -webkit-animation-delay: 3000ms;
    animation-delay: 3000ms;
  }

  .fireflies_item:nth-child(81) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-81;
    animation-name: an-move-frames-81;
    -webkit-animation-duration: 31398ms;
    animation-duration: 31398ms;
    -webkit-animation-delay: 13334ms;
    animation-delay: 13334ms;
  }

  @-webkit-keyframes an-move-frames-81 {
    from {
      -webkit-transform: translate3d(56vw, 108vh, 0);
      transform: translate3d(56vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(100vw, -111vh, 0);
      transform: translate3d(100vw, -111vh, 0);
    }
  }

  @keyframes an-move-frames-81 {
    from {
      -webkit-transform: translate3d(56vw, 108vh, 0);
      transform: translate3d(56vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(100vw, -111vh, 0);
      transform: translate3d(100vw, -111vh, 0);
    }
  }

  .fireflies_item:nth-child(81) .fireflies_inner {
    -webkit-animation-delay: 587ms;
    animation-delay: 587ms;
  }

  .fireflies_item:nth-child(82) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-82;
    animation-name: an-move-frames-82;
    -webkit-animation-duration: 29055ms;
    animation-duration: 29055ms;
    -webkit-animation-delay: 9871ms;
    animation-delay: 9871ms;
  }

  @-webkit-keyframes an-move-frames-82 {
    from {
      -webkit-transform: translate3d(22vw, 105vh, 0);
      transform: translate3d(22vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(91vw, -120vh, 0);
      transform: translate3d(91vw, -120vh, 0);
    }
  }

  @keyframes an-move-frames-82 {
    from {
      -webkit-transform: translate3d(22vw, 105vh, 0);
      transform: translate3d(22vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(91vw, -120vh, 0);
      transform: translate3d(91vw, -120vh, 0);
    }
  }

  .fireflies_item:nth-child(82) .fireflies_inner {
    -webkit-animation-delay: 1706ms;
    animation-delay: 1706ms;
  }

  .fireflies_item:nth-child(83) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-83;
    animation-name: an-move-frames-83;
    -webkit-animation-duration: 30056ms;
    animation-duration: 30056ms;
    -webkit-animation-delay: 10173ms;
    animation-delay: 10173ms;
  }

  @-webkit-keyframes an-move-frames-83 {
    from {
      -webkit-transform: translate3d(65vw, 110vh, 0);
      transform: translate3d(65vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(93vw, -116vh, 0);
      transform: translate3d(93vw, -116vh, 0);
    }
  }

  @keyframes an-move-frames-83 {
    from {
      -webkit-transform: translate3d(65vw, 110vh, 0);
      transform: translate3d(65vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(93vw, -116vh, 0);
      transform: translate3d(93vw, -116vh, 0);
    }
  }

  .fireflies_item:nth-child(83) .fireflies_inner {
    -webkit-animation-delay: 1589ms;
    animation-delay: 1589ms;
  }

  .fireflies_item:nth-child(84) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-84;
    animation-name: an-move-frames-84;
    -webkit-animation-duration: 32572ms;
    animation-duration: 32572ms;
    -webkit-animation-delay: 14343ms;
    animation-delay: 14343ms;
  }

  @-webkit-keyframes an-move-frames-84 {
    from {
      -webkit-transform: translate3d(27vw, 104vh, 0);
      transform: translate3d(27vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(12vw, -132vh, 0);
      transform: translate3d(12vw, -132vh, 0);
    }
  }

  @keyframes an-move-frames-84 {
    from {
      -webkit-transform: translate3d(27vw, 104vh, 0);
      transform: translate3d(27vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(12vw, -132vh, 0);
      transform: translate3d(12vw, -132vh, 0);
    }
  }

  .fireflies_item:nth-child(84) .fireflies_inner {
    -webkit-animation-delay: 3589ms;
    animation-delay: 3589ms;
  }

  .fireflies_item:nth-child(85) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-85;
    animation-name: an-move-frames-85;
    -webkit-animation-duration: 31722ms;
    animation-duration: 31722ms;
    -webkit-animation-delay: 7497ms;
    animation-delay: 7497ms;
  }

  @-webkit-keyframes an-move-frames-85 {
    from {
      -webkit-transform: translate3d(85vw, 110vh, 0);
      transform: translate3d(85vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(30vw, -134vh, 0);
      transform: translate3d(30vw, -134vh, 0);
    }
  }

  @keyframes an-move-frames-85 {
    from {
      -webkit-transform: translate3d(85vw, 110vh, 0);
      transform: translate3d(85vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(30vw, -134vh, 0);
      transform: translate3d(30vw, -134vh, 0);
    }
  }

  .fireflies_item:nth-child(85) .fireflies_inner {
    -webkit-animation-delay: 3781ms;
    animation-delay: 3781ms;
  }

  .fireflies_item:nth-child(86) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-86;
    animation-name: an-move-frames-86;
    -webkit-animation-duration: 33659ms;
    animation-duration: 33659ms;
    -webkit-animation-delay: 4972ms;
    animation-delay: 4972ms;
  }

  @-webkit-keyframes an-move-frames-86 {
    from {
      -webkit-transform: translate3d(30vw, 105vh, 0);
      transform: translate3d(30vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(36vw, -111vh, 0);
      transform: translate3d(36vw, -111vh, 0);
    }
  }

  @keyframes an-move-frames-86 {
    from {
      -webkit-transform: translate3d(30vw, 105vh, 0);
      transform: translate3d(30vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(36vw, -111vh, 0);
      transform: translate3d(36vw, -111vh, 0);
    }
  }

  .fireflies_item:nth-child(86) .fireflies_inner {
    -webkit-animation-delay: 3455ms;
    animation-delay: 3455ms;
  }

  .fireflies_item:nth-child(87) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-87;
    animation-name: an-move-frames-87;
    -webkit-animation-duration: 28065ms;
    animation-duration: 28065ms;
    -webkit-animation-delay: 14640ms;
    animation-delay: 14640ms;
  }

  @-webkit-keyframes an-move-frames-87 {
    from {
      -webkit-transform: translate3d(79vw, 108vh, 0);
      transform: translate3d(79vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -116vh, 0);
      transform: translate3d(63vw, -116vh, 0);
    }
  }

  @keyframes an-move-frames-87 {
    from {
      -webkit-transform: translate3d(79vw, 108vh, 0);
      transform: translate3d(79vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -116vh, 0);
      transform: translate3d(63vw, -116vh, 0);
    }
  }

  .fireflies_item:nth-child(87) .fireflies_inner {
    -webkit-animation-delay: 738ms;
    animation-delay: 738ms;
  }

  .fireflies_item:nth-child(88) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-88;
    animation-name: an-move-frames-88;
    -webkit-animation-duration: 36799ms;
    animation-duration: 36799ms;
    -webkit-animation-delay: 19330ms;
    animation-delay: 19330ms;
  }

  @-webkit-keyframes an-move-frames-88 {
    from {
      -webkit-transform: translate3d(68vw, 106vh, 0);
      transform: translate3d(68vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(99vw, -136vh, 0);
      transform: translate3d(99vw, -136vh, 0);
    }
  }

  @keyframes an-move-frames-88 {
    from {
      -webkit-transform: translate3d(68vw, 106vh, 0);
      transform: translate3d(68vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(99vw, -136vh, 0);
      transform: translate3d(99vw, -136vh, 0);
    }
  }

  .fireflies_item:nth-child(88) .fireflies_inner {
    -webkit-animation-delay: 3527ms;
    animation-delay: 3527ms;
  }

  .fireflies_item:nth-child(89) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-89;
    animation-name: an-move-frames-89;
    -webkit-animation-duration: 29921ms;
    animation-duration: 29921ms;
    -webkit-animation-delay: 21749ms;
    animation-delay: 21749ms;
  }

  @-webkit-keyframes an-move-frames-89 {
    from {
      -webkit-transform: translate3d(28vw, 102vh, 0);
      transform: translate3d(28vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(31vw, -130vh, 0);
      transform: translate3d(31vw, -130vh, 0);
    }
  }

  @keyframes an-move-frames-89 {
    from {
      -webkit-transform: translate3d(28vw, 102vh, 0);
      transform: translate3d(28vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(31vw, -130vh, 0);
      transform: translate3d(31vw, -130vh, 0);
    }
  }

  .fireflies_item:nth-child(89) .fireflies_inner {
    -webkit-animation-delay: 3623ms;
    animation-delay: 3623ms;
  }

  .fireflies_item:nth-child(90) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-90;
    animation-name: an-move-frames-90;
    -webkit-animation-duration: 35197ms;
    animation-duration: 35197ms;
    -webkit-animation-delay: 24491ms;
    animation-delay: 24491ms;
  }

  @-webkit-keyframes an-move-frames-90 {
    from {
      -webkit-transform: translate3d(10vw, 108vh, 0);
      transform: translate3d(10vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(59vw, -116vh, 0);
      transform: translate3d(59vw, -116vh, 0);
    }
  }

  @keyframes an-move-frames-90 {
    from {
      -webkit-transform: translate3d(10vw, 108vh, 0);
      transform: translate3d(10vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(59vw, -116vh, 0);
      transform: translate3d(59vw, -116vh, 0);
    }
  }

  .fireflies_item:nth-child(90) .fireflies_inner {
    -webkit-animation-delay: 2888ms;
    animation-delay: 2888ms;
  }

  .fireflies_item:nth-child(91) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-91;
    animation-name: an-move-frames-91;
    -webkit-animation-duration: 28719ms;
    animation-duration: 28719ms;
    -webkit-animation-delay: 27898ms;
    animation-delay: 27898ms;
  }

  @-webkit-keyframes an-move-frames-91 {
    from {
      -webkit-transform: translate3d(73vw, 102vh, 0);
      transform: translate3d(73vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(5vw, -121vh, 0);
      transform: translate3d(5vw, -121vh, 0);
    }
  }

  @keyframes an-move-frames-91 {
    from {
      -webkit-transform: translate3d(73vw, 102vh, 0);
      transform: translate3d(73vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(5vw, -121vh, 0);
      transform: translate3d(5vw, -121vh, 0);
    }
  }

  .fireflies_item:nth-child(91) .fireflies_inner {
    -webkit-animation-delay: 207ms;
    animation-delay: 207ms;
  }

  .fireflies_item:nth-child(92) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-92;
    animation-name: an-move-frames-92;
    -webkit-animation-duration: 30745ms;
    animation-duration: 30745ms;
    -webkit-animation-delay: 25829ms;
    animation-delay: 25829ms;
  }

  @-webkit-keyframes an-move-frames-92 {
    from {
      -webkit-transform: translate3d(98vw, 104vh, 0);
      transform: translate3d(98vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(23vw, -118vh, 0);
      transform: translate3d(23vw, -118vh, 0);
    }
  }

  @keyframes an-move-frames-92 {
    from {
      -webkit-transform: translate3d(98vw, 104vh, 0);
      transform: translate3d(98vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(23vw, -118vh, 0);
      transform: translate3d(23vw, -118vh, 0);
    }
  }

  .fireflies_item:nth-child(92) .fireflies_inner {
    -webkit-animation-delay: 1347ms;
    animation-delay: 1347ms;
  }

  .fireflies_item:nth-child(93) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-93;
    animation-name: an-move-frames-93;
    -webkit-animation-duration: 29903ms;
    animation-duration: 29903ms;
    -webkit-animation-delay: 369ms;
    animation-delay: 369ms;
  }

  @-webkit-keyframes an-move-frames-93 {
    from {
      -webkit-transform: translate3d(80vw, 109vh, 0);
      transform: translate3d(80vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(42vw, -132vh, 0);
      transform: translate3d(42vw, -132vh, 0);
    }
  }

  @keyframes an-move-frames-93 {
    from {
      -webkit-transform: translate3d(80vw, 109vh, 0);
      transform: translate3d(80vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(42vw, -132vh, 0);
      transform: translate3d(42vw, -132vh, 0);
    }
  }

  .fireflies_item:nth-child(93) .fireflies_inner {
    -webkit-animation-delay: 3297ms;
    animation-delay: 3297ms;
  }

  .fireflies_item:nth-child(94) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-94;
    animation-name: an-move-frames-94;
    -webkit-animation-duration: 34529ms;
    animation-duration: 34529ms;
    -webkit-animation-delay: 21898ms;
    animation-delay: 21898ms;
  }

  @-webkit-keyframes an-move-frames-94 {
    from {
      -webkit-transform: translate3d(44vw, 104vh, 0);
      transform: translate3d(44vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(57vw, -121vh, 0);
      transform: translate3d(57vw, -121vh, 0);
    }
  }

  @keyframes an-move-frames-94 {
    from {
      -webkit-transform: translate3d(44vw, 104vh, 0);
      transform: translate3d(44vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(57vw, -121vh, 0);
      transform: translate3d(57vw, -121vh, 0);
    }
  }

  .fireflies_item:nth-child(94) .fireflies_inner {
    -webkit-animation-delay: 3024ms;
    animation-delay: 3024ms;
  }

  .fireflies_item:nth-child(95) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-95;
    animation-name: an-move-frames-95;
    -webkit-animation-duration: 29849ms;
    animation-duration: 29849ms;
    -webkit-animation-delay: 14130ms;
    animation-delay: 14130ms;
  }

  @-webkit-keyframes an-move-frames-95 {
    from {
      -webkit-transform: translate3d(95vw, 108vh, 0);
      transform: translate3d(95vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(88vw, -117vh, 0);
      transform: translate3d(88vw, -117vh, 0);
    }
  }

  @keyframes an-move-frames-95 {
    from {
      -webkit-transform: translate3d(95vw, 108vh, 0);
      transform: translate3d(95vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(88vw, -117vh, 0);
      transform: translate3d(88vw, -117vh, 0);
    }
  }

  .fireflies_item:nth-child(95) .fireflies_inner {
    -webkit-animation-delay: 1335ms;
    animation-delay: 1335ms;
  }

  .fireflies_item:nth-child(96) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-96;
    animation-name: an-move-frames-96;
    -webkit-animation-duration: 30710ms;
    animation-duration: 30710ms;
    -webkit-animation-delay: 27970ms;
    animation-delay: 27970ms;
  }

  @-webkit-keyframes an-move-frames-96 {
    from {
      -webkit-transform: translate3d(40vw, 103vh, 0);
      transform: translate3d(40vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(4vw, -121vh, 0);
      transform: translate3d(4vw, -121vh, 0);
    }
  }

  @keyframes an-move-frames-96 {
    from {
      -webkit-transform: translate3d(40vw, 103vh, 0);
      transform: translate3d(40vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(4vw, -121vh, 0);
      transform: translate3d(4vw, -121vh, 0);
    }
  }

  .fireflies_item:nth-child(96) .fireflies_inner {
    -webkit-animation-delay: 2072ms;
    animation-delay: 2072ms;
  }

  .fireflies_item:nth-child(97) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-97;
    animation-name: an-move-frames-97;
    -webkit-animation-duration: 35749ms;
    animation-duration: 35749ms;
    -webkit-animation-delay: 32377ms;
    animation-delay: 32377ms;
  }

  @-webkit-keyframes an-move-frames-97 {
    from {
      -webkit-transform: translate3d(59vw, 110vh, 0);
      transform: translate3d(59vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(90vw, -133vh, 0);
      transform: translate3d(90vw, -133vh, 0);
    }
  }

  @keyframes an-move-frames-97 {
    from {
      -webkit-transform: translate3d(59vw, 110vh, 0);
      transform: translate3d(59vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(90vw, -133vh, 0);
      transform: translate3d(90vw, -133vh, 0);
    }
  }

  .fireflies_item:nth-child(97) .fireflies_inner {
    -webkit-animation-delay: 1906ms;
    animation-delay: 1906ms;
  }

  .fireflies_item:nth-child(98) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-98;
    animation-name: an-move-frames-98;
    -webkit-animation-duration: 34467ms;
    animation-duration: 34467ms;
    -webkit-animation-delay: 23881ms;
    animation-delay: 23881ms;
  }

  @-webkit-keyframes an-move-frames-98 {
    from {
      -webkit-transform: translate3d(96vw, 106vh, 0);
      transform: translate3d(96vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(40vw, -127vh, 0);
      transform: translate3d(40vw, -127vh, 0);
    }
  }

  @keyframes an-move-frames-98 {
    from {
      -webkit-transform: translate3d(96vw, 106vh, 0);
      transform: translate3d(96vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(40vw, -127vh, 0);
      transform: translate3d(40vw, -127vh, 0);
    }
  }

  .fireflies_item:nth-child(98) .fireflies_inner {
    -webkit-animation-delay: 476ms;
    animation-delay: 476ms;
  }

  .fireflies_item:nth-child(99) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-99;
    animation-name: an-move-frames-99;
    -webkit-animation-duration: 29632ms;
    animation-duration: 29632ms;
    -webkit-animation-delay: 2587ms;
    animation-delay: 2587ms;
  }

  @-webkit-keyframes an-move-frames-99 {
    from {
      -webkit-transform: translate3d(61vw, 101vh, 0);
      transform: translate3d(61vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(39vw, -114vh, 0);
      transform: translate3d(39vw, -114vh, 0);
    }
  }

  @keyframes an-move-frames-99 {
    from {
      -webkit-transform: translate3d(61vw, 101vh, 0);
      transform: translate3d(61vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(39vw, -114vh, 0);
      transform: translate3d(39vw, -114vh, 0);
    }
  }

  .fireflies_item:nth-child(99) .fireflies_inner {
    -webkit-animation-delay: 2175ms;
    animation-delay: 2175ms;
  }

  .fireflies_item:nth-child(100) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-100;
    animation-name: an-move-frames-100;
    -webkit-animation-duration: 29998ms;
    animation-duration: 29998ms;
    -webkit-animation-delay: 7534ms;
    animation-delay: 7534ms;
  }

  @-webkit-keyframes an-move-frames-100 {
    from {
      -webkit-transform: translate3d(30vw, 109vh, 0);
      transform: translate3d(30vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(42vw, -112vh, 0);
      transform: translate3d(42vw, -112vh, 0);
    }
  }

  @keyframes an-move-frames-100 {
    from {
      -webkit-transform: translate3d(30vw, 109vh, 0);
      transform: translate3d(30vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(42vw, -112vh, 0);
      transform: translate3d(42vw, -112vh, 0);
    }
  }

  .fireflies_item:nth-child(100) .fireflies_inner {
    -webkit-animation-delay: 3706ms;
    animation-delay: 3706ms;
  }

  .fireflies_item:nth-child(101) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-101;
    animation-name: an-move-frames-101;
    -webkit-animation-duration: 30654ms;
    animation-duration: 30654ms;
    -webkit-animation-delay: 2325ms;
    animation-delay: 2325ms;
  }

  @-webkit-keyframes an-move-frames-101 {
    from {
      -webkit-transform: translate3d(54vw, 107vh, 0);
      transform: translate3d(54vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(52vw, -108vh, 0);
      transform: translate3d(52vw, -108vh, 0);
    }
  }

  @keyframes an-move-frames-101 {
    from {
      -webkit-transform: translate3d(54vw, 107vh, 0);
      transform: translate3d(54vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(52vw, -108vh, 0);
      transform: translate3d(52vw, -108vh, 0);
    }
  }

  .fireflies_item:nth-child(101) .fireflies_inner {
    -webkit-animation-delay: 286ms;
    animation-delay: 286ms;
  }

  .fireflies_item:nth-child(102) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-102;
    animation-name: an-move-frames-102;
    -webkit-animation-duration: 34181ms;
    animation-duration: 34181ms;
    -webkit-animation-delay: 28569ms;
    animation-delay: 28569ms;
  }

  @-webkit-keyframes an-move-frames-102 {
    from {
      -webkit-transform: translate3d(82vw, 107vh, 0);
      transform: translate3d(82vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(22vw, -116vh, 0);
      transform: translate3d(22vw, -116vh, 0);
    }
  }

  @keyframes an-move-frames-102 {
    from {
      -webkit-transform: translate3d(82vw, 107vh, 0);
      transform: translate3d(82vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(22vw, -116vh, 0);
      transform: translate3d(22vw, -116vh, 0);
    }
  }

  .fireflies_item:nth-child(102) .fireflies_inner {
    -webkit-animation-delay: 2842ms;
    animation-delay: 2842ms;
  }

  .fireflies_item:nth-child(103) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-103;
    animation-name: an-move-frames-103;
    -webkit-animation-duration: 34372ms;
    animation-duration: 34372ms;
    -webkit-animation-delay: 3422ms;
    animation-delay: 3422ms;
  }

  @-webkit-keyframes an-move-frames-103 {
    from {
      -webkit-transform: translate3d(99vw, 102vh, 0);
      transform: translate3d(99vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(65vw, -124vh, 0);
      transform: translate3d(65vw, -124vh, 0);
    }
  }

  @keyframes an-move-frames-103 {
    from {
      -webkit-transform: translate3d(99vw, 102vh, 0);
      transform: translate3d(99vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(65vw, -124vh, 0);
      transform: translate3d(65vw, -124vh, 0);
    }
  }

  .fireflies_item:nth-child(103) .fireflies_inner {
    -webkit-animation-delay: 1679ms;
    animation-delay: 1679ms;
  }

  .fireflies_item:nth-child(104) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-104;
    animation-name: an-move-frames-104;
    -webkit-animation-duration: 28792ms;
    animation-duration: 28792ms;
    -webkit-animation-delay: 20095ms;
    animation-delay: 20095ms;
  }

  @-webkit-keyframes an-move-frames-104 {
    from {
      -webkit-transform: translate3d(63vw, 108vh, 0);
      transform: translate3d(63vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -112vh, 0);
      transform: translate3d(63vw, -112vh, 0);
    }
  }

  @keyframes an-move-frames-104 {
    from {
      -webkit-transform: translate3d(63vw, 108vh, 0);
      transform: translate3d(63vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -112vh, 0);
      transform: translate3d(63vw, -112vh, 0);
    }
  }

  .fireflies_item:nth-child(104) .fireflies_inner {
    -webkit-animation-delay: 442ms;
    animation-delay: 442ms;
  }

  .fireflies_item:nth-child(105) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-105;
    animation-name: an-move-frames-105;
    -webkit-animation-duration: 33051ms;
    animation-duration: 33051ms;
    -webkit-animation-delay: 29774ms;
    animation-delay: 29774ms;
  }

  @-webkit-keyframes an-move-frames-105 {
    from {
      -webkit-transform: translate3d(13vw, 110vh, 0);
      transform: translate3d(13vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(91vw, -133vh, 0);
      transform: translate3d(91vw, -133vh, 0);
    }
  }

  @keyframes an-move-frames-105 {
    from {
      -webkit-transform: translate3d(13vw, 110vh, 0);
      transform: translate3d(13vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(91vw, -133vh, 0);
      transform: translate3d(91vw, -133vh, 0);
    }
  }

  .fireflies_item:nth-child(105) .fireflies_inner {
    -webkit-animation-delay: 241ms;
    animation-delay: 241ms;
  }

  .fireflies_item:nth-child(106) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-106;
    animation-name: an-move-frames-106;
    -webkit-animation-duration: 29820ms;
    animation-duration: 29820ms;
    -webkit-animation-delay: 32395ms;
    animation-delay: 32395ms;
  }

  @-webkit-keyframes an-move-frames-106 {
    from {
      -webkit-transform: translate3d(72vw, 108vh, 0);
      transform: translate3d(72vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(62vw, -115vh, 0);
      transform: translate3d(62vw, -115vh, 0);
    }
  }

  @keyframes an-move-frames-106 {
    from {
      -webkit-transform: translate3d(72vw, 108vh, 0);
      transform: translate3d(72vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(62vw, -115vh, 0);
      transform: translate3d(62vw, -115vh, 0);
    }
  }

  .fireflies_item:nth-child(106) .fireflies_inner {
    -webkit-animation-delay: 1364ms;
    animation-delay: 1364ms;
  }

  .fireflies_item:nth-child(107) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-107;
    animation-name: an-move-frames-107;
    -webkit-animation-duration: 32215ms;
    animation-duration: 32215ms;
    -webkit-animation-delay: 12695ms;
    animation-delay: 12695ms;
  }

  @-webkit-keyframes an-move-frames-107 {
    from {
      -webkit-transform: translate3d(7vw, 101vh, 0);
      transform: translate3d(7vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(20vw, -128vh, 0);
      transform: translate3d(20vw, -128vh, 0);
    }
  }

  @keyframes an-move-frames-107 {
    from {
      -webkit-transform: translate3d(7vw, 101vh, 0);
      transform: translate3d(7vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(20vw, -128vh, 0);
      transform: translate3d(20vw, -128vh, 0);
    }
  }

  .fireflies_item:nth-child(107) .fireflies_inner {
    -webkit-animation-delay: 321ms;
    animation-delay: 321ms;
  }

  .fireflies_item:nth-child(108) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-108;
    animation-name: an-move-frames-108;
    -webkit-animation-duration: 34140ms;
    animation-duration: 34140ms;
    -webkit-animation-delay: 21291ms;
    animation-delay: 21291ms;
  }

  @-webkit-keyframes an-move-frames-108 {
    from {
      -webkit-transform: translate3d(33vw, 107vh, 0);
      transform: translate3d(33vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(34vw, -121vh, 0);
      transform: translate3d(34vw, -121vh, 0);
    }
  }

  @keyframes an-move-frames-108 {
    from {
      -webkit-transform: translate3d(33vw, 107vh, 0);
      transform: translate3d(33vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(34vw, -121vh, 0);
      transform: translate3d(34vw, -121vh, 0);
    }
  }

  .fireflies_item:nth-child(108) .fireflies_inner {
    -webkit-animation-delay: 1499ms;
    animation-delay: 1499ms;
  }

  .fireflies_item:nth-child(109) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-109;
    animation-name: an-move-frames-109;
    -webkit-animation-duration: 34973ms;
    animation-duration: 34973ms;
    -webkit-animation-delay: 10858ms;
    animation-delay: 10858ms;
  }

  @-webkit-keyframes an-move-frames-109 {
    from {
      -webkit-transform: translate3d(49vw, 106vh, 0);
      transform: translate3d(49vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(31vw, -119vh, 0);
      transform: translate3d(31vw, -119vh, 0);
    }
  }

  @keyframes an-move-frames-109 {
    from {
      -webkit-transform: translate3d(49vw, 106vh, 0);
      transform: translate3d(49vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(31vw, -119vh, 0);
      transform: translate3d(31vw, -119vh, 0);
    }
  }

  .fireflies_item:nth-child(109) .fireflies_inner {
    -webkit-animation-delay: 2754ms;
    animation-delay: 2754ms;
  }

  .fireflies_item:nth-child(110) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-110;
    animation-name: an-move-frames-110;
    -webkit-animation-duration: 34453ms;
    animation-duration: 34453ms;
    -webkit-animation-delay: 18523ms;
    animation-delay: 18523ms;
  }

  @-webkit-keyframes an-move-frames-110 {
    from {
      -webkit-transform: translate3d(25vw, 108vh, 0);
      transform: translate3d(25vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(16vw, -109vh, 0);
      transform: translate3d(16vw, -109vh, 0);
    }
  }

  @keyframes an-move-frames-110 {
    from {
      -webkit-transform: translate3d(25vw, 108vh, 0);
      transform: translate3d(25vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(16vw, -109vh, 0);
      transform: translate3d(16vw, -109vh, 0);
    }
  }

  .fireflies_item:nth-child(110) .fireflies_inner {
    -webkit-animation-delay: 2826ms;
    animation-delay: 2826ms;
  }

  .fireflies_item:nth-child(111) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-111;
    animation-name: an-move-frames-111;
    -webkit-animation-duration: 34343ms;
    animation-duration: 34343ms;
    -webkit-animation-delay: 12236ms;
    animation-delay: 12236ms;
  }

  @-webkit-keyframes an-move-frames-111 {
    from {
      -webkit-transform: translate3d(56vw, 110vh, 0);
      transform: translate3d(56vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(30vw, -115vh, 0);
      transform: translate3d(30vw, -115vh, 0);
    }
  }

  @keyframes an-move-frames-111 {
    from {
      -webkit-transform: translate3d(56vw, 110vh, 0);
      transform: translate3d(56vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(30vw, -115vh, 0);
      transform: translate3d(30vw, -115vh, 0);
    }
  }

  .fireflies_item:nth-child(111) .fireflies_inner {
    -webkit-animation-delay: 2391ms;
    animation-delay: 2391ms;
  }

  .fireflies_item:nth-child(112) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-112;
    animation-name: an-move-frames-112;
    -webkit-animation-duration: 34434ms;
    animation-duration: 34434ms;
    -webkit-animation-delay: 2637ms;
    animation-delay: 2637ms;
  }

  @-webkit-keyframes an-move-frames-112 {
    from {
      -webkit-transform: translate3d(20vw, 110vh, 0);
      transform: translate3d(20vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(46vw, -125vh, 0);
      transform: translate3d(46vw, -125vh, 0);
    }
  }

  @keyframes an-move-frames-112 {
    from {
      -webkit-transform: translate3d(20vw, 110vh, 0);
      transform: translate3d(20vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(46vw, -125vh, 0);
      transform: translate3d(46vw, -125vh, 0);
    }
  }

  .fireflies_item:nth-child(112) .fireflies_inner {
    -webkit-animation-delay: 449ms;
    animation-delay: 449ms;
  }

  .fireflies_item:nth-child(113) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-113;
    animation-name: an-move-frames-113;
    -webkit-animation-duration: 28657ms;
    animation-duration: 28657ms;
    -webkit-animation-delay: 7459ms;
    animation-delay: 7459ms;
  }

  @-webkit-keyframes an-move-frames-113 {
    from {
      -webkit-transform: translate3d(81vw, 106vh, 0);
      transform: translate3d(81vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(28vw, -115vh, 0);
      transform: translate3d(28vw, -115vh, 0);
    }
  }

  @keyframes an-move-frames-113 {
    from {
      -webkit-transform: translate3d(81vw, 106vh, 0);
      transform: translate3d(81vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(28vw, -115vh, 0);
      transform: translate3d(28vw, -115vh, 0);
    }
  }

  .fireflies_item:nth-child(113) .fireflies_inner {
    -webkit-animation-delay: 1296ms;
    animation-delay: 1296ms;
  }

  .fireflies_item:nth-child(114) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-114;
    animation-name: an-move-frames-114;
    -webkit-animation-duration: 32605ms;
    animation-duration: 32605ms;
    -webkit-animation-delay: 3384ms;
    animation-delay: 3384ms;
  }

  @-webkit-keyframes an-move-frames-114 {
    from {
      -webkit-transform: translate3d(14vw, 110vh, 0);
      transform: translate3d(14vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(12vw, -140vh, 0);
      transform: translate3d(12vw, -140vh, 0);
    }
  }

  @keyframes an-move-frames-114 {
    from {
      -webkit-transform: translate3d(14vw, 110vh, 0);
      transform: translate3d(14vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(12vw, -140vh, 0);
      transform: translate3d(12vw, -140vh, 0);
    }
  }

  .fireflies_item:nth-child(114) .fireflies_inner {
    -webkit-animation-delay: 1884ms;
    animation-delay: 1884ms;
  }

  .fireflies_item:nth-child(115) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-115;
    animation-name: an-move-frames-115;
    -webkit-animation-duration: 34221ms;
    animation-duration: 34221ms;
    -webkit-animation-delay: 13619ms;
    animation-delay: 13619ms;
  }

  @-webkit-keyframes an-move-frames-115 {
    from {
      -webkit-transform: translate3d(26vw, 104vh, 0);
      transform: translate3d(26vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(20vw, -121vh, 0);
      transform: translate3d(20vw, -121vh, 0);
    }
  }

  @keyframes an-move-frames-115 {
    from {
      -webkit-transform: translate3d(26vw, 104vh, 0);
      transform: translate3d(26vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(20vw, -121vh, 0);
      transform: translate3d(20vw, -121vh, 0);
    }
  }

  .fireflies_item:nth-child(115) .fireflies_inner {
    -webkit-animation-delay: 119ms;
    animation-delay: 119ms;
  }

  .fireflies_item:nth-child(116) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-116;
    animation-name: an-move-frames-116;
    -webkit-animation-duration: 30810ms;
    animation-duration: 30810ms;
    -webkit-animation-delay: 30142ms;
    animation-delay: 30142ms;
  }

  @-webkit-keyframes an-move-frames-116 {
    from {
      -webkit-transform: translate3d(39vw, 109vh, 0);
      transform: translate3d(39vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(94vw, -129vh, 0);
      transform: translate3d(94vw, -129vh, 0);
    }
  }

  @keyframes an-move-frames-116 {
    from {
      -webkit-transform: translate3d(39vw, 109vh, 0);
      transform: translate3d(39vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(94vw, -129vh, 0);
      transform: translate3d(94vw, -129vh, 0);
    }
  }

  .fireflies_item:nth-child(116) .fireflies_inner {
    -webkit-animation-delay: 1018ms;
    animation-delay: 1018ms;
  }

  .fireflies_item:nth-child(117) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-117;
    animation-name: an-move-frames-117;
    -webkit-animation-duration: 32505ms;
    animation-duration: 32505ms;
    -webkit-animation-delay: 32816ms;
    animation-delay: 32816ms;
  }

  @-webkit-keyframes an-move-frames-117 {
    from {
      -webkit-transform: translate3d(35vw, 109vh, 0);
      transform: translate3d(35vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(81vw, -129vh, 0);
      transform: translate3d(81vw, -129vh, 0);
    }
  }

  @keyframes an-move-frames-117 {
    from {
      -webkit-transform: translate3d(35vw, 109vh, 0);
      transform: translate3d(35vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(81vw, -129vh, 0);
      transform: translate3d(81vw, -129vh, 0);
    }
  }

  .fireflies_item:nth-child(117) .fireflies_inner {
    -webkit-animation-delay: 1073ms;
    animation-delay: 1073ms;
  }

  .fireflies_item:nth-child(118) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-118;
    animation-name: an-move-frames-118;
    -webkit-animation-duration: 31564ms;
    animation-duration: 31564ms;
    -webkit-animation-delay: 26432ms;
    animation-delay: 26432ms;
  }

  @-webkit-keyframes an-move-frames-118 {
    from {
      -webkit-transform: translate3d(60vw, 101vh, 0);
      transform: translate3d(60vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(11vw, -103vh, 0);
      transform: translate3d(11vw, -103vh, 0);
    }
  }

  @keyframes an-move-frames-118 {
    from {
      -webkit-transform: translate3d(60vw, 101vh, 0);
      transform: translate3d(60vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(11vw, -103vh, 0);
      transform: translate3d(11vw, -103vh, 0);
    }
  }

  .fireflies_item:nth-child(118) .fireflies_inner {
    -webkit-animation-delay: 2309ms;
    animation-delay: 2309ms;
  }

  .fireflies_item:nth-child(119) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-119;
    animation-name: an-move-frames-119;
    -webkit-animation-duration: 29590ms;
    animation-duration: 29590ms;
    -webkit-animation-delay: 6280ms;
    animation-delay: 6280ms;
  }

  @-webkit-keyframes an-move-frames-119 {
    from {
      -webkit-transform: translate3d(86vw, 101vh, 0);
      transform: translate3d(86vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(21vw, -122vh, 0);
      transform: translate3d(21vw, -122vh, 0);
    }
  }

  @keyframes an-move-frames-119 {
    from {
      -webkit-transform: translate3d(86vw, 101vh, 0);
      transform: translate3d(86vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(21vw, -122vh, 0);
      transform: translate3d(21vw, -122vh, 0);
    }
  }

  .fireflies_item:nth-child(119) .fireflies_inner {
    -webkit-animation-delay: 1618ms;
    animation-delay: 1618ms;
  }

  .fireflies_item:nth-child(120) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-120;
    animation-name: an-move-frames-120;
    -webkit-animation-duration: 28379ms;
    animation-duration: 28379ms;
    -webkit-animation-delay: 12660ms;
    animation-delay: 12660ms;
  }

  @-webkit-keyframes an-move-frames-120 {
    from {
      -webkit-transform: translate3d(55vw, 102vh, 0);
      transform: translate3d(55vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(76vw, -105vh, 0);
      transform: translate3d(76vw, -105vh, 0);
    }
  }

  @keyframes an-move-frames-120 {
    from {
      -webkit-transform: translate3d(55vw, 102vh, 0);
      transform: translate3d(55vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(76vw, -105vh, 0);
      transform: translate3d(76vw, -105vh, 0);
    }
  }

  .fireflies_item:nth-child(120) .fireflies_inner {
    -webkit-animation-delay: 389ms;
    animation-delay: 389ms;
  }

  .fireflies_item:nth-child(121) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-121;
    animation-name: an-move-frames-121;
    -webkit-animation-duration: 29625ms;
    animation-duration: 29625ms;
    -webkit-animation-delay: 17942ms;
    animation-delay: 17942ms;
  }

  @-webkit-keyframes an-move-frames-121 {
    from {
      -webkit-transform: translate3d(1vw, 103vh, 0);
      transform: translate3d(1vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(81vw, -123vh, 0);
      transform: translate3d(81vw, -123vh, 0);
    }
  }

  @keyframes an-move-frames-121 {
    from {
      -webkit-transform: translate3d(1vw, 103vh, 0);
      transform: translate3d(1vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(81vw, -123vh, 0);
      transform: translate3d(81vw, -123vh, 0);
    }
  }

  .fireflies_item:nth-child(121) .fireflies_inner {
    -webkit-animation-delay: 1046ms;
    animation-delay: 1046ms;
  }

  .fireflies_item:nth-child(122) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-122;
    animation-name: an-move-frames-122;
    -webkit-animation-duration: 28219ms;
    animation-duration: 28219ms;
    -webkit-animation-delay: 34522ms;
    animation-delay: 34522ms;
  }

  @-webkit-keyframes an-move-frames-122 {
    from {
      -webkit-transform: translate3d(26vw, 106vh, 0);
      transform: translate3d(26vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(43vw, -128vh, 0);
      transform: translate3d(43vw, -128vh, 0);
    }
  }

  @keyframes an-move-frames-122 {
    from {
      -webkit-transform: translate3d(26vw, 106vh, 0);
      transform: translate3d(26vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(43vw, -128vh, 0);
      transform: translate3d(43vw, -128vh, 0);
    }
  }

  .fireflies_item:nth-child(122) .fireflies_inner {
    -webkit-animation-delay: 2275ms;
    animation-delay: 2275ms;
  }

  .fireflies_item:nth-child(123) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-123;
    animation-name: an-move-frames-123;
    -webkit-animation-duration: 33762ms;
    animation-duration: 33762ms;
    -webkit-animation-delay: 31412ms;
    animation-delay: 31412ms;
  }

  @-webkit-keyframes an-move-frames-123 {
    from {
      -webkit-transform: translate3d(98vw, 106vh, 0);
      transform: translate3d(98vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(43vw, -131vh, 0);
      transform: translate3d(43vw, -131vh, 0);
    }
  }

  @keyframes an-move-frames-123 {
    from {
      -webkit-transform: translate3d(98vw, 106vh, 0);
      transform: translate3d(98vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(43vw, -131vh, 0);
      transform: translate3d(43vw, -131vh, 0);
    }
  }

  .fireflies_item:nth-child(123) .fireflies_inner {
    -webkit-animation-delay: 801ms;
    animation-delay: 801ms;
  }

  .fireflies_item:nth-child(124) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-124;
    animation-name: an-move-frames-124;
    -webkit-animation-duration: 34716ms;
    animation-duration: 34716ms;
    -webkit-animation-delay: 19493ms;
    animation-delay: 19493ms;
  }

  @-webkit-keyframes an-move-frames-124 {
    from {
      -webkit-transform: translate3d(63vw, 103vh, 0);
      transform: translate3d(63vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(68vw, -106vh, 0);
      transform: translate3d(68vw, -106vh, 0);
    }
  }

  @keyframes an-move-frames-124 {
    from {
      -webkit-transform: translate3d(63vw, 103vh, 0);
      transform: translate3d(63vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(68vw, -106vh, 0);
      transform: translate3d(68vw, -106vh, 0);
    }
  }

  .fireflies_item:nth-child(124) .fireflies_inner {
    -webkit-animation-delay: 30ms;
    animation-delay: 30ms;
  }

  .fireflies_item:nth-child(125) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-125;
    animation-name: an-move-frames-125;
    -webkit-animation-duration: 35093ms;
    animation-duration: 35093ms;
    -webkit-animation-delay: 11498ms;
    animation-delay: 11498ms;
  }

  @-webkit-keyframes an-move-frames-125 {
    from {
      -webkit-transform: translate3d(31vw, 107vh, 0);
      transform: translate3d(31vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -133vh, 0);
      transform: translate3d(63vw, -133vh, 0);
    }
  }

  @keyframes an-move-frames-125 {
    from {
      -webkit-transform: translate3d(31vw, 107vh, 0);
      transform: translate3d(31vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -133vh, 0);
      transform: translate3d(63vw, -133vh, 0);
    }
  }

  .fireflies_item:nth-child(125) .fireflies_inner {
    -webkit-animation-delay: 116ms;
    animation-delay: 116ms;
  }

  .fireflies_item:nth-child(126) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-126;
    animation-name: an-move-frames-126;
    -webkit-animation-duration: 31163ms;
    animation-duration: 31163ms;
    -webkit-animation-delay: 32385ms;
    animation-delay: 32385ms;
  }

  @-webkit-keyframes an-move-frames-126 {
    from {
      -webkit-transform: translate3d(62vw, 103vh, 0);
      transform: translate3d(62vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(68vw, -123vh, 0);
      transform: translate3d(68vw, -123vh, 0);
    }
  }

  @keyframes an-move-frames-126 {
    from {
      -webkit-transform: translate3d(62vw, 103vh, 0);
      transform: translate3d(62vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(68vw, -123vh, 0);
      transform: translate3d(68vw, -123vh, 0);
    }
  }

  .fireflies_item:nth-child(126) .fireflies_inner {
    -webkit-animation-delay: 3455ms;
    animation-delay: 3455ms;
  }

  .fireflies_item:nth-child(127) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-127;
    animation-name: an-move-frames-127;
    -webkit-animation-duration: 33152ms;
    animation-duration: 33152ms;
    -webkit-animation-delay: 4061ms;
    animation-delay: 4061ms;
  }

  @-webkit-keyframes an-move-frames-127 {
    from {
      -webkit-transform: translate3d(74vw, 106vh, 0);
      transform: translate3d(74vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(77vw, -131vh, 0);
      transform: translate3d(77vw, -131vh, 0);
    }
  }

  @keyframes an-move-frames-127 {
    from {
      -webkit-transform: translate3d(74vw, 106vh, 0);
      transform: translate3d(74vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(77vw, -131vh, 0);
      transform: translate3d(77vw, -131vh, 0);
    }
  }

  .fireflies_item:nth-child(127) .fireflies_inner {
    -webkit-animation-delay: 2995ms;
    animation-delay: 2995ms;
  }

  .fireflies_item:nth-child(128) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-128;
    animation-name: an-move-frames-128;
    -webkit-animation-duration: 29781ms;
    animation-duration: 29781ms;
    -webkit-animation-delay: 11875ms;
    animation-delay: 11875ms;
  }

  @-webkit-keyframes an-move-frames-128 {
    from {
      -webkit-transform: translate3d(51vw, 101vh, 0);
      transform: translate3d(51vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(5vw, -106vh, 0);
      transform: translate3d(5vw, -106vh, 0);
    }
  }

  @keyframes an-move-frames-128 {
    from {
      -webkit-transform: translate3d(51vw, 101vh, 0);
      transform: translate3d(51vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(5vw, -106vh, 0);
      transform: translate3d(5vw, -106vh, 0);
    }
  }

  .fireflies_item:nth-child(128) .fireflies_inner {
    -webkit-animation-delay: 1020ms;
    animation-delay: 1020ms;
  }

  .fireflies_item:nth-child(129) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-129;
    animation-name: an-move-frames-129;
    -webkit-animation-duration: 32994ms;
    animation-duration: 32994ms;
    -webkit-animation-delay: 16070ms;
    animation-delay: 16070ms;
  }

  @-webkit-keyframes an-move-frames-129 {
    from {
      -webkit-transform: translate3d(38vw, 102vh, 0);
      transform: translate3d(38vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(43vw, -105vh, 0);
      transform: translate3d(43vw, -105vh, 0);
    }
  }

  @keyframes an-move-frames-129 {
    from {
      -webkit-transform: translate3d(38vw, 102vh, 0);
      transform: translate3d(38vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(43vw, -105vh, 0);
      transform: translate3d(43vw, -105vh, 0);
    }
  }

  .fireflies_item:nth-child(129) .fireflies_inner {
    -webkit-animation-delay: 2312ms;
    animation-delay: 2312ms;
  }

  .fireflies_item:nth-child(130) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-130;
    animation-name: an-move-frames-130;
    -webkit-animation-duration: 35964ms;
    animation-duration: 35964ms;
    -webkit-animation-delay: 1741ms;
    animation-delay: 1741ms;
  }

  @-webkit-keyframes an-move-frames-130 {
    from {
      -webkit-transform: translate3d(1vw, 103vh, 0);
      transform: translate3d(1vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(10vw, -107vh, 0);
      transform: translate3d(10vw, -107vh, 0);
    }
  }

  @keyframes an-move-frames-130 {
    from {
      -webkit-transform: translate3d(1vw, 103vh, 0);
      transform: translate3d(1vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(10vw, -107vh, 0);
      transform: translate3d(10vw, -107vh, 0);
    }
  }

  .fireflies_item:nth-child(130) .fireflies_inner {
    -webkit-animation-delay: 3905ms;
    animation-delay: 3905ms;
  }

  .fireflies_item:nth-child(131) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-131;
    animation-name: an-move-frames-131;
    -webkit-animation-duration: 35559ms;
    animation-duration: 35559ms;
    -webkit-animation-delay: 16612ms;
    animation-delay: 16612ms;
  }

  @-webkit-keyframes an-move-frames-131 {
    from {
      -webkit-transform: translate3d(55vw, 101vh, 0);
      transform: translate3d(55vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(4vw, -130vh, 0);
      transform: translate3d(4vw, -130vh, 0);
    }
  }

  @keyframes an-move-frames-131 {
    from {
      -webkit-transform: translate3d(55vw, 101vh, 0);
      transform: translate3d(55vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(4vw, -130vh, 0);
      transform: translate3d(4vw, -130vh, 0);
    }
  }

  .fireflies_item:nth-child(131) .fireflies_inner {
    -webkit-animation-delay: 818ms;
    animation-delay: 818ms;
  }

  .fireflies_item:nth-child(132) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-132;
    animation-name: an-move-frames-132;
    -webkit-animation-duration: 35700ms;
    animation-duration: 35700ms;
    -webkit-animation-delay: 34581ms;
    animation-delay: 34581ms;
  }

  @-webkit-keyframes an-move-frames-132 {
    from {
      -webkit-transform: translate3d(28vw, 101vh, 0);
      transform: translate3d(28vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(93vw, -118vh, 0);
      transform: translate3d(93vw, -118vh, 0);
    }
  }

  @keyframes an-move-frames-132 {
    from {
      -webkit-transform: translate3d(28vw, 101vh, 0);
      transform: translate3d(28vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(93vw, -118vh, 0);
      transform: translate3d(93vw, -118vh, 0);
    }
  }

  .fireflies_item:nth-child(132) .fireflies_inner {
    -webkit-animation-delay: 2517ms;
    animation-delay: 2517ms;
  }

  .fireflies_item:nth-child(133) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-133;
    animation-name: an-move-frames-133;
    -webkit-animation-duration: 28661ms;
    animation-duration: 28661ms;
    -webkit-animation-delay: 26996ms;
    animation-delay: 26996ms;
  }

  @-webkit-keyframes an-move-frames-133 {
    from {
      -webkit-transform: translate3d(60vw, 107vh, 0);
      transform: translate3d(60vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(76vw, -137vh, 0);
      transform: translate3d(76vw, -137vh, 0);
    }
  }

  @keyframes an-move-frames-133 {
    from {
      -webkit-transform: translate3d(60vw, 107vh, 0);
      transform: translate3d(60vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(76vw, -137vh, 0);
      transform: translate3d(76vw, -137vh, 0);
    }
  }

  .fireflies_item:nth-child(133) .fireflies_inner {
    -webkit-animation-delay: 215ms;
    animation-delay: 215ms;
  }

  .fireflies_item:nth-child(134) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-134;
    animation-name: an-move-frames-134;
    -webkit-animation-duration: 34905ms;
    animation-duration: 34905ms;
    -webkit-animation-delay: 22248ms;
    animation-delay: 22248ms;
  }

  @-webkit-keyframes an-move-frames-134 {
    from {
      -webkit-transform: translate3d(100vw, 105vh, 0);
      transform: translate3d(100vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(45vw, -110vh, 0);
      transform: translate3d(45vw, -110vh, 0);
    }
  }

  @keyframes an-move-frames-134 {
    from {
      -webkit-transform: translate3d(100vw, 105vh, 0);
      transform: translate3d(100vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(45vw, -110vh, 0);
      transform: translate3d(45vw, -110vh, 0);
    }
  }

  .fireflies_item:nth-child(134) .fireflies_inner {
    -webkit-animation-delay: 3968ms;
    animation-delay: 3968ms;
  }

  .fireflies_item:nth-child(135) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-135;
    animation-name: an-move-frames-135;
    -webkit-animation-duration: 29126ms;
    animation-duration: 29126ms;
    -webkit-animation-delay: 2880ms;
    animation-delay: 2880ms;
  }

  @-webkit-keyframes an-move-frames-135 {
    from {
      -webkit-transform: translate3d(45vw, 108vh, 0);
      transform: translate3d(45vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(29vw, -121vh, 0);
      transform: translate3d(29vw, -121vh, 0);
    }
  }

  @keyframes an-move-frames-135 {
    from {
      -webkit-transform: translate3d(45vw, 108vh, 0);
      transform: translate3d(45vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(29vw, -121vh, 0);
      transform: translate3d(29vw, -121vh, 0);
    }
  }

  .fireflies_item:nth-child(135) .fireflies_inner {
    -webkit-animation-delay: 2500ms;
    animation-delay: 2500ms;
  }

  .fireflies_item:nth-child(136) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-136;
    animation-name: an-move-frames-136;
    -webkit-animation-duration: 28666ms;
    animation-duration: 28666ms;
    -webkit-animation-delay: 37ms;
    animation-delay: 37ms;
  }

  @-webkit-keyframes an-move-frames-136 {
    from {
      -webkit-transform: translate3d(75vw, 104vh, 0);
      transform: translate3d(75vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(88vw, -129vh, 0);
      transform: translate3d(88vw, -129vh, 0);
    }
  }

  @keyframes an-move-frames-136 {
    from {
      -webkit-transform: translate3d(75vw, 104vh, 0);
      transform: translate3d(75vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(88vw, -129vh, 0);
      transform: translate3d(88vw, -129vh, 0);
    }
  }

  .fireflies_item:nth-child(136) .fireflies_inner {
    -webkit-animation-delay: 726ms;
    animation-delay: 726ms;
  }

  .fireflies_item:nth-child(137) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-137;
    animation-name: an-move-frames-137;
    -webkit-animation-duration: 30304ms;
    animation-duration: 30304ms;
    -webkit-animation-delay: 17669ms;
    animation-delay: 17669ms;
  }

  @-webkit-keyframes an-move-frames-137 {
    from {
      -webkit-transform: translate3d(58vw, 101vh, 0);
      transform: translate3d(58vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(60vw, -116vh, 0);
      transform: translate3d(60vw, -116vh, 0);
    }
  }

  @keyframes an-move-frames-137 {
    from {
      -webkit-transform: translate3d(58vw, 101vh, 0);
      transform: translate3d(58vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(60vw, -116vh, 0);
      transform: translate3d(60vw, -116vh, 0);
    }
  }

  .fireflies_item:nth-child(137) .fireflies_inner {
    -webkit-animation-delay: 2831ms;
    animation-delay: 2831ms;
  }

  .fireflies_item:nth-child(138) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-138;
    animation-name: an-move-frames-138;
    -webkit-animation-duration: 28065ms;
    animation-duration: 28065ms;
    -webkit-animation-delay: 28402ms;
    animation-delay: 28402ms;
  }

  @-webkit-keyframes an-move-frames-138 {
    from {
      -webkit-transform: translate3d(54vw, 108vh, 0);
      transform: translate3d(54vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(15vw, -117vh, 0);
      transform: translate3d(15vw, -117vh, 0);
    }
  }

  @keyframes an-move-frames-138 {
    from {
      -webkit-transform: translate3d(54vw, 108vh, 0);
      transform: translate3d(54vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(15vw, -117vh, 0);
      transform: translate3d(15vw, -117vh, 0);
    }
  }

  .fireflies_item:nth-child(138) .fireflies_inner {
    -webkit-animation-delay: 2482ms;
    animation-delay: 2482ms;
  }

  .fireflies_item:nth-child(139) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-139;
    animation-name: an-move-frames-139;
    -webkit-animation-duration: 29743ms;
    animation-duration: 29743ms;
    -webkit-animation-delay: 36592ms;
    animation-delay: 36592ms;
  }

  @-webkit-keyframes an-move-frames-139 {
    from {
      -webkit-transform: translate3d(46vw, 104vh, 0);
      transform: translate3d(46vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(42vw, -105vh, 0);
      transform: translate3d(42vw, -105vh, 0);
    }
  }

  @keyframes an-move-frames-139 {
    from {
      -webkit-transform: translate3d(46vw, 104vh, 0);
      transform: translate3d(46vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(42vw, -105vh, 0);
      transform: translate3d(42vw, -105vh, 0);
    }
  }

  .fireflies_item:nth-child(139) .fireflies_inner {
    -webkit-animation-delay: 528ms;
    animation-delay: 528ms;
  }

  .fireflies_item:nth-child(140) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-140;
    animation-name: an-move-frames-140;
    -webkit-animation-duration: 31117ms;
    animation-duration: 31117ms;
    -webkit-animation-delay: 21519ms;
    animation-delay: 21519ms;
  }

  @-webkit-keyframes an-move-frames-140 {
    from {
      -webkit-transform: translate3d(37vw, 102vh, 0);
      transform: translate3d(37vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(34vw, -120vh, 0);
      transform: translate3d(34vw, -120vh, 0);
    }
  }

  @keyframes an-move-frames-140 {
    from {
      -webkit-transform: translate3d(37vw, 102vh, 0);
      transform: translate3d(37vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(34vw, -120vh, 0);
      transform: translate3d(34vw, -120vh, 0);
    }
  }

  .fireflies_item:nth-child(140) .fireflies_inner {
    -webkit-animation-delay: 1816ms;
    animation-delay: 1816ms;
  }

  .fireflies_item:nth-child(141) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-141;
    animation-name: an-move-frames-141;
    -webkit-animation-duration: 36595ms;
    animation-duration: 36595ms;
    -webkit-animation-delay: 17550ms;
    animation-delay: 17550ms;
  }

  @-webkit-keyframes an-move-frames-141 {
    from {
      -webkit-transform: translate3d(24vw, 109vh, 0);
      transform: translate3d(24vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(64vw, -131vh, 0);
      transform: translate3d(64vw, -131vh, 0);
    }
  }

  @keyframes an-move-frames-141 {
    from {
      -webkit-transform: translate3d(24vw, 109vh, 0);
      transform: translate3d(24vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(64vw, -131vh, 0);
      transform: translate3d(64vw, -131vh, 0);
    }
  }

  .fireflies_item:nth-child(141) .fireflies_inner {
    -webkit-animation-delay: 3334ms;
    animation-delay: 3334ms;
  }

  .fireflies_item:nth-child(142) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-142;
    animation-name: an-move-frames-142;
    -webkit-animation-duration: 35147ms;
    animation-duration: 35147ms;
    -webkit-animation-delay: 12549ms;
    animation-delay: 12549ms;
  }

  @-webkit-keyframes an-move-frames-142 {
    from {
      -webkit-transform: translate3d(32vw, 104vh, 0);
      transform: translate3d(32vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(89vw, -129vh, 0);
      transform: translate3d(89vw, -129vh, 0);
    }
  }

  @keyframes an-move-frames-142 {
    from {
      -webkit-transform: translate3d(32vw, 104vh, 0);
      transform: translate3d(32vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(89vw, -129vh, 0);
      transform: translate3d(89vw, -129vh, 0);
    }
  }

  .fireflies_item:nth-child(142) .fireflies_inner {
    -webkit-animation-delay: 1761ms;
    animation-delay: 1761ms;
  }

  .fireflies_item:nth-child(143) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-143;
    animation-name: an-move-frames-143;
    -webkit-animation-duration: 35884ms;
    animation-duration: 35884ms;
    -webkit-animation-delay: 14000ms;
    animation-delay: 14000ms;
  }

  @-webkit-keyframes an-move-frames-143 {
    from {
      -webkit-transform: translate3d(16vw, 106vh, 0);
      transform: translate3d(16vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(75vw, -128vh, 0);
      transform: translate3d(75vw, -128vh, 0);
    }
  }

  @keyframes an-move-frames-143 {
    from {
      -webkit-transform: translate3d(16vw, 106vh, 0);
      transform: translate3d(16vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(75vw, -128vh, 0);
      transform: translate3d(75vw, -128vh, 0);
    }
  }

  .fireflies_item:nth-child(143) .fireflies_inner {
    -webkit-animation-delay: 297ms;
    animation-delay: 297ms;
  }

  .fireflies_item:nth-child(144) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-144;
    animation-name: an-move-frames-144;
    -webkit-animation-duration: 30518ms;
    animation-duration: 30518ms;
    -webkit-animation-delay: 7648ms;
    animation-delay: 7648ms;
  }

  @-webkit-keyframes an-move-frames-144 {
    from {
      -webkit-transform: translate3d(85vw, 110vh, 0);
      transform: translate3d(85vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(9vw, -115vh, 0);
      transform: translate3d(9vw, -115vh, 0);
    }
  }

  @keyframes an-move-frames-144 {
    from {
      -webkit-transform: translate3d(85vw, 110vh, 0);
      transform: translate3d(85vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(9vw, -115vh, 0);
      transform: translate3d(9vw, -115vh, 0);
    }
  }

  .fireflies_item:nth-child(144) .fireflies_inner {
    -webkit-animation-delay: 2010ms;
    animation-delay: 2010ms;
  }

  .fireflies_item:nth-child(145) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-145;
    animation-name: an-move-frames-145;
    -webkit-animation-duration: 30093ms;
    animation-duration: 30093ms;
    -webkit-animation-delay: 21729ms;
    animation-delay: 21729ms;
  }

  @-webkit-keyframes an-move-frames-145 {
    from {
      -webkit-transform: translate3d(78vw, 107vh, 0);
      transform: translate3d(78vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(62vw, -133vh, 0);
      transform: translate3d(62vw, -133vh, 0);
    }
  }

  @keyframes an-move-frames-145 {
    from {
      -webkit-transform: translate3d(78vw, 107vh, 0);
      transform: translate3d(78vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(62vw, -133vh, 0);
      transform: translate3d(62vw, -133vh, 0);
    }
  }

  .fireflies_item:nth-child(145) .fireflies_inner {
    -webkit-animation-delay: 2444ms;
    animation-delay: 2444ms;
  }

  .fireflies_item:nth-child(146) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-146;
    animation-name: an-move-frames-146;
    -webkit-animation-duration: 33975ms;
    animation-duration: 33975ms;
    -webkit-animation-delay: 9135ms;
    animation-delay: 9135ms;
  }

  @-webkit-keyframes an-move-frames-146 {
    from {
      -webkit-transform: translate3d(91vw, 108vh, 0);
      transform: translate3d(91vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(75vw, -122vh, 0);
      transform: translate3d(75vw, -122vh, 0);
    }
  }

  @keyframes an-move-frames-146 {
    from {
      -webkit-transform: translate3d(91vw, 108vh, 0);
      transform: translate3d(91vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(75vw, -122vh, 0);
      transform: translate3d(75vw, -122vh, 0);
    }
  }

  .fireflies_item:nth-child(146) .fireflies_inner {
    -webkit-animation-delay: 2024ms;
    animation-delay: 2024ms;
  }

  .fireflies_item:nth-child(147) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-147;
    animation-name: an-move-frames-147;
    -webkit-animation-duration: 30202ms;
    animation-duration: 30202ms;
    -webkit-animation-delay: 8100ms;
    animation-delay: 8100ms;
  }

  @-webkit-keyframes an-move-frames-147 {
    from {
      -webkit-transform: translate3d(69vw, 107vh, 0);
      transform: translate3d(69vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(22vw, -130vh, 0);
      transform: translate3d(22vw, -130vh, 0);
    }
  }

  @keyframes an-move-frames-147 {
    from {
      -webkit-transform: translate3d(69vw, 107vh, 0);
      transform: translate3d(69vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(22vw, -130vh, 0);
      transform: translate3d(22vw, -130vh, 0);
    }
  }

  .fireflies_item:nth-child(147) .fireflies_inner {
    -webkit-animation-delay: 1377ms;
    animation-delay: 1377ms;
  }

  .fireflies_item:nth-child(148) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-148;
    animation-name: an-move-frames-148;
    -webkit-animation-duration: 35863ms;
    animation-duration: 35863ms;
    -webkit-animation-delay: 35450ms;
    animation-delay: 35450ms;
  }

  @-webkit-keyframes an-move-frames-148 {
    from {
      -webkit-transform: translate3d(11vw, 106vh, 0);
      transform: translate3d(11vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(31vw, -121vh, 0);
      transform: translate3d(31vw, -121vh, 0);
    }
  }

  @keyframes an-move-frames-148 {
    from {
      -webkit-transform: translate3d(11vw, 106vh, 0);
      transform: translate3d(11vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(31vw, -121vh, 0);
      transform: translate3d(31vw, -121vh, 0);
    }
  }

  .fireflies_item:nth-child(148) .fireflies_inner {
    -webkit-animation-delay: 617ms;
    animation-delay: 617ms;
  }

  .fireflies_item:nth-child(149) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-149;
    animation-name: an-move-frames-149;
    -webkit-animation-duration: 29760ms;
    animation-duration: 29760ms;
    -webkit-animation-delay: 805ms;
    animation-delay: 805ms;
  }

  @-webkit-keyframes an-move-frames-149 {
    from {
      -webkit-transform: translate3d(10vw, 109vh, 0);
      transform: translate3d(10vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(24vw, -133vh, 0);
      transform: translate3d(24vw, -133vh, 0);
    }
  }

  @keyframes an-move-frames-149 {
    from {
      -webkit-transform: translate3d(10vw, 109vh, 0);
      transform: translate3d(10vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(24vw, -133vh, 0);
      transform: translate3d(24vw, -133vh, 0);
    }
  }

  .fireflies_item:nth-child(149) .fireflies_inner {
    -webkit-animation-delay: 2745ms;
    animation-delay: 2745ms;
  }

  .fireflies_item:nth-child(150) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-150;
    animation-name: an-move-frames-150;
    -webkit-animation-duration: 28892ms;
    animation-duration: 28892ms;
    -webkit-animation-delay: 17611ms;
    animation-delay: 17611ms;
  }

  @-webkit-keyframes an-move-frames-150 {
    from {
      -webkit-transform: translate3d(24vw, 108vh, 0);
      transform: translate3d(24vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(27vw, -113vh, 0);
      transform: translate3d(27vw, -113vh, 0);
    }
  }

  @keyframes an-move-frames-150 {
    from {
      -webkit-transform: translate3d(24vw, 108vh, 0);
      transform: translate3d(24vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(27vw, -113vh, 0);
      transform: translate3d(27vw, -113vh, 0);
    }
  }

  .fireflies_item:nth-child(150) .fireflies_inner {
    -webkit-animation-delay: 2530ms;
    animation-delay: 2530ms;
  }

  .fireflies_item:nth-child(151) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-151;
    animation-name: an-move-frames-151;
    -webkit-animation-duration: 33297ms;
    animation-duration: 33297ms;
    -webkit-animation-delay: 12319ms;
    animation-delay: 12319ms;
  }

  @-webkit-keyframes an-move-frames-151 {
    from {
      -webkit-transform: translate3d(57vw, 106vh, 0);
      transform: translate3d(57vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(45vw, -127vh, 0);
      transform: translate3d(45vw, -127vh, 0);
    }
  }

  @keyframes an-move-frames-151 {
    from {
      -webkit-transform: translate3d(57vw, 106vh, 0);
      transform: translate3d(57vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(45vw, -127vh, 0);
      transform: translate3d(45vw, -127vh, 0);
    }
  }

  .fireflies_item:nth-child(151) .fireflies_inner {
    -webkit-animation-delay: 1054ms;
    animation-delay: 1054ms;
  }

  .fireflies_item:nth-child(152) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-152;
    animation-name: an-move-frames-152;
    -webkit-animation-duration: 28981ms;
    animation-duration: 28981ms;
    -webkit-animation-delay: 1608ms;
    animation-delay: 1608ms;
  }

  @-webkit-keyframes an-move-frames-152 {
    from {
      -webkit-transform: translate3d(9vw, 106vh, 0);
      transform: translate3d(9vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(22vw, -126vh, 0);
      transform: translate3d(22vw, -126vh, 0);
    }
  }

  @keyframes an-move-frames-152 {
    from {
      -webkit-transform: translate3d(9vw, 106vh, 0);
      transform: translate3d(9vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(22vw, -126vh, 0);
      transform: translate3d(22vw, -126vh, 0);
    }
  }

  .fireflies_item:nth-child(152) .fireflies_inner {
    -webkit-animation-delay: 1751ms;
    animation-delay: 1751ms;
  }

  .fireflies_item:nth-child(153) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-153;
    animation-name: an-move-frames-153;
    -webkit-animation-duration: 34323ms;
    animation-duration: 34323ms;
    -webkit-animation-delay: 31389ms;
    animation-delay: 31389ms;
  }

  @-webkit-keyframes an-move-frames-153 {
    from {
      -webkit-transform: translate3d(89vw, 101vh, 0);
      transform: translate3d(89vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(29vw, -110vh, 0);
      transform: translate3d(29vw, -110vh, 0);
    }
  }

  @keyframes an-move-frames-153 {
    from {
      -webkit-transform: translate3d(89vw, 101vh, 0);
      transform: translate3d(89vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(29vw, -110vh, 0);
      transform: translate3d(29vw, -110vh, 0);
    }
  }

  .fireflies_item:nth-child(153) .fireflies_inner {
    -webkit-animation-delay: 1290ms;
    animation-delay: 1290ms;
  }

  .fireflies_item:nth-child(154) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-154;
    animation-name: an-move-frames-154;
    -webkit-animation-duration: 31955ms;
    animation-duration: 31955ms;
    -webkit-animation-delay: 1267ms;
    animation-delay: 1267ms;
  }

  @-webkit-keyframes an-move-frames-154 {
    from {
      -webkit-transform: translate3d(77vw, 106vh, 0);
      transform: translate3d(77vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(66vw, -126vh, 0);
      transform: translate3d(66vw, -126vh, 0);
    }
  }

  @keyframes an-move-frames-154 {
    from {
      -webkit-transform: translate3d(77vw, 106vh, 0);
      transform: translate3d(77vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(66vw, -126vh, 0);
      transform: translate3d(66vw, -126vh, 0);
    }
  }

  .fireflies_item:nth-child(154) .fireflies_inner {
    -webkit-animation-delay: 3942ms;
    animation-delay: 3942ms;
  }

  .fireflies_item:nth-child(155) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-155;
    animation-name: an-move-frames-155;
    -webkit-animation-duration: 29838ms;
    animation-duration: 29838ms;
    -webkit-animation-delay: 15619ms;
    animation-delay: 15619ms;
  }

  @-webkit-keyframes an-move-frames-155 {
    from {
      -webkit-transform: translate3d(49vw, 109vh, 0);
      transform: translate3d(49vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(79vw, -137vh, 0);
      transform: translate3d(79vw, -137vh, 0);
    }
  }

  @keyframes an-move-frames-155 {
    from {
      -webkit-transform: translate3d(49vw, 109vh, 0);
      transform: translate3d(49vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(79vw, -137vh, 0);
      transform: translate3d(79vw, -137vh, 0);
    }
  }

  .fireflies_item:nth-child(155) .fireflies_inner {
    -webkit-animation-delay: 2741ms;
    animation-delay: 2741ms;
  }

  .fireflies_item:nth-child(156) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-156;
    animation-name: an-move-frames-156;
    -webkit-animation-duration: 34439ms;
    animation-duration: 34439ms;
    -webkit-animation-delay: 27590ms;
    animation-delay: 27590ms;
  }

  @-webkit-keyframes an-move-frames-156 {
    from {
      -webkit-transform: translate3d(99vw, 102vh, 0);
      transform: translate3d(99vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(51vw, -107vh, 0);
      transform: translate3d(51vw, -107vh, 0);
    }
  }

  @keyframes an-move-frames-156 {
    from {
      -webkit-transform: translate3d(99vw, 102vh, 0);
      transform: translate3d(99vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(51vw, -107vh, 0);
      transform: translate3d(51vw, -107vh, 0);
    }
  }

  .fireflies_item:nth-child(156) .fireflies_inner {
    -webkit-animation-delay: 898ms;
    animation-delay: 898ms;
  }

  .fireflies_item:nth-child(157) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-157;
    animation-name: an-move-frames-157;
    -webkit-animation-duration: 29825ms;
    animation-duration: 29825ms;
    -webkit-animation-delay: 6485ms;
    animation-delay: 6485ms;
  }

  @-webkit-keyframes an-move-frames-157 {
    from {
      -webkit-transform: translate3d(14vw, 105vh, 0);
      transform: translate3d(14vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(3vw, -112vh, 0);
      transform: translate3d(3vw, -112vh, 0);
    }
  }

  @keyframes an-move-frames-157 {
    from {
      -webkit-transform: translate3d(14vw, 105vh, 0);
      transform: translate3d(14vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(3vw, -112vh, 0);
      transform: translate3d(3vw, -112vh, 0);
    }
  }

  .fireflies_item:nth-child(157) .fireflies_inner {
    -webkit-animation-delay: 2343ms;
    animation-delay: 2343ms;
  }

  .fireflies_item:nth-child(158) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-158;
    animation-name: an-move-frames-158;
    -webkit-animation-duration: 32507ms;
    animation-duration: 32507ms;
    -webkit-animation-delay: 27627ms;
    animation-delay: 27627ms;
  }

  @-webkit-keyframes an-move-frames-158 {
    from {
      -webkit-transform: translate3d(27vw, 106vh, 0);
      transform: translate3d(27vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(74vw, -120vh, 0);
      transform: translate3d(74vw, -120vh, 0);
    }
  }

  @keyframes an-move-frames-158 {
    from {
      -webkit-transform: translate3d(27vw, 106vh, 0);
      transform: translate3d(27vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(74vw, -120vh, 0);
      transform: translate3d(74vw, -120vh, 0);
    }
  }

  .fireflies_item:nth-child(158) .fireflies_inner {
    -webkit-animation-delay: 1506ms;
    animation-delay: 1506ms;
  }

  .fireflies_item:nth-child(159) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-159;
    animation-name: an-move-frames-159;
    -webkit-animation-duration: 31423ms;
    animation-duration: 31423ms;
    -webkit-animation-delay: 3265ms;
    animation-delay: 3265ms;
  }

  @-webkit-keyframes an-move-frames-159 {
    from {
      -webkit-transform: translate3d(63vw, 108vh, 0);
      transform: translate3d(63vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(65vw, -130vh, 0);
      transform: translate3d(65vw, -130vh, 0);
    }
  }

  @keyframes an-move-frames-159 {
    from {
      -webkit-transform: translate3d(63vw, 108vh, 0);
      transform: translate3d(63vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(65vw, -130vh, 0);
      transform: translate3d(65vw, -130vh, 0);
    }
  }

  .fireflies_item:nth-child(159) .fireflies_inner {
    -webkit-animation-delay: 401ms;
    animation-delay: 401ms;
  }

  .fireflies_item:nth-child(160) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-160;
    animation-name: an-move-frames-160;
    -webkit-animation-duration: 35209ms;
    animation-duration: 35209ms;
    -webkit-animation-delay: 19246ms;
    animation-delay: 19246ms;
  }

  @-webkit-keyframes an-move-frames-160 {
    from {
      -webkit-transform: translate3d(97vw, 104vh, 0);
      transform: translate3d(97vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(78vw, -110vh, 0);
      transform: translate3d(78vw, -110vh, 0);
    }
  }

  @keyframes an-move-frames-160 {
    from {
      -webkit-transform: translate3d(97vw, 104vh, 0);
      transform: translate3d(97vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(78vw, -110vh, 0);
      transform: translate3d(78vw, -110vh, 0);
    }
  }

  .fireflies_item:nth-child(160) .fireflies_inner {
    -webkit-animation-delay: 2884ms;
    animation-delay: 2884ms;
  }

  .fireflies_item:nth-child(161) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-161;
    animation-name: an-move-frames-161;
    -webkit-animation-duration: 35392ms;
    animation-duration: 35392ms;
    -webkit-animation-delay: 19024ms;
    animation-delay: 19024ms;
  }

  @-webkit-keyframes an-move-frames-161 {
    from {
      -webkit-transform: translate3d(47vw, 101vh, 0);
      transform: translate3d(47vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(8vw, -111vh, 0);
      transform: translate3d(8vw, -111vh, 0);
    }
  }

  @keyframes an-move-frames-161 {
    from {
      -webkit-transform: translate3d(47vw, 101vh, 0);
      transform: translate3d(47vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(8vw, -111vh, 0);
      transform: translate3d(8vw, -111vh, 0);
    }
  }

  .fireflies_item:nth-child(161) .fireflies_inner {
    -webkit-animation-delay: 593ms;
    animation-delay: 593ms;
  }

  .fireflies_item:nth-child(162) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-162;
    animation-name: an-move-frames-162;
    -webkit-animation-duration: 28689ms;
    animation-duration: 28689ms;
    -webkit-animation-delay: 19995ms;
    animation-delay: 19995ms;
  }

  @-webkit-keyframes an-move-frames-162 {
    from {
      -webkit-transform: translate3d(38vw, 101vh, 0);
      transform: translate3d(38vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(77vw, -114vh, 0);
      transform: translate3d(77vw, -114vh, 0);
    }
  }

  @keyframes an-move-frames-162 {
    from {
      -webkit-transform: translate3d(38vw, 101vh, 0);
      transform: translate3d(38vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(77vw, -114vh, 0);
      transform: translate3d(77vw, -114vh, 0);
    }
  }

  .fireflies_item:nth-child(162) .fireflies_inner {
    -webkit-animation-delay: 1367ms;
    animation-delay: 1367ms;
  }

  .fireflies_item:nth-child(163) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-163;
    animation-name: an-move-frames-163;
    -webkit-animation-duration: 30864ms;
    animation-duration: 30864ms;
    -webkit-animation-delay: 15511ms;
    animation-delay: 15511ms;
  }

  @-webkit-keyframes an-move-frames-163 {
    from {
      -webkit-transform: translate3d(61vw, 108vh, 0);
      transform: translate3d(61vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(95vw, -111vh, 0);
      transform: translate3d(95vw, -111vh, 0);
    }
  }

  @keyframes an-move-frames-163 {
    from {
      -webkit-transform: translate3d(61vw, 108vh, 0);
      transform: translate3d(61vw, 108vh, 0);
    }

    to {
      -webkit-transform: translate3d(95vw, -111vh, 0);
      transform: translate3d(95vw, -111vh, 0);
    }
  }

  .fireflies_item:nth-child(163) .fireflies_inner {
    -webkit-animation-delay: 1316ms;
    animation-delay: 1316ms;
  }

  .fireflies_item:nth-child(164) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-164;
    animation-name: an-move-frames-164;
    -webkit-animation-duration: 31279ms;
    animation-duration: 31279ms;
    -webkit-animation-delay: 11102ms;
    animation-delay: 11102ms;
  }

  @-webkit-keyframes an-move-frames-164 {
    from {
      -webkit-transform: translate3d(40vw, 101vh, 0);
      transform: translate3d(40vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(17vw, -107vh, 0);
      transform: translate3d(17vw, -107vh, 0);
    }
  }

  @keyframes an-move-frames-164 {
    from {
      -webkit-transform: translate3d(40vw, 101vh, 0);
      transform: translate3d(40vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(17vw, -107vh, 0);
      transform: translate3d(17vw, -107vh, 0);
    }
  }

  .fireflies_item:nth-child(164) .fireflies_inner {
    -webkit-animation-delay: 630ms;
    animation-delay: 630ms;
  }

  .fireflies_item:nth-child(165) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-165;
    animation-name: an-move-frames-165;
    -webkit-animation-duration: 28683ms;
    animation-duration: 28683ms;
    -webkit-animation-delay: 8340ms;
    animation-delay: 8340ms;
  }

  @-webkit-keyframes an-move-frames-165 {
    from {
      -webkit-transform: translate3d(21vw, 103vh, 0);
      transform: translate3d(21vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(52vw, -128vh, 0);
      transform: translate3d(52vw, -128vh, 0);
    }
  }

  @keyframes an-move-frames-165 {
    from {
      -webkit-transform: translate3d(21vw, 103vh, 0);
      transform: translate3d(21vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(52vw, -128vh, 0);
      transform: translate3d(52vw, -128vh, 0);
    }
  }

  .fireflies_item:nth-child(165) .fireflies_inner {
    -webkit-animation-delay: 2859ms;
    animation-delay: 2859ms;
  }

  .fireflies_item:nth-child(166) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-166;
    animation-name: an-move-frames-166;
    -webkit-animation-duration: 36532ms;
    animation-duration: 36532ms;
    -webkit-animation-delay: 7476ms;
    animation-delay: 7476ms;
  }

  @-webkit-keyframes an-move-frames-166 {
    from {
      -webkit-transform: translate3d(65vw, 107vh, 0);
      transform: translate3d(65vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(6vw, -122vh, 0);
      transform: translate3d(6vw, -122vh, 0);
    }
  }

  @keyframes an-move-frames-166 {
    from {
      -webkit-transform: translate3d(65vw, 107vh, 0);
      transform: translate3d(65vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(6vw, -122vh, 0);
      transform: translate3d(6vw, -122vh, 0);
    }
  }

  .fireflies_item:nth-child(166) .fireflies_inner {
    -webkit-animation-delay: 2797ms;
    animation-delay: 2797ms;
  }

  .fireflies_item:nth-child(167) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-167;
    animation-name: an-move-frames-167;
    -webkit-animation-duration: 28213ms;
    animation-duration: 28213ms;
    -webkit-animation-delay: 6779ms;
    animation-delay: 6779ms;
  }

  @-webkit-keyframes an-move-frames-167 {
    from {
      -webkit-transform: translate3d(25vw, 102vh, 0);
      transform: translate3d(25vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(84vw, -127vh, 0);
      transform: translate3d(84vw, -127vh, 0);
    }
  }

  @keyframes an-move-frames-167 {
    from {
      -webkit-transform: translate3d(25vw, 102vh, 0);
      transform: translate3d(25vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(84vw, -127vh, 0);
      transform: translate3d(84vw, -127vh, 0);
    }
  }

  .fireflies_item:nth-child(167) .fireflies_inner {
    -webkit-animation-delay: 3732ms;
    animation-delay: 3732ms;
  }

  .fireflies_item:nth-child(168) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-168;
    animation-name: an-move-frames-168;
    -webkit-animation-duration: 33136ms;
    animation-duration: 33136ms;
    -webkit-animation-delay: 12005ms;
    animation-delay: 12005ms;
  }

  @-webkit-keyframes an-move-frames-168 {
    from {
      -webkit-transform: translate3d(43vw, 105vh, 0);
      transform: translate3d(43vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(62vw, -127vh, 0);
      transform: translate3d(62vw, -127vh, 0);
    }
  }

  @keyframes an-move-frames-168 {
    from {
      -webkit-transform: translate3d(43vw, 105vh, 0);
      transform: translate3d(43vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(62vw, -127vh, 0);
      transform: translate3d(62vw, -127vh, 0);
    }
  }

  .fireflies_item:nth-child(168) .fireflies_inner {
    -webkit-animation-delay: 2235ms;
    animation-delay: 2235ms;
  }

  .fireflies_item:nth-child(169) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-169;
    animation-name: an-move-frames-169;
    -webkit-animation-duration: 30211ms;
    animation-duration: 30211ms;
    -webkit-animation-delay: 18155ms;
    animation-delay: 18155ms;
  }

  @-webkit-keyframes an-move-frames-169 {
    from {
      -webkit-transform: translate3d(74vw, 109vh, 0);
      transform: translate3d(74vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(82vw, -118vh, 0);
      transform: translate3d(82vw, -118vh, 0);
    }
  }

  @keyframes an-move-frames-169 {
    from {
      -webkit-transform: translate3d(74vw, 109vh, 0);
      transform: translate3d(74vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(82vw, -118vh, 0);
      transform: translate3d(82vw, -118vh, 0);
    }
  }

  .fireflies_item:nth-child(169) .fireflies_inner {
    -webkit-animation-delay: 1459ms;
    animation-delay: 1459ms;
  }

  .fireflies_item:nth-child(170) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-170;
    animation-name: an-move-frames-170;
    -webkit-animation-duration: 31126ms;
    animation-duration: 31126ms;
    -webkit-animation-delay: 17244ms;
    animation-delay: 17244ms;
  }

  @-webkit-keyframes an-move-frames-170 {
    from {
      -webkit-transform: translate3d(52vw, 106vh, 0);
      transform: translate3d(52vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(2vw, -112vh, 0);
      transform: translate3d(2vw, -112vh, 0);
    }
  }

  @keyframes an-move-frames-170 {
    from {
      -webkit-transform: translate3d(52vw, 106vh, 0);
      transform: translate3d(52vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(2vw, -112vh, 0);
      transform: translate3d(2vw, -112vh, 0);
    }
  }

  .fireflies_item:nth-child(170) .fireflies_inner {
    -webkit-animation-delay: 2995ms;
    animation-delay: 2995ms;
  }

  .fireflies_item:nth-child(171) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-171;
    animation-name: an-move-frames-171;
    -webkit-animation-duration: 33058ms;
    animation-duration: 33058ms;
    -webkit-animation-delay: 30469ms;
    animation-delay: 30469ms;
  }

  @-webkit-keyframes an-move-frames-171 {
    from {
      -webkit-transform: translate3d(51vw, 102vh, 0);
      transform: translate3d(51vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(13vw, -132vh, 0);
      transform: translate3d(13vw, -132vh, 0);
    }
  }

  @keyframes an-move-frames-171 {
    from {
      -webkit-transform: translate3d(51vw, 102vh, 0);
      transform: translate3d(51vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(13vw, -132vh, 0);
      transform: translate3d(13vw, -132vh, 0);
    }
  }

  .fireflies_item:nth-child(171) .fireflies_inner {
    -webkit-animation-delay: 2400ms;
    animation-delay: 2400ms;
  }

  .fireflies_item:nth-child(172) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-172;
    animation-name: an-move-frames-172;
    -webkit-animation-duration: 32437ms;
    animation-duration: 32437ms;
    -webkit-animation-delay: 4025ms;
    animation-delay: 4025ms;
  }

  @-webkit-keyframes an-move-frames-172 {
    from {
      -webkit-transform: translate3d(53vw, 103vh, 0);
      transform: translate3d(53vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(97vw, -110vh, 0);
      transform: translate3d(97vw, -110vh, 0);
    }
  }

  @keyframes an-move-frames-172 {
    from {
      -webkit-transform: translate3d(53vw, 103vh, 0);
      transform: translate3d(53vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(97vw, -110vh, 0);
      transform: translate3d(97vw, -110vh, 0);
    }
  }

  .fireflies_item:nth-child(172) .fireflies_inner {
    -webkit-animation-delay: 1818ms;
    animation-delay: 1818ms;
  }

  .fireflies_item:nth-child(173) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-173;
    animation-name: an-move-frames-173;
    -webkit-animation-duration: 33257ms;
    animation-duration: 33257ms;
    -webkit-animation-delay: 21700ms;
    animation-delay: 21700ms;
  }

  @-webkit-keyframes an-move-frames-173 {
    from {
      -webkit-transform: translate3d(34vw, 105vh, 0);
      transform: translate3d(34vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(24vw, -117vh, 0);
      transform: translate3d(24vw, -117vh, 0);
    }
  }

  @keyframes an-move-frames-173 {
    from {
      -webkit-transform: translate3d(34vw, 105vh, 0);
      transform: translate3d(34vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(24vw, -117vh, 0);
      transform: translate3d(24vw, -117vh, 0);
    }
  }

  .fireflies_item:nth-child(173) .fireflies_inner {
    -webkit-animation-delay: 2507ms;
    animation-delay: 2507ms;
  }

  .fireflies_item:nth-child(174) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-174;
    animation-name: an-move-frames-174;
    -webkit-animation-duration: 35562ms;
    animation-duration: 35562ms;
    -webkit-animation-delay: 13219ms;
    animation-delay: 13219ms;
  }

  @-webkit-keyframes an-move-frames-174 {
    from {
      -webkit-transform: translate3d(50vw, 109vh, 0);
      transform: translate3d(50vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(14vw, -132vh, 0);
      transform: translate3d(14vw, -132vh, 0);
    }
  }

  @keyframes an-move-frames-174 {
    from {
      -webkit-transform: translate3d(50vw, 109vh, 0);
      transform: translate3d(50vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(14vw, -132vh, 0);
      transform: translate3d(14vw, -132vh, 0);
    }
  }

  .fireflies_item:nth-child(174) .fireflies_inner {
    -webkit-animation-delay: 2242ms;
    animation-delay: 2242ms;
  }

  .fireflies_item:nth-child(175) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-175;
    animation-name: an-move-frames-175;
    -webkit-animation-duration: 28292ms;
    animation-duration: 28292ms;
    -webkit-animation-delay: 241ms;
    animation-delay: 241ms;
  }

  @-webkit-keyframes an-move-frames-175 {
    from {
      -webkit-transform: translate3d(76vw, 107vh, 0);
      transform: translate3d(76vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(10vw, -109vh, 0);
      transform: translate3d(10vw, -109vh, 0);
    }
  }

  @keyframes an-move-frames-175 {
    from {
      -webkit-transform: translate3d(76vw, 107vh, 0);
      transform: translate3d(76vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(10vw, -109vh, 0);
      transform: translate3d(10vw, -109vh, 0);
    }
  }

  .fireflies_item:nth-child(175) .fireflies_inner {
    -webkit-animation-delay: 536ms;
    animation-delay: 536ms;
  }

  .fireflies_item:nth-child(176) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-176;
    animation-name: an-move-frames-176;
    -webkit-animation-duration: 31436ms;
    animation-duration: 31436ms;
    -webkit-animation-delay: 36830ms;
    animation-delay: 36830ms;
  }

  @-webkit-keyframes an-move-frames-176 {
    from {
      -webkit-transform: translate3d(95vw, 110vh, 0);
      transform: translate3d(95vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(19vw, -125vh, 0);
      transform: translate3d(19vw, -125vh, 0);
    }
  }

  @keyframes an-move-frames-176 {
    from {
      -webkit-transform: translate3d(95vw, 110vh, 0);
      transform: translate3d(95vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(19vw, -125vh, 0);
      transform: translate3d(19vw, -125vh, 0);
    }
  }

  .fireflies_item:nth-child(176) .fireflies_inner {
    -webkit-animation-delay: 1307ms;
    animation-delay: 1307ms;
  }

  .fireflies_item:nth-child(177) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: an-move-frames-177;
    animation-name: an-move-frames-177;
    -webkit-animation-duration: 32155ms;
    animation-duration: 32155ms;
    -webkit-animation-delay: 1155ms;
    animation-delay: 1155ms;
  }

  @-webkit-keyframes an-move-frames-177 {
    from {
      -webkit-transform: translate3d(65vw, 103vh, 0);
      transform: translate3d(65vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(91vw, -125vh, 0);
      transform: translate3d(91vw, -125vh, 0);
    }
  }

  @keyframes an-move-frames-177 {
    from {
      -webkit-transform: translate3d(65vw, 103vh, 0);
      transform: translate3d(65vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(91vw, -125vh, 0);
      transform: translate3d(91vw, -125vh, 0);
    }
  }

  .fireflies_item:nth-child(177) .fireflies_inner {
    -webkit-animation-delay: 2174ms;
    animation-delay: 2174ms;
  }

  .fireflies_item:nth-child(178) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-178;
    animation-name: an-move-frames-178;
    -webkit-animation-duration: 33620ms;
    animation-duration: 33620ms;
    -webkit-animation-delay: 8093ms;
    animation-delay: 8093ms;
  }

  @-webkit-keyframes an-move-frames-178 {
    from {
      -webkit-transform: translate3d(71vw, 110vh, 0);
      transform: translate3d(71vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(62vw, -123vh, 0);
      transform: translate3d(62vw, -123vh, 0);
    }
  }

  @keyframes an-move-frames-178 {
    from {
      -webkit-transform: translate3d(71vw, 110vh, 0);
      transform: translate3d(71vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(62vw, -123vh, 0);
      transform: translate3d(62vw, -123vh, 0);
    }
  }

  .fireflies_item:nth-child(178) .fireflies_inner {
    -webkit-animation-delay: 178ms;
    animation-delay: 178ms;
  }

  .fireflies_item:nth-child(179) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-179;
    animation-name: an-move-frames-179;
    -webkit-animation-duration: 29547ms;
    animation-duration: 29547ms;
    -webkit-animation-delay: 17071ms;
    animation-delay: 17071ms;
  }

  @-webkit-keyframes an-move-frames-179 {
    from {
      -webkit-transform: translate3d(99vw, 110vh, 0);
      transform: translate3d(99vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(66vw, -136vh, 0);
      transform: translate3d(66vw, -136vh, 0);
    }
  }

  @keyframes an-move-frames-179 {
    from {
      -webkit-transform: translate3d(99vw, 110vh, 0);
      transform: translate3d(99vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(66vw, -136vh, 0);
      transform: translate3d(66vw, -136vh, 0);
    }
  }

  .fireflies_item:nth-child(179) .fireflies_inner {
    -webkit-animation-delay: 190ms;
    animation-delay: 190ms;
  }

  .fireflies_item:nth-child(180) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-180;
    animation-name: an-move-frames-180;
    -webkit-animation-duration: 34661ms;
    animation-duration: 34661ms;
    -webkit-animation-delay: 18203ms;
    animation-delay: 18203ms;
  }

  @-webkit-keyframes an-move-frames-180 {
    from {
      -webkit-transform: translate3d(99vw, 102vh, 0);
      transform: translate3d(99vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(60vw, -130vh, 0);
      transform: translate3d(60vw, -130vh, 0);
    }
  }

  @keyframes an-move-frames-180 {
    from {
      -webkit-transform: translate3d(99vw, 102vh, 0);
      transform: translate3d(99vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(60vw, -130vh, 0);
      transform: translate3d(60vw, -130vh, 0);
    }
  }

  .fireflies_item:nth-child(180) .fireflies_inner {
    -webkit-animation-delay: 682ms;
    animation-delay: 682ms;
  }

  .fireflies_item:nth-child(181) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-181;
    animation-name: an-move-frames-181;
    -webkit-animation-duration: 36982ms;
    animation-duration: 36982ms;
    -webkit-animation-delay: 32784ms;
    animation-delay: 32784ms;
  }

  @-webkit-keyframes an-move-frames-181 {
    from {
      -webkit-transform: translate3d(91vw, 109vh, 0);
      transform: translate3d(91vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(10vw, -132vh, 0);
      transform: translate3d(10vw, -132vh, 0);
    }
  }

  @keyframes an-move-frames-181 {
    from {
      -webkit-transform: translate3d(91vw, 109vh, 0);
      transform: translate3d(91vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(10vw, -132vh, 0);
      transform: translate3d(10vw, -132vh, 0);
    }
  }

  .fireflies_item:nth-child(181) .fireflies_inner {
    -webkit-animation-delay: 2412ms;
    animation-delay: 2412ms;
  }

  .fireflies_item:nth-child(182) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-182;
    animation-name: an-move-frames-182;
    -webkit-animation-duration: 35560ms;
    animation-duration: 35560ms;
    -webkit-animation-delay: 17055ms;
    animation-delay: 17055ms;
  }

  @-webkit-keyframes an-move-frames-182 {
    from {
      -webkit-transform: translate3d(4vw, 103vh, 0);
      transform: translate3d(4vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -108vh, 0);
      transform: translate3d(63vw, -108vh, 0);
    }
  }

  @keyframes an-move-frames-182 {
    from {
      -webkit-transform: translate3d(4vw, 103vh, 0);
      transform: translate3d(4vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -108vh, 0);
      transform: translate3d(63vw, -108vh, 0);
    }
  }

  .fireflies_item:nth-child(182) .fireflies_inner {
    -webkit-animation-delay: 3286ms;
    animation-delay: 3286ms;
  }

  .fireflies_item:nth-child(183) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-183;
    animation-name: an-move-frames-183;
    -webkit-animation-duration: 31164ms;
    animation-duration: 31164ms;
    -webkit-animation-delay: 13006ms;
    animation-delay: 13006ms;
  }

  @-webkit-keyframes an-move-frames-183 {
    from {
      -webkit-transform: translate3d(44vw, 105vh, 0);
      transform: translate3d(44vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(94vw, -110vh, 0);
      transform: translate3d(94vw, -110vh, 0);
    }
  }

  @keyframes an-move-frames-183 {
    from {
      -webkit-transform: translate3d(44vw, 105vh, 0);
      transform: translate3d(44vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(94vw, -110vh, 0);
      transform: translate3d(94vw, -110vh, 0);
    }
  }

  .fireflies_item:nth-child(183) .fireflies_inner {
    -webkit-animation-delay: 2459ms;
    animation-delay: 2459ms;
  }

  .fireflies_item:nth-child(184) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-184;
    animation-name: an-move-frames-184;
    -webkit-animation-duration: 28120ms;
    animation-duration: 28120ms;
    -webkit-animation-delay: 8345ms;
    animation-delay: 8345ms;
  }

  @-webkit-keyframes an-move-frames-184 {
    from {
      -webkit-transform: translate3d(81vw, 109vh, 0);
      transform: translate3d(81vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(16vw, -110vh, 0);
      transform: translate3d(16vw, -110vh, 0);
    }
  }

  @keyframes an-move-frames-184 {
    from {
      -webkit-transform: translate3d(81vw, 109vh, 0);
      transform: translate3d(81vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(16vw, -110vh, 0);
      transform: translate3d(16vw, -110vh, 0);
    }
  }

  .fireflies_item:nth-child(184) .fireflies_inner {
    -webkit-animation-delay: 150ms;
    animation-delay: 150ms;
  }

  .fireflies_item:nth-child(185) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: an-move-frames-185;
    animation-name: an-move-frames-185;
    -webkit-animation-duration: 31552ms;
    animation-duration: 31552ms;
    -webkit-animation-delay: 28793ms;
    animation-delay: 28793ms;
  }

  @-webkit-keyframes an-move-frames-185 {
    from {
      -webkit-transform: translate3d(7vw, 105vh, 0);
      transform: translate3d(7vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(87vw, -135vh, 0);
      transform: translate3d(87vw, -135vh, 0);
    }
  }

  @keyframes an-move-frames-185 {
    from {
      -webkit-transform: translate3d(7vw, 105vh, 0);
      transform: translate3d(7vw, 105vh, 0);
    }

    to {
      -webkit-transform: translate3d(87vw, -135vh, 0);
      transform: translate3d(87vw, -135vh, 0);
    }
  }

  .fireflies_item:nth-child(185) .fireflies_inner {
    -webkit-animation-delay: 2178ms;
    animation-delay: 2178ms;
  }

  .fireflies_item:nth-child(186) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-186;
    animation-name: an-move-frames-186;
    -webkit-animation-duration: 32328ms;
    animation-duration: 32328ms;
    -webkit-animation-delay: 11516ms;
    animation-delay: 11516ms;
  }

  @-webkit-keyframes an-move-frames-186 {
    from {
      -webkit-transform: translate3d(52vw, 109vh, 0);
      transform: translate3d(52vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(92vw, -111vh, 0);
      transform: translate3d(92vw, -111vh, 0);
    }
  }

  @keyframes an-move-frames-186 {
    from {
      -webkit-transform: translate3d(52vw, 109vh, 0);
      transform: translate3d(52vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(92vw, -111vh, 0);
      transform: translate3d(92vw, -111vh, 0);
    }
  }

  .fireflies_item:nth-child(186) .fireflies_inner {
    -webkit-animation-delay: 3928ms;
    animation-delay: 3928ms;
  }

  .fireflies_item:nth-child(187) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-187;
    animation-name: an-move-frames-187;
    -webkit-animation-duration: 29586ms;
    animation-duration: 29586ms;
    -webkit-animation-delay: 22101ms;
    animation-delay: 22101ms;
  }

  @-webkit-keyframes an-move-frames-187 {
    from {
      -webkit-transform: translate3d(74vw, 107vh, 0);
      transform: translate3d(74vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(89vw, -122vh, 0);
      transform: translate3d(89vw, -122vh, 0);
    }
  }

  @keyframes an-move-frames-187 {
    from {
      -webkit-transform: translate3d(74vw, 107vh, 0);
      transform: translate3d(74vw, 107vh, 0);
    }

    to {
      -webkit-transform: translate3d(89vw, -122vh, 0);
      transform: translate3d(89vw, -122vh, 0);
    }
  }

  .fireflies_item:nth-child(187) .fireflies_inner {
    -webkit-animation-delay: 2347ms;
    animation-delay: 2347ms;
  }

  .fireflies_item:nth-child(188) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-188;
    animation-name: an-move-frames-188;
    -webkit-animation-duration: 34939ms;
    animation-duration: 34939ms;
    -webkit-animation-delay: 22380ms;
    animation-delay: 22380ms;
  }

  @-webkit-keyframes an-move-frames-188 {
    from {
      -webkit-transform: translate3d(27vw, 104vh, 0);
      transform: translate3d(27vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(14vw, -124vh, 0);
      transform: translate3d(14vw, -124vh, 0);
    }
  }

  @keyframes an-move-frames-188 {
    from {
      -webkit-transform: translate3d(27vw, 104vh, 0);
      transform: translate3d(27vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(14vw, -124vh, 0);
      transform: translate3d(14vw, -124vh, 0);
    }
  }

  .fireflies_item:nth-child(188) .fireflies_inner {
    -webkit-animation-delay: 1308ms;
    animation-delay: 1308ms;
  }

  .fireflies_item:nth-child(189) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: an-move-frames-189;
    animation-name: an-move-frames-189;
    -webkit-animation-duration: 35559ms;
    animation-duration: 35559ms;
    -webkit-animation-delay: 19220ms;
    animation-delay: 19220ms;
  }

  @-webkit-keyframes an-move-frames-189 {
    from {
      -webkit-transform: translate3d(44vw, 106vh, 0);
      transform: translate3d(44vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(13vw, -128vh, 0);
      transform: translate3d(13vw, -128vh, 0);
    }
  }

  @keyframes an-move-frames-189 {
    from {
      -webkit-transform: translate3d(44vw, 106vh, 0);
      transform: translate3d(44vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(13vw, -128vh, 0);
      transform: translate3d(13vw, -128vh, 0);
    }
  }

  .fireflies_item:nth-child(189) .fireflies_inner {
    -webkit-animation-delay: 1134ms;
    animation-delay: 1134ms;
  }

  .fireflies_item:nth-child(190) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-190;
    animation-name: an-move-frames-190;
    -webkit-animation-duration: 30405ms;
    animation-duration: 30405ms;
    -webkit-animation-delay: 7995ms;
    animation-delay: 7995ms;
  }

  @-webkit-keyframes an-move-frames-190 {
    from {
      -webkit-transform: translate3d(16vw, 109vh, 0);
      transform: translate3d(16vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(11vw, -126vh, 0);
      transform: translate3d(11vw, -126vh, 0);
    }
  }

  @keyframes an-move-frames-190 {
    from {
      -webkit-transform: translate3d(16vw, 109vh, 0);
      transform: translate3d(16vw, 109vh, 0);
    }

    to {
      -webkit-transform: translate3d(11vw, -126vh, 0);
      transform: translate3d(11vw, -126vh, 0);
    }
  }

  .fireflies_item:nth-child(190) .fireflies_inner {
    -webkit-animation-delay: 3171ms;
    animation-delay: 3171ms;
  }

  .fireflies_item:nth-child(191) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: an-move-frames-191;
    animation-name: an-move-frames-191;
    -webkit-animation-duration: 29458ms;
    animation-duration: 29458ms;
    -webkit-animation-delay: 26002ms;
    animation-delay: 26002ms;
  }

  @-webkit-keyframes an-move-frames-191 {
    from {
      -webkit-transform: translate3d(91vw, 103vh, 0);
      transform: translate3d(91vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(54vw, -115vh, 0);
      transform: translate3d(54vw, -115vh, 0);
    }
  }

  @keyframes an-move-frames-191 {
    from {
      -webkit-transform: translate3d(91vw, 103vh, 0);
      transform: translate3d(91vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(54vw, -115vh, 0);
      transform: translate3d(54vw, -115vh, 0);
    }
  }

  .fireflies_item:nth-child(191) .fireflies_inner {
    -webkit-animation-delay: 35ms;
    animation-delay: 35ms;
  }

  .fireflies_item:nth-child(192) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-192;
    animation-name: an-move-frames-192;
    -webkit-animation-duration: 32433ms;
    animation-duration: 32433ms;
    -webkit-animation-delay: 29888ms;
    animation-delay: 29888ms;
  }

  @-webkit-keyframes an-move-frames-192 {
    from {
      -webkit-transform: translate3d(51vw, 110vh, 0);
      transform: translate3d(51vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(62vw, -131vh, 0);
      transform: translate3d(62vw, -131vh, 0);
    }
  }

  @keyframes an-move-frames-192 {
    from {
      -webkit-transform: translate3d(51vw, 110vh, 0);
      transform: translate3d(51vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(62vw, -131vh, 0);
      transform: translate3d(62vw, -131vh, 0);
    }
  }

  .fireflies_item:nth-child(192) .fireflies_inner {
    -webkit-animation-delay: 3799ms;
    animation-delay: 3799ms;
  }

  .fireflies_item:nth-child(193) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-193;
    animation-name: an-move-frames-193;
    -webkit-animation-duration: 36338ms;
    animation-duration: 36338ms;
    -webkit-animation-delay: 22299ms;
    animation-delay: 22299ms;
  }

  @-webkit-keyframes an-move-frames-193 {
    from {
      -webkit-transform: translate3d(72vw, 106vh, 0);
      transform: translate3d(72vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -132vh, 0);
      transform: translate3d(63vw, -132vh, 0);
    }
  }

  @keyframes an-move-frames-193 {
    from {
      -webkit-transform: translate3d(72vw, 106vh, 0);
      transform: translate3d(72vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(63vw, -132vh, 0);
      transform: translate3d(63vw, -132vh, 0);
    }
  }

  .fireflies_item:nth-child(193) .fireflies_inner {
    -webkit-animation-delay: 788ms;
    animation-delay: 788ms;
  }

  .fireflies_item:nth-child(194) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-194;
    animation-name: an-move-frames-194;
    -webkit-animation-duration: 28999ms;
    animation-duration: 28999ms;
    -webkit-animation-delay: 12036ms;
    animation-delay: 12036ms;
  }

  @-webkit-keyframes an-move-frames-194 {
    from {
      -webkit-transform: translate3d(61vw, 110vh, 0);
      transform: translate3d(61vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(8vw, -135vh, 0);
      transform: translate3d(8vw, -135vh, 0);
    }
  }

  @keyframes an-move-frames-194 {
    from {
      -webkit-transform: translate3d(61vw, 110vh, 0);
      transform: translate3d(61vw, 110vh, 0);
    }

    to {
      -webkit-transform: translate3d(8vw, -135vh, 0);
      transform: translate3d(8vw, -135vh, 0);
    }
  }

  .fireflies_item:nth-child(194) .fireflies_inner {
    -webkit-animation-delay: 1203ms;
    animation-delay: 1203ms;
  }

  .fireflies_item:nth-child(195) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-195;
    animation-name: an-move-frames-195;
    -webkit-animation-duration: 30282ms;
    animation-duration: 30282ms;
    -webkit-animation-delay: 1862ms;
    animation-delay: 1862ms;
  }

  @-webkit-keyframes an-move-frames-195 {
    from {
      -webkit-transform: translate3d(53vw, 104vh, 0);
      transform: translate3d(53vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(49vw, -105vh, 0);
      transform: translate3d(49vw, -105vh, 0);
    }
  }

  @keyframes an-move-frames-195 {
    from {
      -webkit-transform: translate3d(53vw, 104vh, 0);
      transform: translate3d(53vw, 104vh, 0);
    }

    to {
      -webkit-transform: translate3d(49vw, -105vh, 0);
      transform: translate3d(49vw, -105vh, 0);
    }
  }

  .fireflies_item:nth-child(195) .fireflies_inner {
    -webkit-animation-delay: 320ms;
    animation-delay: 320ms;
  }

  .fireflies_item:nth-child(196) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: an-move-frames-196;
    animation-name: an-move-frames-196;
    -webkit-animation-duration: 30847ms;
    animation-duration: 30847ms;
    -webkit-animation-delay: 2666ms;
    animation-delay: 2666ms;
  }

  @-webkit-keyframes an-move-frames-196 {
    from {
      -webkit-transform: translate3d(6vw, 103vh, 0);
      transform: translate3d(6vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(25vw, -129vh, 0);
      transform: translate3d(25vw, -129vh, 0);
    }
  }

  @keyframes an-move-frames-196 {
    from {
      -webkit-transform: translate3d(6vw, 103vh, 0);
      transform: translate3d(6vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(25vw, -129vh, 0);
      transform: translate3d(25vw, -129vh, 0);
    }
  }

  .fireflies_item:nth-child(196) .fireflies_inner {
    -webkit-animation-delay: 2396ms;
    animation-delay: 2396ms;
  }

  .fireflies_item:nth-child(197) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: an-move-frames-197;
    animation-name: an-move-frames-197;
    -webkit-animation-duration: 29463ms;
    animation-duration: 29463ms;
    -webkit-animation-delay: 32801ms;
    animation-delay: 32801ms;
  }

  @-webkit-keyframes an-move-frames-197 {
    from {
      -webkit-transform: translate3d(44vw, 101vh, 0);
      transform: translate3d(44vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(5vw, -117vh, 0);
      transform: translate3d(5vw, -117vh, 0);
    }
  }

  @keyframes an-move-frames-197 {
    from {
      -webkit-transform: translate3d(44vw, 101vh, 0);
      transform: translate3d(44vw, 101vh, 0);
    }

    to {
      -webkit-transform: translate3d(5vw, -117vh, 0);
      transform: translate3d(5vw, -117vh, 0);
    }
  }

  .fireflies_item:nth-child(197) .fireflies_inner {
    -webkit-animation-delay: 446ms;
    animation-delay: 446ms;
  }

  .fireflies_item:nth-child(198) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: an-move-frames-198;
    animation-name: an-move-frames-198;
    -webkit-animation-duration: 29463ms;
    animation-duration: 29463ms;
    -webkit-animation-delay: 26055ms;
    animation-delay: 26055ms;
  }

  @-webkit-keyframes an-move-frames-198 {
    from {
      -webkit-transform: translate3d(23vw, 103vh, 0);
      transform: translate3d(23vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(64vw, -107vh, 0);
      transform: translate3d(64vw, -107vh, 0);
    }
  }

  @keyframes an-move-frames-198 {
    from {
      -webkit-transform: translate3d(23vw, 103vh, 0);
      transform: translate3d(23vw, 103vh, 0);
    }

    to {
      -webkit-transform: translate3d(64vw, -107vh, 0);
      transform: translate3d(64vw, -107vh, 0);
    }
  }

  .fireflies_item:nth-child(198) .fireflies_inner {
    -webkit-animation-delay: 455ms;
    animation-delay: 455ms;
  }

  .fireflies_item:nth-child(199) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-199;
    animation-name: an-move-frames-199;
    -webkit-animation-duration: 33860ms;
    animation-duration: 33860ms;
    -webkit-animation-delay: 25801ms;
    animation-delay: 25801ms;
  }

  @-webkit-keyframes an-move-frames-199 {
    from {
      -webkit-transform: translate3d(47vw, 106vh, 0);
      transform: translate3d(47vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(36vw, -136vh, 0);
      transform: translate3d(36vw, -136vh, 0);
    }
  }

  @keyframes an-move-frames-199 {
    from {
      -webkit-transform: translate3d(47vw, 106vh, 0);
      transform: translate3d(47vw, 106vh, 0);
    }

    to {
      -webkit-transform: translate3d(36vw, -136vh, 0);
      transform: translate3d(36vw, -136vh, 0);
    }
  }

  .fireflies_item:nth-child(199) .fireflies_inner {
    -webkit-animation-delay: 2695ms;
    animation-delay: 2695ms;
  }

  .fireflies_item:nth-child(200) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: an-move-frames-200;
    animation-name: an-move-frames-200;
    -webkit-animation-duration: 29702ms;
    animation-duration: 29702ms;
    -webkit-animation-delay: 16223ms;
    animation-delay: 16223ms;
  }

  @-webkit-keyframes an-move-frames-200 {
    from {
      -webkit-transform: translate3d(71vw, 102vh, 0);
      transform: translate3d(71vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(55vw, -122vh, 0);
      transform: translate3d(55vw, -122vh, 0);
    }
  }

  @keyframes an-move-frames-200 {
    from {
      -webkit-transform: translate3d(71vw, 102vh, 0);
      transform: translate3d(71vw, 102vh, 0);
    }

    to {
      -webkit-transform: translate3d(55vw, -122vh, 0);
      transform: translate3d(55vw, -122vh, 0);
    }
  }

  .fireflies_item:nth-child(200) .fireflies_inner {
    -webkit-animation-delay: 2695ms;
    animation-delay: 2695ms;
  }

  .fireflies_inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(#f7bf50 0%, rgba(247, 191, 80, 0.9) 10%);
    box-shadow: 0 0 .9375em .1875em #f8a63a;
    -webkit-animation: an-scale-frames 2s infinite;
    animation: an-scale-frames 2s infinite;
  }

  @-webkit-keyframes an-scale-frames {
    0% {
      -webkit-transform: scale3d(0.4, 0.4, 1);
      transform: scale3d(0.4, 0.4, 1);
    }

    50% {
      -webkit-transform: scale3d(1.4, 1.4, 1);
      transform: scale3d(1.4, 1.4, 1);
    }

    100% {
      -webkit-transform: scale3d(0.4, 0.4, 1);
      transform: scale3d(0.4, 0.4, 1);
    }
  }

  @keyframes an-scale-frames {
    0% {
      -webkit-transform: scale3d(0.4, 0.4, 1);
      transform: scale3d(0.4, 0.4, 1);
    }

    50% {
      -webkit-transform: scale3d(1.4, 1.4, 1);
      transform: scale3d(1.4, 1.4, 1);
    }

    100% {
      -webkit-transform: scale3d(0.4, 0.4, 1);
      transform: scale3d(0.4, 0.4, 1);
    }
  }


  @media only screen and (max-width: 767px) {
    .format-container {
      width: 96%;
    }

  }

  @media only screen and (max-width: 639px) {}

  @media only screen and (max-width: 479px) {}

  @media (min-width: 768px) and (max-width: 979px) {
    .format-container {
      width: 750px;
    }

  }

  @media (min-width: 980px) and (max-width: 1161px) {
    .format-container {
      width: 960px;
    }

  }

}

.win,
.lose {

  // background-color: #2eace6;
  // padding: 20px;
  // border-radius: 10px;
  // box-shadow:rgba(0, 0, 0, 0.38) 0px 0px 20px 10px;
  // color: #eeeeee;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  // border: 1px solid #2eace6bd;
  .logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    img {
      min-width: 90px;
      width: auto;
      max-width: 250px;
    }
  }

  .title {
    text-align: center;
  }

  .imageHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;

    .imgSystem {
      height: 150px;
      margin-bottom: 20px;
    }

    .imgProduct {
      width: 250px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  h4 {
    text-align: center;
    margin: 0;
    font-size: xx-large;
  }

  p {
    text-align: center;

    &:first-of-type {
      font-size: large;
    }

    &:last-child {
      font-size: small;
    }
  }
}