.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // background-color: #f3f3f3;
  background-color: #4e54c8;
  background-color: linear-gradient(to left, #6a71ff, #4e54c8);

  .intro {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .logos {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        margin: 20px;
      }
    }

    @media only screen and (max-width: 500px) {
      .logos {
        flex-direction: column;
      }
    }

    h2 {
      color: #eeeeee;
      font-family: 'Roboto';
      text-align: center;
      font-weight: 500;
    }
  }

  .gamebox {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
  }

  .wheel_container {
    position: relative;
    border-radius: 50%;
    // background-color: #eeeeee;
    background-color: #275003;
    background-image: url('./border.png');
    background-repeat: no-repeat;
    background-size: cover;
    // -webkit-box-shadow: 0px 10px 13px -7px #000000, 0px 0px 28px 20px rgba(0, 0, 0, 0.38);
    // box-shadow: 0px 10px 13px -7px #000000, 0px 0px 28px 20px rgba(0, 0, 0, 0.38);
    z-index: 5;
  }

  .wheel_around {
    border-radius: 50%;
    position: absolute;
    // background-color: #eeeeee;
    background-color: #275003;
    background-image: url('./border.png');
    background-repeat: no-repeat;
    background-size: cover;
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
    // -webkit-box-shadow: 0px 10px 13px -7px #000000, 0px 0px 28px 20px rgba(0, 0, 0, 0.38);
    // box-shadow: 0px 10px 13px -7px #000000, 0px 0px 28px 20px rgba(0, 0, 0, 0.38);
    z-index: 4;
  }

  .wheel_inner_ping {
    position: relative;
    height: 100%;
    width: 100%;
    background: url('./ping.png') no-repeat center center;
    background-origin: content-box;
    background-size: 96px;
  }

  .circles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    & li {
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 0.2);
      animation: animate 25s linear infinite;
      bottom: -150px;

      &:nth-child(1) {
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
      }

      &:nth-child(2) {
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
      }

      &:nth-child(3) {
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
      }

      &:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
      }

      &:nth-child(5) {
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
      }

      &:nth-child(6) {
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
      }

      &:nth-child(7) {
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
      }

      &:nth-child(8) {
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
      }

      &:nth-child(9) {
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
      }

      &:nth-child(10) {
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
      }
    }
  }

  .cube {
    position: absolute;
    top: 80%;
    left: 45%;
    width: 10px;
    height: 10px;
    border: solid 1px darken(#0040C1, 8%);
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 12s ease-in forwards infinite;

    &:nth-child(2n) {
      border-color: lighten(#0040C1, 10%);
    }

    &:nth-child(2) {
      animation-delay: 2s;
      left: 25%;
      top: 40%;
    }

    &:nth-child(3) {
      animation-delay: 4s;
      left: 75%;
      top: 50%;
    }

    &:nth-child(4) {
      animation-delay: 6s;
      left: 90%;
      top: 10%;
    }

    &:nth-child(5) {
      animation-delay: 8s;
      left: 10%;
      top: 85%;
    }

    &:nth-child(6) {
      animation-delay: 10s;
      left: 50%;
      top: 10%;
    }
  }

  @keyframes cube {
    from {
      transform: scale(0) rotate(0deg) translate(-50%, -50%);
      opacity: 1;
    }

    to {
      transform: scale(20) rotate(960deg) translate(-50%, -50%);
      opacity: 0;
    }
  }

  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }

    100% {
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }
  }
}

.win,
.lose {

  // background-color: #2eace6;
  // padding: 20px;
  // border-radius: 10px;
  // box-shadow:rgba(0, 0, 0, 0.38) 0px 0px 20px 10px;
  // color: #eeeeee;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  // border: 1px solid #2eace6bd;
  .logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    img {
      min-width: 90px;
      width: auto;
      max-width: 250px;
    }
  }

  .title {
    text-align: center;
  }

  .imageHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;

    .imgSystem {
      height: 150px;
      margin-bottom: 20px;
    }

    .imgProduct {
      width: 250px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  h4 {
    text-align: center;
    margin: 0;
    font-size: xx-large;
  }

  p {
    text-align: center;

    &:first-of-type {
      font-size: large;
    }

    &:last-child {
      font-size: small;
    }
  }
}