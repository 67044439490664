.dialog {
  .logos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // max-width: 90%;
    margin-left: auto;
    margin-right: auto;

    img {
      min-width: 100px;
      width: auto;
      max-width: 250px;
    }
  }

  .title {
    text-align: center;
  }
}