.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
  color: white;
  // background: #f1b307;
  //background: linear-gradient(to left, #6a71ff, #4e54c8);

  .button_container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .game_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: flex-end;
  }
  .intro {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    h2 {
      color: #eeeeee;
      font-family: "Roboto";
      text-align: center;
      font-weight: 500;
    }
  }
  button {
    background: #0059ad;
    border-radius: 8px;
    font-weight: bolder;
    color: #fff;
    border: none;
    padding: 7px 15px;
    font-size: larger;
    margin: 8px;
    min-width: 150px;
  }
  button:hover {
    background: #008378;
  }
  button:focus {
    outline: 0;
  }
  .cube {
    position: absolute;
    top: 80%;
    left: 45%;
    width: 10px;
    height: 10px;
    border: solid 1px darken(#0040c1, 8%);
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 12s ease-in forwards infinite;

    &:nth-child(2n) {
      border-color: lighten(#0040c1, 10%);
    }

    &:nth-child(2) {
      animation-delay: 2s;
      left: 25%;
      top: 40%;
    }

    &:nth-child(3) {
      animation-delay: 4s;
      left: 75%;
      top: 50%;
    }

    &:nth-child(4) {
      animation-delay: 6s;
      left: 90%;
      top: 10%;
    }

    &:nth-child(5) {
      animation-delay: 8s;
      left: 10%;
      top: 85%;
    }

    &:nth-child(6) {
      animation-delay: 10s;
      left: 50%;
      top: 10%;
    }
  }

  @keyframes cube {
    from {
      transform: scale(0) rotate(0deg) translate(-50%, -50%);
      opacity: 1;
    }
    to {
      transform: scale(20) rotate(960deg) translate(-50%, -50%);
      opacity: 0;
    }
  }

  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }

    100% {
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }
  }
}
